// Adding common functions
import PropertyItem from "../pages/Instructions/components/Forms/DecOfTrustQuestionnaire/PropertyItem";
// import logoImage from "../assets/images/email/logo.png";
import { ADD_BOOK_ROLES, REGEX } from "./constant";

export const getCookieValue = (cookieKey) => {
  const cookieString = document.cookie;
  const cookies = cookieString.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    // Check if the cookie starts with the desired key
    if (cookie.startsWith(cookieKey + "=")) {
      // Extract and return the cookie value
      return cookie.substring(cookieKey.length + 1);
    }
  }

  return null; // Return null if the cookie key is not found
};

export const handleSetCookie = (cookieValue) => {
  const cookieName = "jwt-token";
  // Set the cookie with document.cookie
  document.cookie = `${cookieName}=${cookieValue}; path=/`;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/`;

  // Remove it from local storage too
  window.localStorage.removeItem(name);
};

export const formatDateToMMDDYYYY = (date) => {
  // Ensure the input is a valid Date object
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error("Invalid date");
  }

  // Get the month, day, and year components
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear());

  // Concatenate the components into MM/DD/YYYY format
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
};

export const phoneNumberValidation = (value) => {
  const regex = REGEX.PHONE_NUMBER_REGEX;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const emailValidation = (value) => {
  const regex = REGEX.EMAIL_REGEX;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const slaValidation = (value) => {
  const regex = REGEX.NUMBER_REGEX;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const NumberValidation = (value) => {
  const regex = REGEX.NUMBER_REGEX;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const vatNumberValidation = (value) => {
  const regex = REGEX.VAT_NUMBER_REGEX;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const decOfTrustFormSections = (arr, isJoint, properties) => {
  let formArr = [];

  properties.forEach((property, index) => {
    let obj = {
      id: index + 1,
      isExpanded: false,
      title: `Property ${index + 1} / `,
      subTitle: `${property.property}`,
      component: (
        <PropertyItem arr={arr} isJoint={isJoint} propertyIndex={index} />
      ),
    };

    formArr.push(obj);
  });

  return formArr;
};

export const getClientNamesFromProspectString = (inputString) => {
  // Remove 'Prospect -' from the string
  let processedString = inputString?.replace("Prospect - ", "");

  // Split the string by '&' and trim each part
  let namesArray = processedString.split("&").map((name) => name.trim());

  return namesArray;
};

export const removeNullProperties = (obj) => {
  for (var prop in obj) {
    if (obj[prop] === null) {
      delete obj[prop];
    }
  }
  return obj;
};

export const convertToShortForm = (str) => {
  return str
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase();
};

export const convertArrayValuesToString = (array) => {
  return array.map((obj) => {
    const convertedObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        convertedObj[key] = obj[key].toString();
      }
    }
    return convertedObj;
  });
};

export const includesStringFromArray = (mainString, stringArray) => {
  for (let i = 0; i < stringArray.length; i++) {
    if (mainString.includes(stringArray[i])) {
      return true;
    }
  }
  return false;
};

// used in QC forms , ref. from ipad app
export function findValues(data, keys) {
  if (!Array.isArray(keys)) {
    keys = [keys]; // Convert the key to an array if it's not already
  }

  let values = [];

  keys.forEach((key) => {
    if (typeof key !== "string") {
      return; // Skip non-string keys
    }

    if (key.includes(".")) {
      const nestedKeys = key.split(".");
      if (Array.isArray(data)) {
        data.forEach((item) => (values = values.concat(findValues(item, key))));
      } else if (typeof data === "object" && data !== null) {
        if (
          nestedKeys.length === 1 &&
          typeof data[nestedKeys[0]] !== "undefined"
        ) {
          const value = data[nestedKeys[0]];
          if (value !== "") {
            values.push(value);
          }
        } else if (nestedKeys.length > 1) {
          const currentKey = nestedKeys.shift();
          values = values.concat(
            findValues(data[currentKey], nestedKeys.join("."))
          );
        }
      }
    } else {
      function traverse(obj) {
        for (let prop in obj) {
          if (obj.hasOwnProperty(prop)) {
            if (prop === key) {
              const value = obj[prop];
              if (value !== "") {
                values.push(value);
              }
            } else if (typeof obj[prop] === "object") {
              traverse(obj[prop]);
            }
          }
        }
      }
      traverse(data);
    }
  });

  return values;
}
// gets all property from object and return array of object with all found properties. used in instructionFormList.
export function findValueAndHeader(data, propertyNames) {
  let results = [];

  function searchNested(obj) {
    if (obj === null || typeof obj !== "object") {
      return;
    }

    let foundValues = {};

    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (propertyNames.includes(key)) {
          foundValues[key] = obj[key];
        } else if (typeof obj[key] === "object") {
          searchNested(obj[key]);
        }
      }
    }

    if (Object.keys(foundValues).length > 0) {
      results.push(foundValues);
    }
  }

  if (Array.isArray(data)) {
    for (let item of data) {
      searchNested(item);
    }
  } else {
    searchNested(data);
  }

  return results;
}

/* 
@param obj : 'takes instruction object',
@param propToDelete : 'property to delete from instruction object',
*/
export function updateValues(obj, propToDelete) {
  if (typeof obj !== "object" || obj === null) {
    // Base case: If the current value is not an object, or is null, return it as is
    return obj;
  }

  if (Array.isArray(obj)) {
    // If the current value is an array, iterate through each element and apply the function recursively
    for (let i = 0; i < obj.length; i++) {
      obj[i] = updateValues(obj[i], propToDelete);
    }
  } else {
    // If the current value is an object, iterate through its properties
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // Apply the function recursively on the property value
        obj[key] = updateValues(obj[key], propToDelete);

        // Check if the current property matches the one to be deleted
        if (key === propToDelete) {
          // Delete the property
          delete obj[key];
        }
      }
    }
  }

  return obj;
}

// returns unique values from array using given unique key function (lodash uniqBy)
export function uniqBy(array, transformFunction) {
  const uniqueKeys = new Set();
  return array.filter((item) => {
    const key = transformFunction(item);
    if (!uniqueKeys.has(key)) {
      uniqueKeys.add(key);
      return true;
    }
    return false;
  });
}

export function toTitleCase(value) {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  } else {
    return "";
  }
}
// converts a string to title case
export function toStartCase(str) {
  // Regular expression to match words
  var wordPattern = /\b\w+\b/g;

  // Function to capitalize the first letter of a word
  function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  // Replace each word in the string with its title case equivalent
  var result = str.replace(wordPattern, function (word) {
    return capitalizeFirstLetter(word);
  });

  return result;
}

export function getUniqueID() {
  // format used in iPad
  return String(Date.now().toString(32) + Math.random().toString(16)).replace(
    /\./g,
    ""
  );
}

/* checks if two string arrays are same */
export function areArraysEqual(arr1, arr2) {
  // Check if arrays have the same length
  if (!arr1 || !arr2 || arr1?.length !== arr2?.length) {
    return false;
  }

  // Sort the arrays and then compare their elements
  arr1.sort();
  arr2.sort();

  // Compare elements of both arrays
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  // If all elements are the same, arrays are equal
  return true;
}

// Aggregate Addressbook from all questionairs

const mapBasicField = (person, targetPath) => {
  let { clientIndex, questionnair, fieldName, sourcePath } = targetPath;

  let isClient = questionnair === "client"; // toRemove
  let isTrustInterest = fieldName === "trustName.trustInterest";

  let personRole = [];
  if (person?.role === "Both") {
    person.role = "Executors & Trustees";
  }

  const isWillTrustee = fieldName?.includes("willTrusts");
  if (fieldName === "parties") {
    // person.fullName = formatFullName('joinFullName', person); todo
  }

  isWillTrustee && (person.fullName = person?.fullName || person?.ultimate);
  switch (true) {
    case person?.role != null:
      personRole = Array.isArray(person?.role) ? person?.role : [person?.role];
      break;
    case fieldName === "guardians":
      personRole = ["Guardian"];
      break;
    case fieldName === "trustee":
      personRole = ["Trustee"];
      break;
    case isWillTrustee:
      personRole = ["Will Trust Trustee"];
      break;
    case fieldName === "attorneys":
      personRole = ["Attorney"];
      break;
    case fieldName === "peopleData.people":
      personRole = ["Person to be Notified"];
      break;
    case fieldName === "replacementAttorneys.replacementAttorneyDetails":
      personRole = ["Replacement Attorney"];
      break;
    case fieldName?.toLowerCase().includes("beneficiar") ||
      fieldName === "ultimateBeneficiary":
      personRole = ["Beneficiary"];
      break;
    case fieldName?.includes("certificateProviderDetails"):
      personRole = ["Certificate Provider"];
      break;
    case fieldName === "reserveBeneficiaries":
      personRole = ["Reserve Beneficiaries"];
      break;
    case isClient:
      personRole = person?.roles;
      break;
    case fieldName === "testators":
      personRole = ["Testator"];
      break;
    case fieldName === "dependants":
      personRole = ["Dependant"];
      break;
    case fieldName === "exclusions":
      personRole = ["Exclusion"];
      break;
    case fieldName === "willUltimateBeneficiary" ||
      fieldName === "FLITBeneficiary" ||
      fieldName === "PPTBeneficiary" ||
      fieldName === "RDTBeneficiary":
      personRole = ["Will Trust Beneficiary"];
      break;
    case fieldName === "chattelsAndGifts":
      personRole = ["Chattles Legacies"];
      break;
    case fieldName === "monetaryLegacies":
      personRole = ["Monetary Legacies"];
      break;
    default:
      personRole = [];
      break;
  }

  // RELATIONSHIP BENEFICIARIES FROM FACT FIND
  if (person?.classification === "Named Beneficiary") {
    if (clientIndex === 0) {
      person.relationshipToClientOne = person.relationship;
    } else {
      person.relationshipToClientTwo = person.relationship;
    }
  }

  if (isTrustInterest && person) {
    let { index, parties } = targetPath;
    person.fullName = parties[index]?.fullName;
    person?.isSettlor && (personRole = [...personRole, "Settlor"]);
    person?.isTrustee &&
      !personRole?.includes("Trustee") &&
      (personRole = [...personRole, "Trustee"]);
  }
  let personID = person?.personID || getUniqueID();
  return person
    ? {
        ...person,
        fullName:
          person?.ultimateBeneficiary ||
          person?.fullName ||
          person?.beneficiary ||
          person?.trusteeName ||
          person?.name ||
          person?.beneficiaryName ||
          person?.ultimate ||
          person?.recipientName ||
          "",
        personRole,
        personID,
        ...(sourcePath && { sourcePath }),
      }
    : person;
};
const mapBasicFields = (list, targetPath) => {
  return list?.length
    ? list
        ?.filter((person, i) => {
          return person?.other_config?.isWeb || !person?.sourcePath; // filter items that are added in web or that are missing sourcePath
        })
        ?.map((person, i) => {
          return mapBasicField(person, {
            ...targetPath,
            index: i,
            sourcePath: targetPath?.sourcePath
              ? `${targetPath?.sourcePath}.[${i}]`
              : ``,
          });
        })
    : [];
};
const QUESTIONNAIRS = {
  willQuestionnaires: "willQuestionnaires",
  lpaQuestionnaires: "LPA_Questionnaire",
  trustQuestionnaires: "trustQuestionnaire",
  factFindQuestionnaires: "parties",
};
const handleMapFactChild = (clientId, questionnair) => {
  function SourcePath({ clientId }) {
    let index = clientId;
    return {
      dependants: `InstructionClients.${clientId}.InstructionFactFind.dependants`,
      exclusions: `InstructionClients.[${clientId}].InstructionFactFind.exclusions"`,
      beneficiaries: `InstructionClients.[${index}].InstructionFactFind.beneficiaries`,
    };
  }
  let FactQKeys = ["dependants", "exclusions", "beneficiaries"];
  let newAddBooks = [];
  FactQKeys.forEach((key) => {
    let sourcePath = SourcePath({ clientId })?.[key] || "";
    let mappedBooks = mapBasicFields(questionnair?.InstructionFactFind?.[key], {
      clientIndex: clientId,
      questionnair: QUESTIONNAIRS.factFindQuestionnaires, //todo
      fieldName: key,
      sourcePath,
    });
    newAddBooks = mappedBooks ? [...newAddBooks, ...mappedBooks] : newAddBooks;
  });

  return newAddBooks;
};

const handleMapWillQn = (clientId, questionnairObj) => {
  let questionnair = deepClone(questionnairObj);
  const inheritanceTaxPlanningLabel = `will_willTrusts_iht`; // used in SourcePath()
  const willtrusts_label = "will_willTrusts"; // used in WillSourcePath()
  const will_residue_label = "will_residue"; // used in WillSourcePath()

  function WillSourcePath({ clientId, pptIndex, ihtIndex }) {
    let index = clientId;
    return {
      will_testators: `InstructionWillQuestionnaire.[${index}].testators`, // name = fullName
      // will qn > Will trusts list
      [`${willtrusts_label}_FLITBeneficiary`]: `"InstructionWillQuestionnaire.[${index}].willTrusts.flexibleLifeInterestTrust.beneficiary`, // name = ultimateBeneficiary
      [`${willtrusts_label}_RDTBeneficiary`]: `"InstructionWillQuestionnaire.[${index}].willTrusts.residuaryDiscretionaryTrust.RDT`, // name = ultimateBeneficiary

      [`${willtrusts_label}_PPTBeneficiary`]: `"InstructionWillQuestionnaire.[${index}].willTrusts.propertyProtectionTrust[${pptIndex}].beneficiary`, // name = ultimateBeneficiary

      [`${willtrusts_label}_DDTBeneficiary`]: `"InstructionWillQuestionnaire.[${index}].willTrusts.disabledDiscretionaryTrust`, // name = fullName

      [`${will_residue_label}_reserveBeneficiaries`]: `"InstructionWillQuestionnaire.[${index}].residueOfEstate.reserveBeneficiaries`, // name = beneficiary

      will_guardians: `InstructionWillQuestionnaire.[${index}].guardians`,
      will_executorsAndTrustees: `InstructionWillQuestionnaire.${index}.executorsAndTrustees"`,
      will_exclusions: `InstructionWillQuestionnaire.${index}.exclusions"`,
      will_monetaryLegacies: `InstructionWillQuestionnaire.${index}.monetaryLegacies"`, // name = recipientName
      will_chattelsAndGifts: `InstructionWillQuestionnaire.${index}.chattelsAndGifts"`, // name = recipientName
      // Will Qn individual
      will_beneficiaries: `InstructionWillQuestionnaire.[${index}].residueOfEstate.beneficiaries`, // name = beneficiary
      // "InstructionWillQuestionnaire.[1].residueOfEstate.beneficiaries.[1].beneficiary"

      [`${inheritanceTaxPlanningLabel}_trustee`]: `
  "InstructionWillQuestionnaire.[${index}].willTrusts.inheritanceTaxPlanning.[${ihtIndex}].trustee`,

      [`${inheritanceTaxPlanningLabel}_willUltimateBeneficiary`]: `
  "InstructionWillQuestionnaire.[${index}].willTrusts.inheritanceTaxPlanning.[${ihtIndex}].beneficiary`, // name = ultimateBeneficiary

      [`${willtrusts_label}_FLITBeneficiary`]: `
  "InstructionWillQuestionnaire.[${index}].willTrusts.inheritanceTaxPlanning.[${ihtIndex}].beneficiary`, // name = ultimateBeneficiary
    };
  }
  let willQKeys = [
    "executorsAndTrustees",
    "guardians",
    "monetaryLegacies",
    "chattelsAndGifts",
    "exclusions",
  ];
  let newAddBooks = [];
  willQKeys?.forEach((key) => {
    if (questionnair?.[key]?.length) {
      let sourcePath = WillSourcePath({ clientId })?.["will_" + key] || "";

      let mappedBooks = mapBasicFields(questionnair?.[key], {
        clientIndex: clientId,
        questionnair: QUESTIONNAIRS.willQuestionnaires,
        fieldName: key,
        sourcePath,
      });
      newAddBooks = mappedBooks
        ? [...newAddBooks, ...mappedBooks]
        : newAddBooks;
    }
  });
  // for beneficiaries, its nested
  let beneficiaries = questionnair?.residueOfEstate?.beneficiaries;
  if (beneficiaries?.length) {
    let sourcePath = WillSourcePath({ clientId })?.["will_beneficiaries"] || "";
    let mappedBooks = mapBasicFields(beneficiaries, {
      clientIndex: clientId,
      questionnair: QUESTIONNAIRS.willQuestionnaires,
      fieldName: "beneficiaries",
      sourcePath,
    });
    newAddBooks = mappedBooks ? [...newAddBooks, ...mappedBooks] : newAddBooks;
  }

  // for willtrust inheritanceTaxPlanning, its nested
  let inheritanceTaxPlanning = questionnair?.willTrusts?.inheritanceTaxPlanning;
  if (inheritanceTaxPlanning?.length) {
    inheritanceTaxPlanning?.forEach((pln, i) => {
      let willTrustees = pln?.trustee;
      if (willTrustees?.length) {
        let sourcePath =
          WillSourcePath({ clientId, ihtIndex: i })?.[
            `${inheritanceTaxPlanningLabel}_trustee`
          ] || "";

        let mappedBooks = mapBasicFields(willTrustees, {
          clientIndex: clientId,
          questionnair: QUESTIONNAIRS.willQuestionnaires,
          fieldName: "willTrusts.inheritanceTaxPlanning.trustee",
          sourcePath,
        });
        newAddBooks = mappedBooks
          ? [...newAddBooks, ...mappedBooks]
          : newAddBooks;
      }

      // for will trust ultimate beneficiary
      let willUltimateBeneficiary = pln?.beneficiary;
      if (willUltimateBeneficiary?.length) {
        let sourcePath =
          WillSourcePath({ clientId, ihtIndex: i })?.[
            `${inheritanceTaxPlanningLabel}_willUltimateBeneficiary`
          ] || "";

        let mappedBooks = mapBasicFields(willUltimateBeneficiary, {
          clientIndex: clientId,
          questionnair: QUESTIONNAIRS.willQuestionnaires,
          fieldName: "willUltimateBeneficiary",
          sourcePath,
        });
        newAddBooks = mappedBooks
          ? [...newAddBooks, ...mappedBooks]
          : newAddBooks;
      }
    });
  }

  // for willtrust flexibleLifeInterestTrust, its nested
  let FLITBeneficiary =
    questionnair?.willTrusts?.flexibleLifeInterestTrust?.beneficiary;
  if (FLITBeneficiary?.length) {
    let sourcePath =
      WillSourcePath({ clientId })?.[`${willtrusts_label}_FLITBeneficiary`] ||
      "";

    let mappedBooks = mapBasicFields(FLITBeneficiary, {
      clientIndex: clientId,
      questionnair: QUESTIONNAIRS.willQuestionnaires,
      fieldName: "FLITBeneficiary",
      sourcePath,
    });
    newAddBooks = mappedBooks ? [...newAddBooks, ...mappedBooks] : newAddBooks;
  }

  // for willtrust residuaryDiscretionaryTrust, its nested
  let RDTBeneficiary =
    questionnair?.willTrusts?.residuaryDiscretionaryTrust?.RDT;
  if (RDTBeneficiary?.length) {
    let sourcePath =
      WillSourcePath({ clientId })?.[`${willtrusts_label}_RDTBeneficiary`] ||
      "";
    let mappedBooks = mapBasicFields(RDTBeneficiary, {
      clientIndex: clientId,
      questionnair: QUESTIONNAIRS.willQuestionnaires,
      fieldName: "RDTBeneficiary",
      sourcePath,
    });
    newAddBooks = mappedBooks ? [...newAddBooks, ...mappedBooks] : newAddBooks;
  }

  // for willtrust propertyProtectionTrust, its nested
  let PPTBeneficiary = questionnair?.willTrusts?.propertyProtectionTrust;
  if (PPTBeneficiary?.length) {
    PPTBeneficiary.forEach((bene, i) => {
      let PPTbene = bene?.beneficiary;

      //added below code to cleanup object
      const newPPTbene = PPTbene.map((obj) => {
        return {
          isJoint: obj.isJoint,
          lifeTenantName: obj.lifeTenantName,
          classification: obj.classification,
          ongoingMaintenancePropertyRestriction:
            obj.ongoingMaintenancePropertyRestriction,
          sellAndPurchaseHouseRestriction: obj.sellAndPurchaseHouseRestriction,
          ultimateBeneficiary: obj.ultimateBeneficiary,
          ageOfInheritance: obj.ageOfInheritance,
          percentageShare: obj.percentageShare,
        };
      });
      if (newPPTbene?.length) {
        let sourcePath =
          WillSourcePath({ clientId, pptIndex: i })?.[
            `${willtrusts_label}_PPTBeneficiary`
          ] || "";

        let mappedBooks = mapBasicFields(newPPTbene, {
          clientIndex: clientId,
          questionnair: QUESTIONNAIRS.willQuestionnaires,
          fieldName: "PPTBeneficiary",
          sourcePath,
        });
        newAddBooks = mappedBooks
          ? [...newAddBooks, ...mappedBooks]
          : newAddBooks;
      }
    });
  }

  // for willtrust disabledDiscretionaryTrust, its nested
  let DDTBeneficiary =
    questionnair?.willTrusts?.disabledDiscretionaryTrust?.beneficiary;
  if (DDTBeneficiary?.length) {
    let sourcePath =
      WillSourcePath({ clientId })?.[`${willtrusts_label}_DDTBeneficiary`] ||
      "";
    let mappedBooks = mapBasicFields(DDTBeneficiary, {
      clientIndex: clientId,
      questionnair: QUESTIONNAIRS.willQuestionnaires,
      fieldName: "DDTBeneficiary",
      sourcePath,
    });
    newAddBooks = mappedBooks ? [...newAddBooks, ...mappedBooks] : newAddBooks;
  }

  // for reserveBeneficiaries, its nested
  let rvbeneficiaries = questionnair?.residueOfEstate?.reserveBeneficiaries;
  if (rvbeneficiaries?.length) {
    let sourcePath =
      WillSourcePath({ clientId })?.[
        `${will_residue_label}_reserveBeneficiaries`
      ] || "";
    let mappedBooks = mapBasicFields(rvbeneficiaries, {
      clientIndex: clientId,
      questionnair: QUESTIONNAIRS.willQuestionnaires,
      fieldName: "reserveBeneficiaries",
      sourcePath,
    });
    newAddBooks = mappedBooks ? [...newAddBooks, ...mappedBooks] : newAddBooks;
  }

  let testators = questionnair?.testators;
  if (questionnair?.testators) {
    let sourcePath = WillSourcePath({ clientId })?.[`will_testators`] || "";
    let mappedBooks = mapBasicField(testators, {
      clientIndex: clientId,
      questionnair: QUESTIONNAIRS.willQuestionnaires,
      fieldName: "testators",
      sourcePath,
    });

    newAddBooks = [...newAddBooks, { ...mappedBooks }];
    // log('mappedBooks2', newAddBooks);
  }

  // setWillQuestionnaireList(newAddBooks);
  return newAddBooks;
};

const handleMapLpaQn = (clientId, questionnair) => {
  function getLPASourcePath({ clientId, key }) {
    const data = {
      attorneys: `InstructionLPA_Questionnaire.${clientId}.attorneys`,
      replacementAttorneys: `InstructionLPA_Questionnaire.${clientId}.replacementAttorneys.replacementAttorneyDetails`,
      forHealthWelfare: `InstructionLPA_Questionnaire.${clientId}.certificateProviderDetails.forHealthWelfare`,
      forPropertyFinancialAffairs: `InstructionLPA_Questionnaire.${clientId}.certificateProviderDetails.forPropertyFinancialAffairs`,
      peopleData: `InstructionLPA_Questionnaire.${clientId}.peopleData.people`,
    };
    return data[key] || "";
  }
  let LpaQKeys = ["attorneys"];
  let newAddBooks = [];
  LpaQKeys.forEach((key) => {
    if (questionnair?.[key]?.length) {
      let mappedBooks = mapBasicFields(questionnair?.[key], {
        clientIndex: clientId,
        questionnair: QUESTIONNAIRS.lpaQuestionnaires,
        fieldName: key,
        sourcePath: getLPASourcePath({ clientId, key }),
      });
      newAddBooks = mappedBooks
        ? [...newAddBooks, ...mappedBooks]
        : newAddBooks;
    }
  });
  // for replacementAttorneyDetails, its nested
  let replacementAttorneyDetails =
    questionnair?.replacementAttorneys?.replacementAttorneyDetails;
  if (replacementAttorneyDetails?.length) {
    let mappedBooks = mapBasicFields(replacementAttorneyDetails, {
      clientIndex: clientId,
      questionnair: QUESTIONNAIRS.lpaQuestionnaires,
      fieldName: "replacementAttorneys.replacementAttorneyDetails",
      sourcePath: getLPASourcePath({ clientId, key: "replacementAttorneys" }),
    });
    newAddBooks = mappedBooks ? [...newAddBooks, ...mappedBooks] : newAddBooks;
  }

  // for certificateProviderDetails, its nested
  let forPropertyFinancialAffairs =
    questionnair?.certificateProviderDetails?.forPropertyFinancialAffairs;
  if (forPropertyFinancialAffairs) {
    let mappedBooks = mapBasicField(forPropertyFinancialAffairs, {
      clientIndex: clientId,
      questionnair: QUESTIONNAIRS.lpaQuestionnaires,
      fieldName: "certificateProviderDetails.forPropertyFinancialAffairs",
      sourcePath: getLPASourcePath({
        clientId,
        key: "forPropertyFinancialAffairs",
      }),
    });
    newAddBooks = [...newAddBooks, mappedBooks];
  }
  // for certificateProviderDetails, its nested
  let forHealthWelfare =
    questionnair?.certificateProviderDetails?.forHealthWelfare;
  if (forHealthWelfare) {
    let mappedBooks = mapBasicField(forHealthWelfare, {
      clientIndex: clientId,
      questionnair: QUESTIONNAIRS.lpaQuestionnaires,
      fieldName: "certificateProviderDetails.forHealthWelfare",
      sourcePath: getLPASourcePath({ clientId, key: "forHealthWelfare" }),
    });
    newAddBooks = [...newAddBooks, mappedBooks];
  }

  // for people tobe notified, its nested
  let people = questionnair?.peopleData?.people;
  if (people?.length) {
    let mappedBooks = mapBasicFields(people, {
      clientIndex: clientId,
      questionnair: QUESTIONNAIRS.lpaQuestionnaires,
      fieldName: "peopleData.people",
      sourcePath: getLPASourcePath({ clientId, key: "peopleData" }),
    });
    newAddBooks = mappedBooks ? [...newAddBooks, ...mappedBooks] : newAddBooks;
  }

  // setLpaQuestionnaireList(newAddBooks);
  return newAddBooks;
};

function handleMapTrustQn(clientId, questionnair) {
  let trustSourceLabel = "Trust_qn_";
  function getTrustSourcePath({ clientId, key }) {
    const data = {
      [`${trustSourceLabel}trustee`]: `InstructionTrustQuestionnaire.[${clientId}].trustee`,
      [`${trustSourceLabel}ultimateBeneficiary`]: `InstructionTrustQuestionnaire.[${clientId}].ultimateBeneficiary`,
      [`${trustSourceLabel}generalDistribution`]: `InstructionTrustQuestionnaire.[${clientId}].generalDistribution`,
    };
    return data[key] || "";
  }
  const InstructionClients = this;
  let trustQKeys = ["trustee", "ultimateBeneficiary"];
  let newAddBooks = [];
  trustQKeys.forEach((key) => {
    if (questionnair?.[key]?.length) {
      let mappedBooks = mapBasicFields(questionnair?.[key], {
        clientIndex: clientId,
        questionnair: QUESTIONNAIRS.trustQuestionnaires,
        fieldName: key,
        sourcePath: getTrustSourcePath({
          clientId,
          key: trustSourceLabel + key,
        }),
      });
      newAddBooks = mappedBooks
        ? [...newAddBooks, ...mappedBooks]
        : newAddBooks;
    }
  });

  let generalDistribution = questionnair?.generalDistribution?.beneficiary;
  if (generalDistribution?.length) {
    try {
      generalDistribution?.forEach((key) => {
        let mappedBooks = mapBasicField(key, {
          clientIndex: clientId,
          questionnair: QUESTIONNAIRS.trustQuestionnaires,
          fieldName: "beneficiar",
          sourcePath: getTrustSourcePath({
            clientId,
            key: trustSourceLabel + "generalDistribution",
          }),
        });
        newAddBooks = [...newAddBooks, mappedBooks];
      });
    } catch (error) {
      console.log("error");
    }
  }

  let trustName = questionnair?.trustName?.trustInterest;

  let trustNameFilter = trustName;

  if (trustNameFilter?.length && InstructionClients) {
    let parties = InstructionClients?.map((party) => {
      let partyFullName = [party?.firstName, party?.middleName, party?.lastName]
        .join(" ")
        .replace(/\s+/g, " ")
        .trim();

      return {
        fullName: partyFullName || "",
      };
    });
    // todo addobject not found (TrustQuestionnaire\TrustItem.jsx)
    let mappedBooks = mapBasicFields(trustNameFilter, {
      clientIndex: clientId,
      parties,
      questionnair: QUESTIONNAIRS.trustQuestionnaires,
      fieldName: "trustName.trustInterest",
      sourcePath: "", // it will be client name
    });

    newAddBooks = mappedBooks ? [...newAddBooks, ...mappedBooks] : newAddBooks;
  }

  return newAddBooks;
}

export function getAddressbooksFromQuestionnairs({ instruction }) {
  let AddData = instruction?.InstructionAddressBook?.[0]?.addressBook;
  if (AddData?.length) {
    let addBookFormData = AddData.map((user) => {
      let tempUser = { ...user };
      if (
        !tempUser.personRole?.length &&
        tempUser?.targetPath?.fieldName === "parties" &&
        tempUser?.targetPath?.questionnair === "client"
      ) {
        tempUser.personRole.push(
          `Client ${tempUser?.targetPath?.clientIndex + 1}`
        );
      }
      return tempUser;
    });

    // Factfind
    let addressBookFromFactfind = handleQnList(
      instruction?.InstructionClients,
      handleMapFactChild
    );

    // Will Q
    let addressBookFromWillQ = handleQnList(
      instruction?.InstructionWillQuestionnaire,
      handleMapWillQn
    );

    // LPA Q
    let addressBookFromLpaQ = handleQnList(
      instruction?.InstructionLPA_Questionnaire,
      handleMapLpaQn
    );

    // Trusy Q
    let addressBookFromTrustQ = handleQnList(
      instruction?.InstructionTrustQuestionnaire,
      handleMapTrustQn.bind(instruction?.InstructionClients)
    );

    let addressBookFromClientDetails = [];
    // clients
    if (instruction?.InstructionClients?.length) {
      let qnList = [];
      instruction?.InstructionClients?.forEach((party, i) => {
        let roles = [`Client ${i + 1}`];
        addressBookFromLpaQ?.length && roles.push(ADD_BOOK_ROLES.Donor);
        let newClient = mapBasicField(
          { ...party, roles },
          {
            clientIndex: i,
            questionnair: "client",
            fieldName: "parties",
          }
        );

        qnList?.push(newClient);
      });
      addressBookFromClientDetails = qnList;
    }

    let allAddressFromQuestionnair = [
      ...addressBookFromFactfind,
      ...addressBookFromWillQ,
      ...addressBookFromLpaQ,
      ...addressBookFromTrustQ,
      ...addressBookFromClientDetails,
    ];

    let addressFromQuestionnair = [];

    allAddressFromQuestionnair?.forEach((obj) => {
      let isWebAdded = obj?.other_config?.isWeb; // allow only items that are added from web
      // check duplicate name + role exists, if found merge role

      // isWebAdded &&
      let index = addBookFormData?.findIndex(
        (item) => item?.fullName === obj?.fullName
      );
      if (index >= 0) {
        let formItem = addBookFormData[index];
        let roles = formItem?.personRole?.length
          ? new Set([...obj?.personRole, ...formItem?.personRole])
          : obj?.personRole;

        let sourcePaths = [];
        obj?.sourcePath && sourcePaths?.push(obj?.sourcePath);
        formItem?.sourcePaths?.length &&
          (sourcePaths = [...sourcePaths, ...formItem?.sourcePaths]);
        sourcePaths = new Set(sourcePaths);

        addBookFormData[index] = {
          ...formItem,
          personRole: [...roles],
          sourcePaths: [...sourcePaths],
        };
      }
      // push items that are added from web and not duplicate in AddData
      if (isWebAdded && index === -1) {
        !addBookFormData?.find(
          (item) =>
            item?.fullName === obj?.fullName &&
            areArraysEqual(item?.personRole, obj?.personRole)
        ) && // if duplicate due to name change , avoid pushing data
          addressFromQuestionnair?.push({
            ...obj,
            sourcePaths: [obj?.sourcePath],
          });
      }
    });
    return [...addBookFormData, ...addressFromQuestionnair] || [];
  }
  return [];

  function handleQnList(list, cb) {
    let mappedList = [];
    if (list?.length)
      list?.forEach((book, i) => {
        if (book) {
          let temp = cb(i, book);
          mappedList = [...mappedList, ...temp];
        }
      });

    return mappedList;
  }
}
// End of the block - Addressbook aggregating data from other forms

// to deepCompare form state/Object
export function deepCompare(obj1, obj2) {
  // Check if both objects are objects
  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return obj1 === obj2; // Compare non-object values directly
  }

  // Get the keys of both objects
  var keys1 = obj1 ? Object.keys(obj1) : [];
  var keys2 = obj2 ? Object.keys(obj2) : [];

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Compare each property recursively
  for (var key of keys1) {
    if (!keys2.includes(key) || !deepCompare(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true; // If all properties are equal, the objects are deep equal
}

// convert string [0] to [0 + 1]; used in QC form error formatter
export function replaceArrayIndex(inputString) {
  // Regular expression to match the pattern '[*]'
  var regex = /\[(\d+)\]/g;

  // Replace the matched pattern with '[* + 1]'
  var result = inputString.replace(regex, function (match, number) {
    // Increment the captured number by 1
    var incrementedNumber = parseInt(number, 10) + 1;
    return "[" + incrementedNumber + "]";
  });

  return result;
}

export function deepClone(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    const newArray = [];
    for (let i = 0; i < obj.length; i++) {
      newArray[i] = deepClone(obj[i]);
    }
    return newArray;
  }

  const newObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = deepClone(obj[key]);
    }
  }

  return newObj;
}

// Get unique values from array by passing key
export const getUniqueArray = (data, key) => {
  const arrayUniqueByKey = [
    ...new Map(data?.map((item) => [item[key], item])).values(),
  ];
  return arrayUniqueByKey;
};

export const stringIncludesName = ({ longString, arrayOfStrings }) => {
  const includesString = () => {
    return arrayOfStrings.some((substring) => longString.includes(substring));
  };

  return includesString;
};

// to convert local image to base 64
async function getImageBase64(imageSrc, mime) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      const context = canvas.getContext("2d");
      context.drawImage(image, 0, 0);

      const base64 = canvas.toDataURL(mime);
      resolve(base64);
    };

    image.onerror = (error) => {
      reject(error);
    };
  });
}

export const getEmailSignHtml = async ({ userDetails }) => {
  const emailSignTemp = `
  <br><br><div style="width:26em;" > 
  <div><b  >${userDetails?.fullName}</b> &#9679; <span style="font-style:bold" >${userDetails?.role}</span>  </div>
  <div><b  >Squiggle Consult</b></div>
  <table class="tbl-border-0" style="border-collapse: collapse;border:none;" ><tbody><tr><td style="border:none" ><img src="http://emailsignature.squiggleconsult.co.uk/phone.png" width="16" height="16"></td><td style="border:none" ><span style="color:#00CE3F">${userDetails?.phoneNumber}</span></td></tr><tr><td style="border:none" ><img src="http://emailsignature.squiggleconsult.co.uk/email.png" width="16" height="16"></td><td style="border:none" ><a style="color:#00CE3F" href="${userDetails?.email}" target="_blank" rel="noopener">${userDetails?.email} </a></td></tr><tr><td style="border:none" ><img src="http://emailsignature.squiggleconsult.co.uk/website.png" width="16" height="16"></td><td style="border:none" ><a style = "color:#00CE3F" href="http://www.squiggleconsult.co.uk/" target="_blank" rel="noopener">www.squiggleconsult.co.uk</a></td></tr><tr><td style="border:none" ><img src="http://emailsignature.squiggleconsult.co.uk/address.png" width="16" height="16"></td><td style="border:none" >11 Park Barn, Evegate, Ashford, Kent, TN25 6SX</td></tr></tbody></table><div style="border-top:1px solid #00CE3F;padding-top:10px;">
  <table style="border-collapse: collapse;border:none;" ><tbody>
    <tr><td style="border:none;" ><a href="http://www.squiggleconsult.co.uk/" target="_blank" rel="noopener"><img src="http://emailsignature.squiggleconsult.co.uk/logo.png" width="164" height="48"></a></td><td style="border:none;min-width: 8em" ><a href="https://www.facebook.com/squiggleconsult/" target="_blank" rel="noopener"><img src="http://emailsignature.squiggleconsult.co.uk/facebook.png" width="25" height="25"></a>&nbsp;<a href="https://twitter.com/squiggleconsult" target="_blank" rel="noopener"><img src="http://emailsignature.squiggleconsult.co.uk/twitter.png" width="25" height="25"></a>&nbsp;<a href="https://www.linkedin.com/in/kieranosborne" target="_blank" rel="noopener"><img src="http://emailsignature.squiggleconsult.co.uk/linkedin.png" width="25" height="25"></a>&nbsp;<a href="https://www.instagram.com/squiggleconsult" target="_blank" rel="noopener"><img src="http://emailsignature.squiggleconsult.co.uk/instagram.png" width="25" height="25"></a></td>
  </tr>
  <tr>
  <td style="border:none" colspan = "2" >

  Many of my clients come from my existing clients introducing me to their friends & family. If you feel I may be able to assist someone please feel free to share my details.
<hr>
Legal:<br> 
Squiggle Consult Ltd. is a limited company registered in England and Wales. Registered number: <span style = "color:#00CE3F"> 10816512 </span>. Registered office: Suite 2069, Letraset Building, Wotton Road, Ashford, Kent, TN23 6LN.
<p> Confidentiality Notice: This message is private and confidential and may contain confidential, proprietary and legally privileged information. If you have received this message in error, please notify us and remove it from your system and note that you must not copy, distribute or take any action in reliance on it. Any unauthorised use or disclosure of the contents of this message is not permitted and may be unlawful. </p>
<div>Disclaimer: </div>
Email messages may be subject to delays, interception, non-delivery and unauthorised alterations. Therefore, information expressed in this message is not given or endorsed by Squiggle Consult unless otherwise notified by an authorised representative independent of this message. No contractual relationship is created by this message by any person unless specifically indicated by agreement in writing other than email.
Any guidance given on any potential client is provided on the basis that it is not advice. Squiggle Consult has not been instructed to provide such advice and any reliance upon the information provided is done so on that basis. Squiggle Consult accepts no responsibility whatsoever for any advice given by or to any third party based on the guidance given.

  </td>
  </tr>
  </tbody></table>  </div></div>`;
  return emailSignTemp;
};
// for Dropdown map name as label value
export function mapLabelValue(list) {
  return list?.length
    ? list?.map((obj) => ({ label: obj?.name, value: obj?.name }))
    : [];
}

//function to Download docs or images
export const downloadDocument = async (e, clientData, documentType = "") => {
  let path = encodeURIComponent(e?.documentUrl);
  let instructionId = e?.instructionId || null;
  let forClient = encodeURIComponent(e?.forClient) || null;
  let subType = encodeURIComponent(
    e?.subDocumentType?.name || e?.documentType?.name
  );
  let version = e?.version || null;
  if (forClient.toLowerCase() === "both") {
    forClient = `${clientData?.[0]?.firstName}_${clientData?.[0]?.lastName}_${clientData?.[1]?.firstName}_${clientData?.[1]?.lastName}`;
  }

  let finalUrl = "";
  if (documentType === "addressBook") {
    finalUrl = `${process.env.REACT_APP_API_PATH}/file/download-image?path=${path}&filename=${forClient}_${subType}_${instructionId}`;
  } else {
    finalUrl = `${process.env.REACT_APP_API_PATH}/file/download-image?path=${path}&filename=${instructionId}_${forClient}_${subType}`;
  }

  if (version !== null) {
    finalUrl += `&version=${version}`;
  }

  window.open(finalUrl);
};

//function to Download docs or images for notes and doc section
export const downloadNotesDocument = async (
  e,
  clientData = [],
  docIndex = null,
  id
) => {
  let requiredDocumentIndex = docIndex === null ? 0 : docIndex;
  let path = encodeURIComponent(
    e?.documentUrl ||
      e?.path ||
      e?.InstructionTaskDocuments[requiredDocumentIndex]?.documentUrl
  );
  let instructionId = e?.instructionId || id || null;
  let forClient =
    e?.forClient ||
    e?.InstructionTaskDocuments[requiredDocumentIndex]?.forClient ||
    null;
  let subType =
    e?.subDocumentType?.name ||
    e?.documentType?.name ||
    e?.InstructionTaskDocuments[requiredDocumentIndex]?.subDocumentType?.name ||
    e?.InstructionTaskDocuments[requiredDocumentIndex]?.documentType?.name;
  let version = e?.version || e?.InstructionTaskDocuments[0]?.version;
  if (forClient?.toLowerCase() === "both") {
    forClient = `${clientData?.[0]?.firstName}_${clientData?.[0]?.lastName}_${clientData?.[1]?.firstName}_${clientData?.[1]?.lastName}`;
  }
  const finalUrl = `${process.env.REACT_APP_API_PATH}/file/download-image?path=${path}&filename=${instructionId}_${forClient}_${subType}&version=${version}`;
  window.open(finalUrl);
};

export const downloadEmailDocument = async (e, fileName = "") => {
  let fileLink = encodeURIComponent(e);
  let url = `${
    process.env.REACT_APP_API_PATH
  }${`/file/download-image?path=`}${fileLink}`;

  if (fileName !== "") {
    url += `&filename=${fileName}`;
  }
  window.open(url);
};

//function to View docs or images
export const viewDocument = (e) => {
  // window.open(
  //   `${process.env.REACT_APP_API_PATH}${IMG_BASE_URL}${e?.documentUrl}`
  // );
  window.open(e, "_blank").focus();
};

// Method to get QC color as per QC Accuracy Used in superAdmin + officeAdmin Dashboard
export const getColorCodeForQc = (data) => {
  let colorCode = null;
  let tempData = data?.toLowerCase();
  switch (tempData) {
    case "green":
      colorCode = "#00ce3f";
      break;
    case "amber":
      colorCode = "#FFA200";
      break;
    case "red":
      colorCode = "#d32f2f";
      break;
    default:
      return null;
  }
  return colorCode;
};

// pass url and object to get url with query params.
export function getUrlWithQueryParams(url, query) {
  if (typeof url != "string" || typeof query != "object" || !query) return url;

  let searchParams = new URLSearchParams(query);
  let newUrl = `${url}?${searchParams?.toString()}`;
  return newUrl;
}

// function to convert "03:00" format into "3hr 0m" format
export const convertTime = (timeStr) => {
  const [hours, minutes, seconds] = timeStr.split(":").map(Number);

  let formattedTime = "";

  formattedTime += hours + "hr ";
  formattedTime += minutes + "m ";
  if (seconds > 0) {
    formattedTime += seconds + "s";
  }

  return formattedTime.trim();
};

export const addTimes = (timeArray) => {
  // Initialize variables to hold total hours, minutes, and seconds
  let totalHours = 0;
  let totalMinutes = 0;
  let totalSeconds = 0;

  // Loop through each time string in the array
  for (let timeStr of timeArray) {
    // Split the time string into hours, minutes, and seconds
    const timeComponents = timeStr.split(":").map(Number);
    let hours = 0,
      minutes = 0,
      seconds = 0;

    if (timeComponents.length >= 1) {
      hours = timeComponents[0];
    }
    if (timeComponents.length >= 2) {
      minutes = timeComponents[1];
    }
    if (timeComponents.length === 3) {
      seconds = timeComponents[2];
    }

    // Add the hours, minutes, and seconds to the totals
    totalHours += hours;
    totalMinutes += minutes;
    totalSeconds += seconds;
  }

  // Adjust the total minutes and total seconds if they exceed their respective ranges
  totalMinutes += Math.floor(totalSeconds / 60);
  totalSeconds %= 60;
  totalHours += Math.floor(totalMinutes / 60);
  totalMinutes %= 60;

  // Construct the result string
  let result = `${totalHours}:${String(totalMinutes).padStart(2, "0")}`;
  if (totalSeconds > 0) {
    result += `:${String(totalSeconds).padStart(2, "0")}`;
  }
  return result;
};

export const formatBytes = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

// function to get collective file size of attachments
export const isFileSizeValid = (attachments, fileSizeInMb) => {
  // Calculate total size of attachments
  const totalSize = attachments.reduce((acc, file) => acc + file.size, 0);

  // Convert total size to MB
  const totalSizeInMB = totalSize / (1024 * 1024); // Convert bytes to MB

  // Check if total size is not more than fileSizeInMb
  return totalSizeInMB < fileSizeInMb;
};

// Method to find duplicate successor task IDs
export const hasDuplicatesById = (data) => {
  const ids = new Set();
  for (const item of data) {
    if (ids?.has(item?.id)) {
      return true; // Found a duplicate ID
    }
    ids.add(item?.id);
  }
  return false; // No duplicates found
};

//Method to get color on basis of task type
export const handleTasktype = (data) => {
  let taskType = null;
  let colorCode = null;
  switch (data) {
    case "Custom":
      taskType = "Custom";
      colorCode = "#ff0000"; //red
      break;
    case "Instance":
      taskType = "Duplicate";
      colorCode = "#01ce3f"; //green
      break;
    case "Instruction":
      taskType = "Instruction";
      colorCode = "";
      break;
    case undefined:
      taskType = "Instruction";
      colorCode = "";
      break;
    default:
      return null;
  }
  return { taskType, colorCode };
};

export const getModifiedFileName = (filename) => {
  // Split the filename by '/' to separate the path from the file name
  const parts = filename?.split("/");
  let fileName = parts[parts?.length - 1];

  // Define a regex pattern to detect UUIDs followed by an optional underscore and some more characters
  const regex = /([\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12})(?:_)?(.*)/;

  // Initialize to check if there is any match
  let match;

  // Apply the regex repeatedly until no UUID is found in the filename
  do {
    match = fileName?.match(regex);
    if (match) {
      // Use the part after the UUID for the next iteration
      fileName = match[2];
    }
  } while (match);

  // Extract the file extension from the final form of the file name
  const fileExtension = fileName?.split(".").pop();

  // Remove the file extension from the file name, then append it again to form the final modified file name
  const modifiedFileName = `${fileName?.replace(
    `.${fileExtension}`,
    ""
  )}.${fileExtension}`;

  return modifiedFileName;
};

//  write a method to display none
export const hideShowCommissionMenu = (item, userDetails) => {
  const key = "routekey-commissions-introducer";
  if (key === item?.key) {
    if (!userDetails?.isDashBoard) {
      return "none";
    } else {
      return "";
    }
  }
};
