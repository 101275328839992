import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import DataGridComponent from "../../../../components/DataGridComponent";
import Loader from "../../../../components/Loader";
import PaginationComponent from "../../../../components/PaginationComponent";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { getEmailTemplates } from "../../../../thunks/email_template";
import { EMAILTEMPLATE_COLUMNS } from "../../../../utils/TableColumns/EmailTemplateTableColumns";
import AddViewSharedEmail from "./AddViewSharedEmail";
import { PAGINATION_LIMIT } from "../../../../utils/constant";

const EmailTemplateTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tempEmailTemplateData = useSelector(
    (state) => state?.emailTemplate?.emailTemplates
  );
  const [emailTemplateData, setEmailTemplateData] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: PAGINATION_LIMIT,
    totalCount: 0,
  });
  const [isSharedEmail, setIsSharedEmail] = useState(false);
  // Calculate the index range for the current page
  const startIndex =
    (paginationData?.currentPage - 1) * paginationData?.itemsPerPage;
  const endIndex = startIndex + paginationData?.itemsPerPage;

  const getEmailTemplateData = async () => {
    try {
      setLoader(true);
      const response = await dispatch(
        getEmailTemplates({ isActive: true })
      ).unwrap();
      if (response) {
        setEmailTemplateData(response);
        setPaginationData({ ...paginationData, totalCount: response?.length });
        setLoader(false);
      }
    } catch (error) {
      setEmailTemplateData([]);
      setPaginationData({ ...paginationData, totalCount: 0 });
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
      setLoader(false);
    }
  };

  /* Method to change search */
  const handleChangeSearch = (e) => {
    setSearch(e);
    const filterData = tempEmailTemplateData?.filter((value) =>
      value?.name?.toLowerCase().includes(e?.toLowerCase())
    );
    setPaginationData({
      ...paginationData,
      totalCount: filterData?.length,
      currentPage: 1,
    });
    setEmailTemplateData(filterData);
  };

  /* Method to change page --> Pagination */
  const handleChangePage = (event, value) => {
    setPaginationData({ ...paginationData, currentPage: value });
  };

  // DataGrid onRowClick Method
  const onRowClick = (e) => {
    navigate(`../update/${e?.row?.id}`);
  };

  useEffect(() => {
    getEmailTemplateData();
  }, []);

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "0px" }}>
        {/* Loader Component */}
        {isLoader && <Loader />}

        {/* Open AddViewSharedEmail Dialog */}
        {isSharedEmail && (
          <AddViewSharedEmail
            open={isSharedEmail}
            handleClose={() => setIsSharedEmail(false)}
          />
        )}
        {/* Filter By Name Container */}

        <Grid item xs={3}>
          <TextField
            fullWidth
            placeholder="Search by name..."
            className="textField_outlined_border"
            size="small"
            value={search}
            onChange={(e) => handleChangeSearch(e?.target?.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* ADD EmailTemplate BUTTON */}

        <Grid item xs={3}>
          <Button
            className="add_a_new_user_button"
            onClick={() => navigate("../create")}
          >
            Add New
          </Button>
        </Grid>

        {/* ADD Shared Email BUTTON */}

        <Grid
          item
          xs={6}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            className="add_a_new_user_button"
            size="small"
            onClick={() => setIsSharedEmail(true)}
          >
            View Shared Email
          </Button>
        </Grid>

        {/* EmailTemplate TABLE */}

        <Grid
          item
          xs={12}
          style={{ width: "100%", height: "80vh", overflow: "auto" }}
        >
          <DataGridComponent
            columns={EMAILTEMPLATE_COLUMNS}
            rows={emailTemplateData?.slice(startIndex, endIndex)}
            onRowClick={onRowClick}
          />
        </Grid>

        {/* PAGINATION */}
        <PaginationComponent
          totalCount={paginationData?.totalCount}
          limit={paginationData?.itemsPerPage}
          page={paginationData?.currentPage}
          onChange={handleChangePage}
        />
      </Grid>
    </>
  );
};

export default EmailTemplateTable;
