import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Button,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import BackButton from "../../../../assets/images/BUTTON_Back.svg";
import Loader from "../../../../components/Loader";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { clearTask } from "../../../../slice/task";
import { getMilestone } from "../../../../thunks/lookup";
import { getTaskTriggers, updateTaskConfig } from "../../../../thunks/task";
import PreviewTaskConfig from "./PreviewTaskConfig";

const ConfigureTask = lazy(() => import("./ConfigureTask"));
const SequenceTask = lazy(() => import("./SequenceTask"));

const CreateTaskManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const milestone = useSelector(
    (state) => state?.lookupData?.milestoneData || []
  );
  const [activeStep, setActiveStep] = useState(0);
  const [isLoader, setLoader] = useState(false);
  const [isError, setError] = useState(false);
  const [isPreview, setPreview] = useState(false);
  const taskTriggers = useSelector((state) => state?.task?.taskData);

  /* Method to handle back button from update task to task sequencing */
  const handleBack = () => {
    dispatch(clearTask());
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    getTaskTriggersData();
  };

  // Method to get task trigger data
  const getTaskTriggersData = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getTaskTriggers()).unwrap();
      if (response) {
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  // Method to get milestone
  const getMilestoneType = async () => {
    try {
      const response = await dispatch(getMilestone()).unwrap();
    } catch (error) {}
  };

  // Method to check all task successors are present or last task has no successor.
  const checkIsMilestone = (tasks) => {
    // Validate milestone
    return tasks?.some((task) => task?.milestoneId === null);
  };

  // Method to handleClick verify sequence
  const handleClickVerify = () => {
    const isMilestone = checkIsMilestone(taskTriggers);
    setLoader(true);
    const emptyRow = taskTriggers.find((row) => !row.Task);
    if (emptyRow || isMilestone) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: "Please fill all the details in row before verifying.",
          severity: "error",
        })
      );
    } else {
      setActiveStep(1);
      setLoader(false);
    }
  };

  // Method to check all task successors are present or last task has no successor.
  const validateSuccessors = (tasks) => {
    return (
      tasks
        .slice(0, -1)
        .every(
          (task) => task?.sucessorTaskIds && task?.sucessorTaskIds?.length > 0
        ) || tasks?.length === 1
    );
  };

  // Method to check trigger type available or not.
  const validateTriggerType = (tasks) => {
    return tasks?.some((task) => task?.taskTriggerTypeId === null);
  };

  // Method to open task configuration preview
  const handleClickPreview = () => {
    const isValidPayload = validateSuccessors(taskTriggers);
    if (!isValidPayload) {
      setError(true);
      dispatch(
        showSnackbar({
          show: true,
          message: "Please fill all the details in row before publishing.",
          severity: "error",
        })
      );
    } else {
      setError(false);
      setPreview(true);
    }
  };
  // Method to publish configuration
  const handleClickPublish = async () => {
    // Validate successors
    const isValidPayload = validateSuccessors(taskTriggers);
    const isTriggerType = validateTriggerType(taskTriggers);
    setLoader(true);
    if (!isValidPayload || isTriggerType) {
      setError(true);
      dispatch(
        showSnackbar({
          show: true,
          message: "Please fill all the details in row before publishing.",
          severity: "error",
        })
      );
      setLoader(false);
    } else {
      setError(false);
      const payload = taskTriggers?.map((task, index) => ({
        predecessorTaskIds: task?.predecessorTaskIds?.map(
          (predecessor) => predecessor?.id
        ),
        sucessorTaskIds: task?.sucessorTaskIds?.map(
          (successor) => successor?.id
        ),
        sequenceId: index + 1,
        taskId: task?.Task?.id,
        taskTriggerTypeId: task?.taskTriggerTypeId,
        milestoneId: task?.milestoneId || "",
        milestoneName: task?.milestoneName,
      }));
      try {
        setLoader(true);
        const response = await dispatch(updateTaskConfig(payload)).unwrap();
        if (response) {
          setLoader(false);
          navigate("/app/manage/task/list");
          dispatch(
            showSnackbar({
              show: true,
              message: "Successfully created configuration",
              severity: "success",
            })
          );
        } else {
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: `${error?.message}`,
            severity: "error",
          })
        );
      }
    }
  };

  // Navigate to task master screen
  const handleClickBack = () => {
    navigate("/app/manage/task/list");
  };

  // Use Effect Hook to get task trigger data and milestone type
  useEffect(() => {
    getTaskTriggersData();
    getMilestoneType();
  }, []);

  // Clear instruction form state when component unmount
  useEffect(() => {
    return () => {
      dispatch(clearTask());
    };
  }, []);

  return (
    <>
      <Grid container spacing={2} mt={1}>
        {/* Loader Component */}
        {isLoader && <Loader />}
        {/* Task Configuration Preview Dialog */}
        {isPreview && (
          <PreviewTaskConfig
            open={isPreview}
            handleClose={() => setPreview(false)}
          />
        )}
        {/* Screen tile Section */}
        <Grid item xs={4} style={{ backgroundColor: "white" }}>
          <Typography className="serif_display_regular_26">
            <img
              src={BackButton}
              onClick={handleClickBack}
              style={{
                cursor: "pointer",
                marginRight: "10px",
              }}
            />
            Task Management
          </Typography>
        </Grid>

        {/* Stepper component */}
        <Grid item xs={5}>
          <Grid
            style={{
              border: "1px solid #00ce3f",
              padding: "0.2rem",
              borderRadius: "10px",
            }}
          >
            <Stepper activeStep={activeStep}>
              <Step>
                <StepLabel>Verify Sequence</StepLabel>
              </Step>
              <Step>
                <StepLabel>Configure Task</StepLabel>
              </Step>
            </Stepper>
          </Grid>
        </Grid>

        {/* Button Component */}
        <Grid item xs={3}>
          {/* Back Button */}
          {activeStep === 1 && (
            <Tooltip title="Back to sequence">
              <ArrowBackIcon
                onClick={handleBack}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          )}
          {/* Publish Button */}
          {activeStep === 1 && (
            <>
              <Button
                size="small"
                style={{ width: 100, marginLeft: 10 }}
                className="add_a_new_user_button"
                onClick={handleClickPublish}
              >
                Publish
              </Button>

              <Tooltip title="Preview Task Configuration">
                <IconButton onClick={() => handleClickPreview()}>
                  <RemoveRedEyeIcon className="green" />
                </IconButton>
              </Tooltip>
            </>
          )}
          {/* Verify Button */}
          {activeStep === 0 && (
            <>
              <Button
                size="small"
                style={{ width: 100, marginRight: 20 }}
                className="add_a_new_user_button"
                onClick={() => {
                  handleClickVerify();
                }}
                disabled={taskTriggers?.length <= 0}
              >
                Verify
              </Button>
            </>
          )}
          {/* Add Row Button */}
          {/* {activeStep === 0 && (
            <Tooltip title="Add Row">
              <IconButton style={{ padding: 0 }} onClick={addRow}>
                <AddCircleOutlineIcon
                  style={{
                    backgroundColor: "#01ce3f",
                    borderRadius: 25,
                    color: "white",
                    height: 30,
                    width: 30,
                    // marginLeft: 20,
                  }}
                />
              </IconButton>
            </Tooltip>
          )} */}
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={1} mb={2}>
        {/* Task Sequencing section table */}
        <Grid item xs={12}>
          {activeStep === 0 && (
            <Suspense fallback={<Loader />}>
              {" "}
              <SequenceTask milestone={milestone} />
            </Suspense>
          )}
          {/* Import Configure task component */}
          {activeStep === 1 && (
            <Suspense fallback={<Loader />}>
              <ConfigureTask milestone={milestone} isError={isError} />
            </Suspense>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CreateTaskManagement;
