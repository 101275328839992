import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../../assets/images/BUTTON_Back.svg";
import Loader from "../../../../components/Loader";
import RichTextEditor from "../../../../components/RichTextEditor";
import AlertComponent from "../../../../components/alert";
import "../../../../custom-bootstrap.scss";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import {
  getEmailTemplateDataById,
  updateEmailTemplateThunk,
} from "../../../../thunks/email_template";
import {
  NOTIFICATION_MESSAGE,
  VALIDATION_MSG,
} from "../../../../utils/constant";
import { formValidation } from "./formValidation";
import JoditEditotRichText from "../../../../components/JoditEditotRichText";

const UpdateEmailTemplate = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);

  const [isDeleteDialog, setDeleteDialog] = useState(false);

  const [emailTemplateData, setEmailTemplateData] = useState({
    name: "",
    html: "",
    description: "",
    isActive: null,
  });

  const [isLoader, setLoader] = useState(false);

  /* Get EmailTemplate by ID */
  const getEmailTemplateById = async () => {
    try {
      setLoader(true);
      let data = await dispatch(getEmailTemplateDataById(params.id)).unwrap();
      if (data?.id) {
        setEmailTemplateData({
          ...data,
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  /* Method to change form fields */
  const handleChangeFormFields = (value, key) => {
    setEmailTemplateData({ ...emailTemplateData, [key]: value });
  };

  /* Method to update EmailTemplate */
  const handleUpdateEmailTemplate = async () => {
    // let htmlStr = htmlRef.current.getContent();
    // let payload = { ...emailTemplateData, html: htmlStr };
    let payload = { ...emailTemplateData };

    setEmailTemplateData(payload); // to update html prop

    const isValidForm = formValidation(payload);
    if (!isValidForm) {
      setIsError(true);
    } else {
      setIsError(false);

      try {
        setLoader(true);
        const response = await dispatch(
          updateEmailTemplateThunk({ ...payload, id: params?.id })
        ).unwrap();
        if (response?.id) {
          dispatch(
            showSnackbar({
              message: NOTIFICATION_MESSAGE?.EMAIL_TEMPLATE_UPDATE_SUCCESS,
              severity: "success",
            })
          );
        }
      } catch (error) {
        dispatch(
          showSnackbar({
            message: `${error?.message}`,
            severity: "error",
          })
        );
      } finally {
        setLoader(false);
      }
    }
  };

  /* Success Method to delete/deactivate EmailTemplate */
  const handleSuccessDelete = async () => {
    try {
      const payload = {
        id: Number(params?.id),
        isActive: emailTemplateData?.isActive === true ? false : true,
      };
      setLoader(true);
      const response = await dispatch(
        updateEmailTemplateThunk(payload)
      ).unwrap();
      if (response) {
        setLoader(false);
        setDeleteDialog(false);
        if (
          emailTemplateData?.isActive
            ? dispatch(
                showSnackbar({
                  show: true,
                  message:
                    NOTIFICATION_MESSAGE?.EMAIL_TEMPLATE_DEACTIVATE_SUCCESS,
                  severity: "success",
                })
              )
            : dispatch(
                showSnackbar({
                  show: true,
                  message:
                    NOTIFICATION_MESSAGE?.EMAIL_TEMPLATE_ACTIVATE_SUCCESS,
                  severity: "success",
                })
              )
        )
          navigate("../list");
      }
    } catch (error) {
      setLoader(false);
      setDeleteDialog(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
      navigate("../list");
    }
  };

  /* Confirmation method to disbale EmailTemplate */
  const handleDeleteEmailTemplate = () => {
    setDeleteDialog(true);
  };

  useEffect(() => {
    getEmailTemplateById();
  }, []);

  const htmlRef = useRef(null);

  return (
    <>
      {/* Loader component */}
      {isLoader && <Loader />}
      {/* Confirmation dialog to delete EmailTemplate */}
      {isDeleteDialog && (
        <AlertComponent
          open={isDeleteDialog}
          handleSuccess={handleSuccessDelete}
          handleClose={() => setDeleteDialog(false)}
          message={`Are you sure, want to ${
            emailTemplateData?.isActive === true ? "Delete" : "Activate"
          } email template?`}
        />
      )}
      {/* Form Layout */}
      <Grid
        container
        spacing={2}
        rowGap={3}
        justify="flex-start"
        className="create-user instruction_dashboard_scrollbar"
        style={{
          height: "90vh",
          overflow: "auto",
        }}
        pl={5}
        pr={5}
      >
        {/* ADD NEW  BUTTON */}
        <Grid item xs={12}>
          <Typography className="serif_display_regular_26">
            <img
              src={BackButton}
              onClick={() => navigate("../list")}
              style={{
                cursor: "pointer",
                marginRight: "10px",
              }}
            />
            Manage Email Template
          </Typography>
        </Grid>

        {/* EmailTemplate Name */}
        <Grid item xs={2.5} mt={0.5}>
          <TextField
            required
            className="inputTextField normal_normal_600_12_Manropee"
            label="Name"
            placeholder="Name"
            fullWidth
            size="small"
            error={isError && !emailTemplateData?.name}
            helperText={
              isError && !emailTemplateData?.name && VALIDATION_MSG.REQUIRED
            }
            value={emailTemplateData?.name}
            onChange={(e) => handleChangeFormFields(e?.target?.value, "name")}
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>

        {/* EmailTemplate Description */}
        <Grid item xs={5}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            fullWidth
            label="Description"
            placeholder="Description"
            multiline
            maxRows={4}
            value={emailTemplateData?.description}
            onChange={(e) =>
              handleChangeFormFields(e?.target?.value, "description")
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            fullWidth
            label="Subject"
            placeholder="Subject"
            multiline
            maxRows={4}
            value={emailTemplateData?.subject}
            onChange={(e) =>
              handleChangeFormFields(e?.target?.value, "subject")
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>
        {/* template editor */}
        <Grid xs={12} px={2} mb={2}>
          <Typography color="#00CE3F" mb={1}>
            Content *
          </Typography>
          {/* <RichTextEditor
            initialValue={emailTemplateData?.html}
            ref={htmlRef}
            height={320}
          /> */}
          <JoditEditotRichText
            value={emailTemplateData?.html || ""}
            height={320}
            ref={htmlRef}
            onChange={(value) => handleChangeFormFields(value, "html")}
          />
          {isError && !emailTemplateData?.html && (
            <Typography color={"error"}>{VALIDATION_MSG.REQUIRED}</Typography>
          )}
        </Grid>
        {/* todo add variable dynamic fields */}

        {/* EmailTemplate activate / deactivate / save button */}

        <Grid container spacing={2} className="Save_Button_Container" pb={2}>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "space-evenly" }}
            m={1}
          >
            <Grid>
              <Button
                variant="outlined"
                className="deactivate_button"
                onClick={handleDeleteEmailTemplate}
              >
                Delete
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            xs={5}
            sx={{ display: "flex", justifyContent: "flex-end" }}
            m={1}
          >
            <Button
              variant="contained"
              className="save_button"
              onClick={handleUpdateEmailTemplate}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateEmailTemplate;
