import {
  Autocomplete,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../../components/alert";
import Loader from "../../components/Loader";
import PaginationComponent from "../../components/PaginationComponent";
import { showSnackbar } from "../../slice/snackbarSlice";
import { setTaskData } from "../../slice/task";
import { getThingsToPick } from "../../thunks/dashboard";
import {
  allocateOrCompleteIndividualTask,
  allocateOrCompleteTask,
} from "../../thunks/instruction";
import { getAllUser } from "../../thunks/user";
import {
  NOTIFICATION_MESSAGE,
  PAGINATION_LIMIT,
  TASK_TYPES,
  USER_ROLE,
} from "../../utils/constant";
import { handleTasktype } from "../../utils/util";
import DueDateDialog from "../Instructions/components/DueDateDialog";
const ThingsToPick = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const [isLoader, setLoader] = useState(false);
  const [thingsToPickData, setThingsToPickData] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [selectedDueDateData, setSelectedDueDateData] = useState(null);
  const [isOpenDueDate, setOpenDueDate] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [isTaskCompleted, setTaskComplete] = useState(false);
  const [completeTaskId, setCompleteTaskId] = useState(null);
  const lookups = useSelector((state) => state?.lookupData);
  let { Role: roles } = lookups || {};
  const taskTypes = TASK_TYPES;

  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: PAGINATION_LIMIT,
    taskType: userDetails?.role === USER_ROLE?.CONSULTANT ? "All" : "All",
    userId: null,
    roleToSearch: [],
  });

  const getThingsToPickData = async (payload = filterPayload) => {
    try {
      setLoader(true);
      const response = await dispatch(getThingsToPick(payload)).unwrap();
      if (response) {
        setThingsToPickData(response);
        setTotalCount(response?.recordLength);
        setLoader(false);
      } else {
        setThingsToPickData([]);
        setTotalCount(0);
        setLoader(false);
      }
    } catch (error) {
      return null;
    }
  };

  /* Method to change page --> Pagination */
  const handleChangePage = (event, value) => {
    setFilterPayload({ ...filterPayload, page: value });
    const payload = {
      ...filterPayload,
      page: value,
    };
    getThingsToPickData(payload);
  };

  // Method to handle change task type
  const handleChangeTaskType = (value) => {
    setFilterPayload({ ...filterPayload, page: 1, taskType: value });
    const payload = {
      ...filterPayload,
      taskType: value,
      page: 1,
    };
    getThingsToPickData(payload);
  };

  // Method to handle change role
  const handleChangeRole = (value) => {
    setFilterPayload({
      ...filterPayload,
      page: 1,
      roleToSearch: value !== null ? [value] : [],
    });
    const payload = {
      ...filterPayload,
      roleToSearch: value !== null ? [value] : [],
      page: 1,
    };

    getThingsToPickData(payload);
  };

  // Method to handle change user
  const handleChangeUser = (value) => {
    setFilterPayload({ ...filterPayload, page: 1, userId: value });
    const payload = {
      ...filterPayload,
      userId: value,
      page: 1,
    };
    getThingsToPickData(payload);
  };

  // Method to check date if date is greater then current date
  const checkDate = (data) => {
    // Parse the provided date into a Moment.js object
    const tempCurrentDate = moment().format("DD/MM/YYYY hh:mm A");
    const tempProvidedDate = moment(data?.dueDate || null).format(
      "DD/MM/YYYY hh:mm A"
    );
    const tempEndDate = moment(data?.endDate || null).format(
      "DD/MM/YYYY hh:mm A"
    );
    const providedDate = moment(tempProvidedDate, "DD/MM/YYYY hh:mm A");
    const currentDate = moment(tempCurrentDate, "DD/MM/YYYY hh:mm A");
    const endDate = moment(tempEndDate, "DD/MM/YYYY hh:mm A");
    // Compare the dates
    const isDateBeforeComplete = providedDate.isBefore(currentDate);
    const isDateAfterComplete = providedDate.isBefore(endDate);
    if (isDateBeforeComplete && !data?.isCompleted) {
      return true;
    } else if (isDateAfterComplete && data?.isCompleted) {
      return true;
    } else {
      return false;
    }
  };

  // Method to get all users
  const getUsers = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getAllUser()).unwrap();
      if (response) {
        setLoader(false);
        setUsersList(response);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  /* Method to open due date dialog if task status is not completed */
  const handleClickOpenDueDate = (data) => {
    let tempObj = {
      id: data?.id,
      dueDate: data.dueDate,
      taskType: data?.taskType,
    };
    setSelectedDueDateData(tempObj);
    setOpenDueDate(true);
  };
  //METHOD TO HANDLE RE-DIRECTION ONCLLICK OF DASHBOARD'S TASK
  const handleRedirect = (
    instructionId,
    taskType,
    taskId,
    instructionProductId
  ) => {
    if (taskType && ["Custom", "Instance"].includes(taskType)) {
      dispatch(setTaskData({ taskType, instructionId, taskId }));
    } else {
      dispatch(setTaskData({ instructionId, taskId, instructionProductId }));
    }
    navigate(`/app/instructions/dashboard/${instructionId}`);
  };
  /* Method to complete task */
  const handleClickCompleteTask = (data, e) => {
    e.stopPropagation();
    setTaskComplete(true);
    setCompleteTaskId(data);
  };

  /* Success method to update task status to Completed */
  const handleCompleteTaskSuccess = async () => {
    setTaskComplete(false);
    const payload = {
      id: completeTaskId?.id,
      status: "Completed",
    };
    try {
      setLoader(true);
      const response = await dispatch(
        completeTaskId?.taskType === "Custom" ||
          completeTaskId?.taskType === "Instance"
          ? allocateOrCompleteIndividualTask(payload)
          : allocateOrCompleteTask(payload)
      ).unwrap();
      setLoader(false);
      if (response) {
        getThingsToPickData();
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.COMPLETE_TASK_SUCCESS,
            severity: "success",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error?.message,
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    getThingsToPickData();
    getUsers();
  }, []);

  return (
    <>
      {/* Dialog for update due date */}
      {isOpenDueDate && (
        <DueDateDialog
          isIndividual={selectedDueDateData?.taskType !== undefined}
          isDashboardTask={true}
          open={isOpenDueDate}
          handleClose={() => setOpenDueDate(false)}
          selectedData={selectedDueDateData}
          getThingsToPickData={getThingsToPickData}
        />
      )}
      {/* Dialog for Complete Task Confirmation */}
      {isTaskCompleted && (
        <AlertComponent
          open={isTaskCompleted}
          handleSuccess={handleCompleteTaskSuccess}
          handleClose={() => setTaskComplete(false)}
          message="Are you sure you want to complete the task?"
        />
      )}
      {/* Loader Component */}
      {isLoader && <Loader />}

      <Grid container spacing={3}>
        {/* TASK  */}
        <Grid item xs={3}>
          <FormControl
            fullWidth
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
          >
            <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
              Task Type
            </InputLabel>
            <Select
              className="textField_outlined_border"
              label="Task Type"
              value={filterPayload?.taskType}
              onChange={(e) => handleChangeTaskType(e?.target?.value)}
            >
              {taskTypes?.length > 0 &&
                taskTypes.map((d) => (
                  <MenuItem
                    key={d?.id}
                    value={d?.value}
                    className="normal_normal_medium_14_Manrope dark_grey"
                  >
                    {d?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {userDetails?.role === USER_ROLE.SUPER_ADMIN && (
          <>
            {/* ROLE  */}
            <Grid item xs={3}>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                  },
                }}
              >
                <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                  Role
                </InputLabel>
                <Select
                  className="textField_outlined_border"
                  label="Task Type"
                  value={roles?.name}
                  onChange={(e) => handleChangeRole(e?.target?.value)}
                >
                  <MenuItem value={null}>--</MenuItem>
                  {roles?.length > 0 &&
                    roles.map((d) => (
                      <MenuItem
                        key={d?.id}
                        value={d?.value}
                        className="normal_normal_medium_14_Manrope dark_grey"
                      >
                        {d?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {/* USER */}
            <Grid item xs={6}>
              <Autocomplete
                // value={userData?.TaskUser}
                id="tags-outlined"
                options={usersList || []}
                getOptionLabel={(option) =>
                  `${option?.firstName} ${option?.lastName} (${option?.role})`
                }
                onChange={(event, value) => handleChangeUser(value?.id)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="normal_normal_medium_14_Manropee textField_outlined_border"
                    variant="outlined"
                    size="small"
                    label="Select user"
                    placeholder="Select user"
                  />
                )}
              />
            </Grid>
          </>
        )}

        <Grid item xs={userDetails?.role === USER_ROLE.SUPER_ADMIN ? 12 : 9}>
          <PaginationComponent
            totalCount={totalCount}
            limit={filterPayload?.limit}
            page={filterPayload?.page}
            onChange={handleChangePage}
            siblingCount={0}
            boundaryCount={1}
          />
        </Grid>
      </Grid>

      {/* Things to pick List section */}
      <Grid
        display={"flex"}
        flexDirection={"column"}
        overflow={"auto"}
        className="dashboard-scrollbar"
        gap={1}
        px={2}
        py={1}
      >
        {filterPayload?.taskType === "Instruction" &&
          thingsToPickData?.allPickableTasks?.length > 0 &&
          thingsToPickData?.allPickableTasks?.map((data) => (
            <>
              <Grid
                item
                xs={12}
                display={"flex"}
                flexDirection={"row"}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleRedirect(
                    data?.instructionId,
                    data?.taskType,
                    data?.id,
                    data?.instructionProductId
                  )
                }
              >
                <Grid item xs={5}>
                  {data?.instruction?.InstructionLeadClient?.length > 0 &&
                    data?.instruction?.InstructionLeadClient?.map((client) => {
                      return (
                        <Typography className="normal_normal_600_16_Manrope green">
                          {`${client?.LeadClient?.firstName} ${client?.LeadClient?.lastName}`}
                        </Typography>
                      );
                    })}
                </Grid>

                <Grid
                  item
                  xs={12}
                  // style={{
                  //   padding: "12px",
                  // }}
                >
                  <Typography className="normal_normal_bold_14_Manrope dark_grey">
                    {data?.milestoneName?.toUpperCase() || "NA"}
                  </Typography>

                  <Typography className="normal_normal_14_Manrope dark_grey">
                    {data?.name?.toUpperCase() || "NA"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={handleTasktype(data?.taskType)?.taskType}
                    style={{
                      color: handleTasktype(data?.taskType)?.colorCode,
                    }}
                  />
                </Grid>
              </Grid>
              {/* Due Date Section */}
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography className="serif_display_regular_20">
                  <Tooltip title="Update Due Date">
                    <span
                      className={`normal_normal_600_14_Manrope ${
                        checkDate(data) ? "red" : "green"
                      }`}
                      style={{ marginLeft: "1rem", cursor: "pointer" }}
                      onClick={() => handleClickOpenDueDate(data)}
                    >
                      (Due by:-{" "}
                      {data?.dueDate
                        ? moment(new Date(data?.dueDate)).format(
                            "DD/MM/YYYY hh:mm A"
                          )
                        : "NA"}
                      )
                    </span>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ border: `1px solid #DCDCE1`, width: "100%" }} />
              </Grid>
            </>
          ))}

        {filterPayload?.taskType === "Individual" &&
          thingsToPickData?.individualTasks?.length > 0 &&
          thingsToPickData?.individualTasks?.map((data) => (
            <>
              <Grid
                item
                xs={12}
                display={"flex"}
                flexDirection={"row"}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleRedirect(
                    data?.instructionId,
                    data?.taskType,
                    data?.id,
                    data?.instructionProductId
                  )
                }
              >
                <Grid item xs={5}>
                  {data?.instruction?.InstructionLeadClient?.length > 0 &&
                    data?.instruction?.InstructionLeadClient?.map((client) => {
                      return (
                        <Typography className="normal_normal_600_16_Manrope green">
                          {`${client?.LeadClient?.firstName} ${client?.LeadClient?.lastName}`}
                        </Typography>
                      );
                    })}
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    padding: "12px",
                  }}
                >
                  <Typography className="normal_normal_bold_14_Manrope dark_grey">
                    {data?.instruction?.milestone?.toUpperCase() || "NA"}
                  </Typography>
                  <Typography className="normal_normal_14_Manrope dark_grey">
                    {data?.instructionTasks?.name || "NA"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={handleTasktype(data?.taskType)?.taskType}
                    style={{
                      color: handleTasktype(data?.taskType)?.colorCode,
                    }}
                  />
                </Grid>
              </Grid>
              {/* Due Date Section */}
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography className="serif_display_regular_20">
                  <Tooltip title="Update Due Date">
                    <span
                      className={`normal_normal_600_14_Manrope ${
                        checkDate(data) ? "red" : "green"
                      }`}
                      style={{ marginLeft: "1rem", cursor: "pointer" }}
                      onClick={() => handleClickOpenDueDate(data)}
                    >
                      (Due by:-{" "}
                      {data?.dueDate
                        ? moment(new Date(data?.dueDate)).format(
                            "DD/MM/YYYY hh:mm A"
                          )
                        : "NA"}
                      )
                    </span>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ border: `1px solid #DCDCE1`, width: "100%" }} />
              </Grid>
            </>
          ))}

        {filterPayload?.taskType === "Custom" &&
          thingsToPickData?.individualTasks?.length > 0 &&
          thingsToPickData?.individualTasks?.map((data) => (
            <>
              <Grid
                item
                xs={12}
                display={"flex"}
                flexDirection={"row"}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleRedirect(
                    data?.instructionId,
                    data?.taskType,
                    data?.id,
                    data?.instructionProductId
                  )
                }
              >
                <Grid item xs={5}>
                  {data?.instruction?.InstructionLeadClient?.length > 0 &&
                    data?.instruction?.InstructionLeadClient?.map((client) => {
                      return (
                        <Typography className="normal_normal_600_16_Manrope green">
                          {`${client?.LeadClient?.firstName} ${client?.LeadClient?.lastName}`}
                        </Typography>
                      );
                    })}
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    padding: "12px",
                  }}
                >
                  <Typography className="normal_normal_bold_14_Manrope dark_grey">
                    {data?.instruction?.milestone || "NA"}
                  </Typography>
                  <Typography className="normal_normal_14_Manrope dark_grey">
                    {data?.name || "NA"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={handleTasktype(data?.taskType)?.taskType}
                    style={{
                      color: handleTasktype(data?.taskType)?.colorCode,
                    }}
                  />
                </Grid>
              </Grid>
              {/* Due Date Section */}
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography className="serif_display_regular_20">
                  <Tooltip title="Update Due Date">
                    <span
                      className={`normal_normal_600_14_Manrope ${
                        checkDate(data) ? "red" : "green"
                      }`}
                      style={{ marginLeft: "1rem", cursor: "pointer" }}
                      onClick={() => handleClickOpenDueDate(data)}
                    >
                      (Due by:-{" "}
                      {data?.dueDate
                        ? moment(new Date(data?.dueDate)).format(
                            "DD/MM/YYYY hh:mm A"
                          )
                        : "NA"}
                      )
                    </span>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ border: `1px solid #DCDCE1` }} />
              </Grid>
            </>
          ))}

        {filterPayload?.taskType === "All" &&
          thingsToPickData?.allPickableTasks?.length > 0 &&
          thingsToPickData?.allPickableTasks?.map((data) => (
            <>
              <Grid
                item
                xs={12}
                display={"flex"}
                flexDirection={"row"}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleRedirect(
                    data?.instructionId,
                    data?.taskType,
                    data?.id,
                    data?.instructionProductId
                  )
                }
              >
                <Grid item xs={5}>
                  {data?.instruction?.InstructionLeadClient?.length > 0 &&
                    data?.instruction?.InstructionLeadClient?.map((client) => {
                      return (
                        <Typography className="normal_normal_600_16_Manrope green">
                          {`${client?.LeadClient?.firstName} ${client?.LeadClient?.lastName}`}
                        </Typography>
                      );
                    })}
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    padding: "12px",
                  }}
                >
                  <Typography className="normal_normal_bold_14_Manrope dark_grey">
                    {data?.instruction?.milestone || "NA"}
                  </Typography>
                  <Typography className="normal_normal_14_Manrope dark_grey">
                    {data?.name || "NA"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={handleTasktype(data?.taskType)?.taskType}
                    style={{
                      color: handleTasktype(data?.taskType)?.colorCode,
                    }}
                  />
                </Grid>
              </Grid>
              {/* Due Date Section */}
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography className="serif_display_regular_20">
                  <Tooltip title="Update Due Date">
                    <span
                      className={`normal_normal_600_14_Manrope ${
                        checkDate(data) ? "red" : "green"
                      }`}
                      style={{ marginLeft: "1rem", cursor: "pointer" }}
                      onClick={() => handleClickOpenDueDate(data)}
                    >
                      (Due by:-{" "}
                      {data?.dueDate
                        ? moment(new Date(data?.dueDate)).format(
                            "DD/MM/YYYY hh:mm A"
                          )
                        : "NA"}
                      )
                    </span>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ border: `1px solid #DCDCE1` }} />
              </Grid>
            </>
          ))}
      </Grid>
    </>
  );
};

export default ThingsToPick;
