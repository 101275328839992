import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./components/dashboard";

const IntroducerDashboard = () => {
  return (
    <>
      <div className="main-page-container">
        <Routes>
          <Route path={"/"} element={<Dashboard />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default IntroducerDashboard;
