import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../../components/Loader";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import {
  addDocumentSubTypeData,
  updateDocumentSubType,
} from "../../../../thunks/documents";

const AddUpdateDocumentSubType = ({
  open,
  handleClose,
  getDocumentSubTypeData,
  selectedData,
}) => {
  const dispatch = useDispatch();
  const [documentSubType, setDocumentSubType] = useState(null);
  const [isLoader, setLoader] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const handleClickCreateSubType = async () => {
    const payload = {
      id: selectedData?.id,
      name: documentSubType,
      isActive: isActive,
    };
    try {
      setLoader(true);
      let checkDispatch = selectedData
        ? updateDocumentSubType(payload)
        : addDocumentSubTypeData(payload);
      const response = await dispatch(checkDispatch).unwrap();
      if (response) {
        dispatch(
          showSnackbar({
            message: selectedData
              ? "Document sub type updated successfully"
              : "Document sub type created successfully",
            severity: "success",
          })
        );
        setLoader(false);
        handleClose();
        getDocumentSubTypeData();
      } else {
        handleClose();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      handleClose();
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
      return error;
    }
  };

  useEffect(() => {
    if (selectedData) {
      setDocumentSubType(selectedData?.name);
    } else {
      setDocumentSubType(null);
    }
  }, [selectedData]);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
        PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* Loader Component */}
        {isLoader && <Loader />}
        <DialogContent>
          <Grid container spacing={3}>
            {/* Create Task Label Section */}
            <Grid item xs={12}>
              <Typography
                className="serif_display_regular_26 green"
                textAlign={"center"}
              >
                Document Sub Type
              </Typography>
            </Grid>

            {/* Task Name Field Section */}
            <Grid item xs={12}>
              <TextField
                className="inputTextField normal_normal_600_12_Manropee"
                label="Document Sub Type"
                placeholder="Document Sub Type"
                fullWidth
                required
                size="small"
                value={documentSubType || null}
                onChange={(e) => setDocumentSubType(e?.target?.value)}
                // error={isError && !taskData?.name}
                // helperText={
                //   isError && !taskData?.name && VALIDATION_MSG.REQUIRED
                // }
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>

            {/* Submit Button */}
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {/* Is Active section */}
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#00CE3F",
                        "&.Mui-checked": {
                          color: "#00CE3F",
                        },
                      }}
                      checked={isActive || false}
                      onChange={(e) => setIsActive(e?.target?.checked)}
                    />
                  }
                  label="Is Active?"
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  className="save_button"
                  onClick={handleClickCreateSubType}
                >
                  {selectedData ? "Update" : "Create"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddUpdateDocumentSubType;
