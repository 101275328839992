import { Tooltip, Typography } from "@mui/material";

export const PRODUCT_TABLE_COLUMNS = [
  {
    field: "productName",
    headerName: "Name",
    minWidth: 350,
    sortable: false,
  },
  {
    field: "InstructionTypeProduct",
    headerName: "Instruction Type",
    minWidth: 200,
    sortable: false,
  },
  {
    field: "standardFees",
    headerName: "Standard Fees",
    minWidth: 180,
    sortable: false,
  },
  {
    field: "perVat",
    headerName: "Vat In %",
    minWidth: 180,
    sortable: false,
    renderCell: (params) => `${params?.value}%`,
  },

  {
    field: "totalPrice",
    headerName: "Total Price",
    minWidth: 180,
    sortable: false,
  },

  {
    field: "isActive",
    headerName: "Status",
    minWidth: 180,
    sortable: false,
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.isActive ? "Active" : "Inactive"}`}>
        <Typography
          className="normal_normal_16_Manrope"
          style={{
            color: params?.row?.isActive ? "#00ce3f" : "#FF0000",
          }}
        >
          {String(params?.row?.isActive ? "Active" : "Inactive")}
        </Typography>
      </Tooltip>
    ),
  },
];
