import { emailValidation } from "../../../utils/util";

// Form Validatin for client create / Update
export const formValidation = (data) => {
  if (
    !data?.firstName ||
    !data?.lastName ||
    !data?.mobileNo ||
    // !phoneNumberValidation(data?.mobileNo) ||
    !data?.sourceTypeLookupId ||
    // !data?.sourceSubType ||
    (data?.email && !emailValidation(data?.email))
  ) {
    return false;
  } else {
    return true;
  }
};
//METHOD TO VALIDATE FILTERS
export const FilterValidation = (data, selectedTimeUnit) => {
  if (
    !data?.name ||
    !data?.filtertype ||
    !data?.timeUnit ||
    data?.timeValue === null ||
    (selectedTimeUnit === "week" && (!data?.weekStartDay || !data?.weekEndDay))
  ) {
    return false;
  } else {
    return true;
  }
};
