import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";

import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { React, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import {
  createNote,
  getAllMeetings,
  getNotesByClientId,
} from "../../../thunks/client";
import { NOTIFICATION_MESSAGE } from "../../../utils/constant";
import SingleNoteContainer from "../../Instructions/components/SingleNoteContainer";

const AddViewNote = ({ open, handleClose, clientId }) => {
  const dispatch = useDispatch();
  const [isLoader, setLoader] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [note, setNote] = useState("");
  const [notesData, setNotesData] = useState([]);
  const [expandedNotes, setExpandedNotes] = useState({});
  const [meetings, setMeetings] = useState([]);
  // Method to Handle change tab value
  const handleChangeTabValue = (event, newVal) => {
    setTabValue(newVal);
    if (newVal === 1) {
      getNotes();
    } else if (newVal === 2) {
      getMeetingsByClientId();
    } else {
      return null;
    }
  };

  // Method to get notes
  const getNotes = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getNotesByClientId(clientId)).unwrap();
      if (response?.leadClientNotes?.length > 0) {
        setNotesData(response?.leadClientNotes);
        setLoader(false);
      } else {
        setLoader(false);
        setNotesData([]);
      }
    } catch (error) {
      setNotesData([]);
      setLoader(false);
      return error;
    }
  };

  // Method to get clients scheduled meetings
  const getMeetingsByClientId = async () => {
    /* Method to get meetings */
    setLoader(true);
    try {
      const response = await dispatch(
        getAllMeetings({
          query: {
            status: "scheduled",
            milestone: "Not Instructed",
            leadClientId: clientId,
          },
        })
      ).unwrap();
      if (response?.length > 0) {
        setMeetings(response);
        setLoader(false);
      } else {
        setMeetings([]);
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE.MEETING_DATA_NOT_FOUND_ERROR,
            severity: "error",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  // Method to create note
  const handleCreateNote = async () => {
    try {
      const payload = {
        clientId: clientId,
        note: note,
      };
      setLoader(true);
      const response = await dispatch(createNote(payload)).unwrap();
      if (response) {
        setLoader(false);
        handleClose();
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.SUCCESS_NOTE_CREATE,
            severity: "success",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      handleClose();
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  /* Method to read more and read less */
  const toggleExpand = (id) => {
    setExpandedNotes((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const convertJson = (data) => {
    let tempData = [];
    try {
      if (data && data.trim()) {
        tempData = JSON.parse(data);

        // Ensure data is an array
        if (!Array.isArray(tempData)) {
          throw new Error("Parsed data is not an array");
        }
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
    return tempData?.[1]?.answer || "NA";
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers={true} sx={{ marginRight: "5px" }}>
          {isLoader && <Loader />}
          <Grid item xs={12}>
            <Tabs
              fullWidth
              value={tabValue}
              onChange={handleChangeTabValue}
              aria-label="Notes"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#00CE3F",
                },
                "& .MuiTab-root.Mui-selected": {
                  color: "#00CE3F",
                  fontWeight: 600,
                },
                "& .MuiTab-root": {
                  color: "#3D4740",
                },
              }}
            >
              <Tab label="Add Note" />
              <Tab label="View Notes" />
              <Tab label="Meetings" />
            </Tabs>
          </Grid>

          {/* Add  Note Component */}
          {tabValue === 0 && (
            <>
              <Grid item xs={12} p={2}>
                <TextField
                  label="Add Note"
                  fullWidth
                  placeholder="Enter a Note"
                  multiline
                  rows={5}
                  value={note}
                  onChange={(e) => setNote(e?.target?.value)}
                  sx={{
                    "& label.Mui-focused": {
                      color: "#00ce3f",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#00ce3f",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#00ce3f",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "2rem 0 0 0",
                  gap: "1rem",
                }}
              >
                <Button className="cancel_button" onClick={() => handleClose()}>
                  Cancel
                </Button>
                <Button onClick={handleCreateNote} className="save_button">
                  Save
                </Button>
              </Grid>
            </>
          )}

          {/* View Notes Component */}
          {tabValue === 1 && (
            <Grid container spacing={3} mt={1}>
              {notesData?.length > 0 ? (
                notesData?.map((note) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid>
                        <Typography className="normal_normal_600_14_Manrope dark_grey">
                          Author
                        </Typography>
                        <Typography className="normal_normal_medium_14_Manrope dark_grey">
                          {note?.createdBy.firstName || "NA"}{" "}
                          {note?.createdBy.lastName || "NA"}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography className="normal_normal_medium_14_Manrope dark_gray">
                        {expandedNotes[note?.id]
                          ? note?.note
                          : `${note?.note.slice(0, 50)}`}
                        {note?.note?.length > 50 && (
                          <Typography
                            onClick={() => toggleExpand(note?.id)}
                            className="normal_normal_medium_14_Manrope green"
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            {expandedNotes[note?.id]
                              ? "Show Less"
                              : "Show More"}
                          </Typography>
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider style={{ border: "1px solid #00ce3f" }} />
                    </Grid>
                  </>
                ))
              ) : (
                <Grid item xs={12} m={1}>
                  <Typography className="normal_normal_18_Manrope dark_gray">
                    No Data Found.
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}

          {/* Meeting Schedule withs Clients */}
          {tabValue === 2 && (
            <Grid container spacing={3} mt={1}>
              {meetings?.length > 0 &&
                meetings?.map((data) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", gap: 10, alignItems: "center" }}
                    >
                      <IconButton>
                        <CalendarMonthIcon style={{ color: "#00CE3F" }} />
                      </IconButton>
                      <Typography className="normal_normal_600_14_Manrope dark_grey">
                        {`Your ${
                          data?.eventTypeName
                        } meeting is scheduled on ${moment(
                          data?.meetingDate
                        ).format("DD/MM/yyyy")} at ${moment(
                          data?.meetingDate
                        ).format("HH:mm A")} `}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", gap: 10, alignItems: "center" }}
                    >
                      <IconButton>
                        <SpeakerNotesIcon style={{ color: "#00CE3F" }} />
                      </IconButton>
                      <Typography className="normal_normal_medium_14_Manrope dark_gray">
                        <SingleNoteContainer note={convertJson(data?.notes)} />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ border: `1px solid #DCDCE1` }} />
                    </Grid>
                  </>
                ))}
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AddViewNote;
