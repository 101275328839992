import { Tooltip, Typography } from "@mui/material";

export const USER_TABLE_COLUMNS = [
  {
    field: "firstName",
    headerName: "Name",
    width: 200,
    sortable: false,
    renderCell: (params) => {
      return (
        <Typography className="normal_normal_16_Manrope dark_grey">
          {`${params?.row?.firstName} ${params?.row?.lastName}`}
        </Typography>
      );
    },
  },
  { field: "role", headerName: "Role", width: 200, sortable: false },
  {
    field: "phoneNumber",
    headerName: "Work Number",
    width: 200,
    sortable: false,
  },
  {
    field: "email",
    headerName: "Email",
    width: 350,
    sortable: false,
  },
  {
    field: "postalCode",
    headerName: "Post Code",
    width: 150,
    sortable: false,
  },
  {
    field: "taskSpecialization",
    headerName: "Speciality",
    width: 350,
    sortable: false,
    renderCell: (params) => {
      const taskSpecializationArray = params?.row?.taskSpecialization || [];
      const firstTask = taskSpecializationArray[0]?.taskName
        ? taskSpecializationArray[0]?.taskName
        : "NA";
      const remainingTasksCount = taskSpecializationArray.length - 1;

      return (
        <>
          <Typography mr={1} className="normal_normal_16_Manrope dark_grey">
            {firstTask}
          </Typography>
          <Typography className="normal_normal_16_Manrope green">
            {remainingTasksCount > 0 ? ` +${remainingTasksCount}` : ""}
          </Typography>
        </>
      );
    },
  },

  {
    field: "isActive",
    headerName: "Status",
    sortable: false,
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.isActive ? "Active" : "Inactive"}`}>
        <Typography
          className="normal_normal_16_Manrope"
          style={{
            color: params?.row?.isActive ? "#00ce3f" : "#FF0000",
          }}
        >
          {String(params?.row?.isActive ? "Active" : "Inactive")}
        </Typography>
      </Tooltip>
    ),
  },
];
