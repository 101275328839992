import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import EditIcon from "@mui/icons-material/Edit";
import {
  CreatePreset,
  getPresets,
  getReportParams,
  UpdatePreset,
  UpdateReportParams,
} from "../../../../thunks/company";
import Loader from "../../../../components/Loader";
import { getLookups } from "../../../../thunks/lookup";
import {
  NOTIFICATION_MESSAGE,
  VALIDATION_MSG,
  WEEKDAYS,
} from "../../../../utils/constant";
import DataGridComponent from "../../../../components/DataGridComponent";
import { FilterValidation } from "../../../client/components/formValidation";

const Company = () => {
  const dispatch = useDispatch();
  const [selectedTimeUnit, setSelectedTimeUnit] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [isError, setIsError] = useState({
    isRequired: false,
    isNegative: false,
  });
  const [reportParams, setReportParams] = useState([]); // TO SHOW REPORT PARAMETERS
  const [filterData, setFilterData] = useState([]); // TO SHOW PRESETS/FILTERS IN TABLE
  //DATA OF FILTERS/PRESETS TO SAVE & UPDATE
  const [preset, setPreset] = useState({
    name: "",
    timeValue: null,
    timeUnit: null,
    filtertype: null,
    weekStartDay: null,
    weekEndDay: null,
    is_default: false,
  });
  //DROPDOWN VALUES FROM LOOKUP
  const [filters, setFilters] = useState({
    filtertype: {},
    timeUnit: {},
  });
  const columns = [
    {
      field: "name",
      headerName: " Name",
      width: 140,
    },
    {
      field: "filterType",
      headerName: "Filter type",
      width: 140,
      renderCell: (params) => (
        <>
          <Typography>{params?.row?.filterType?.label} </Typography>
        </>
      ),
    },

    {
      field: "timeUnit",
      headerName: "Time unit",
      width: 120,
      renderCell: (params) => (
        <>
          <Typography>{params?.row?.timeUnit?.label} </Typography>
        </>
      ),
    },
    {
      field: "time_value",
      headerName: "Time value",
      width: 120,
    },
    {
      field: "is_default",
      headerName: "Default filter",
      width: 120,
      renderCell: (params) => (
        <>
          <Checkbox
            sx={{
              color: "#00CE3F",
              "&.Mui-checked": {
                color: "#00CE3F",
              },
            }}
            checked={params?.row?.is_default}
            disabled
          />
        </>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => (
        <>
          <Tooltip title="Update Task">
            <IconButton onClick={() => handleUpdatePreset(params?.row)}>
              <EditIcon className="green" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  // METHOD TO GET REPORT PARAMS
  const getReportData = async () => {
    try {
      setLoader(true);
      const res = await dispatch(getReportParams()).unwrap();
      if (res) {
        setReportParams(res);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };
  //METHOD TO CHANGE/UPDATE PARAMS STATUS
  const ChangeReportParamStatus = async (value, id) => {
    try {
      setLoader(true);
      let payload = {
        id: id,
        isVisible: value,
      };
      const res = await dispatch(UpdateReportParams(payload)).unwrap();
      if (res) {
        //API CALL TO REFRESH SCREEN AUTOMATICALLY.
        getReportData();
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: `${NOTIFICATION_MESSAGE.REPORT_UPDATED_SUCESS}`,
            severity: "success",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };
  // GET FILTERS DATA FROM LOOKUP IN PRESET SECTION
  const getFilters = async () => {
    try {
      setLoader(true);
      const lookupKeys = ["filterType", "timeUnit"];
      const response = await dispatch(
        getLookups({ searchStrings: JSON.stringify(lookupKeys) })
      );
      setFilters({
        ...filters,
        filtertype: response?.payload[0],
        timeUnit: response?.payload[1],
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${NOTIFICATION_MESSAGE?.SOMETHING_WENT_WRONG}`,
          severity: "error",
        })
      );
    }
  };
  //GET PRESETS DATA
  const getPresetsData = async () => {
    try {
      setLoader(true);
      const res = await dispatch(getPresets()).unwrap();
      if (res) {
        setFilterData(res);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  //METHOD TO GET DATA FROM INPUT FIELDS
  const handleChange = (data, key) => {
    //CONDITION TO SHOW INPUT OF FROM AND TO DATE
    let selectedId = filters?.timeUnit?.Lookup?.filter((d) => d?.id === data);
    if (selectedId?.[0]?.value === "week") {
      setSelectedTimeUnit("week");
    } else if (["month", "day", "year"]?.includes(selectedId?.[0]?.value)) {
      setSelectedTimeUnit("");
    }
    if (key === "timeValue") {
      //CONDITION TO ACCEPT ONLY NEGATIVE VALUE IN TIMEVALUE
      const isNegative = Number(data) <= 0;
      setIsError((prevErrors) => ({
        ...prevErrors,
        isNegative: !isNegative,
      }));
      if (!isNegative) return;
    }
    setPreset((prevPreset) => ({
      ...prevPreset,
      [key]: data,
    }));
  };
  //METHOD TO CHANGE DATES
  const handleChangeDates = (data, key) => {
    setPreset((prevPreset) => ({
      ...prevPreset,
      [key]: data,
    }));
  };
  //METHOD TO UPDATE PRESET FROM TABLE
  const handleUpdatePreset = (data) => {
    //CONDITION TO SHOW TO SHOW INPUT FROM AND TO DATE
    data?.time_unit === "week"
      ? setSelectedTimeUnit(data?.time_unit)
      : setSelectedTimeUnit("");
    setPreset({
      ...preset,
      id: data?.id,
      name: data?.name,
      timeValue: String(data?.time_value),
      timeUnit: data?.timeUnit?.id,
      filtertype: data?.filterType?.id,
      weekStartDay: data?.weekStartDay,
      weekEndDay: data?.weekEndDay,
      is_default: data?.is_default,
    });
  };
  //METHOD TO SAVE & UPDATE PRESETS
  const handleSavePreset = async () => {
    const isValidForm = FilterValidation(preset, selectedTimeUnit);
    if (!isValidForm) {
      setIsError({
        ...isError,
        isRequired: true,
      });
    } else {
      setIsError({
        ...isError,
        isRequired: false,
      });
      try {
        setLoader(true);
        const CreatePayload = {
          name: preset?.name,
          filter_typeId: preset?.filtertype,
          time_unitId: preset?.timeUnit,
          time_value: Number(preset?.timeValue),
          weekStartDay: preset?.weekStartDay,
          weekEndDay: preset?.weekEndDay,
          is_default: preset?.is_default,
        };
        const UpdatePayload = {
          name: preset?.name,
          filter_typeId: preset?.filtertype,
          time_unitId: preset?.timeUnit,
          time_value: Number(preset?.timeValue),
          weekStartDay: preset?.weekStartDay,
          weekEndDay: preset?.weekEndDay,
          is_default: preset?.is_default,
        };
        let res;
        if (preset?.id) {
          res = await dispatch(
            UpdatePreset({ UpdatePayload, id: preset?.id })
          ).unwrap();
        } else {
          res = await dispatch(CreatePreset(CreatePayload)).unwrap();
        }
        if (res) {
          //CALLING FUNCTION TO AUTOREFRESH SCREEN
          getPresetsData();
          setLoader(false);
          dispatch(
            showSnackbar({
              show: true,
              message: `${
                preset?.id
                  ? NOTIFICATION_MESSAGE?.PRESET_UPDATED_SUCESS
                  : NOTIFICATION_MESSAGE.PRESET_CREATED_SUCESS
              }`,
              severity: "success",
            })
          );
          setPreset({
            ...preset,
            name: "",
            timeValue: null,
            timeUnit: null,
            filtertype: null,
            weekStartDay: null,
            weekEndDay: null,
            id: null,
            is_default: null,
          });
          setSelectedTimeUnit("");
        }
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: `${error?.message}`,
            severity: "error",
          })
        );
      }
    }
  };
  //USEeFFECT TO GET DATA ON PAGE LOADING.
  useEffect(() => {
    getReportData();
    getFilters();
    getPresetsData();
  }, []);
  return (
    <Grid container spacing={3}>
      {/* Loader Component */}
      {isLoader && <Loader />}
      {/*------------------------- Presets section ----------------------*/}
      <Grid item xs={7} mt={2}>
        <Grid container xs={12}>
          {/* LABEL  */}
          <Grid item xs={12}>
            <Typography className="serif_display_regular_22 green">
              Presets
            </Typography>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={3}>
              <TextField
                name="name"
                label="Name"
                className="inputTextField border_color"
                variant="standard"
                size="small"
                value={preset?.name}
                onChange={(event) => handleChange(event?.target?.value, "name")}
                disabled={!!preset?.id}
                error={isError?.isRequired && !preset?.name}
                helperText={
                  isError?.isRequired &&
                  !preset?.name &&
                  VALIDATION_MSG.REQUIRED
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel
                  shrink={true}
                  style={{
                    lineHeight: "2.0375",
                    marginLeft: "-12px",
                    color: "#01ce3f",
                  }}
                >
                  Filter Type
                </InputLabel>
                <Select
                  className="formControlField"
                  variant="standard"
                  size="small"
                  name="filtertype"
                  value={preset?.filtertype || ""}
                  onChange={(event) =>
                    handleChange(event?.target?.value, "filtertype")
                  }
                  fullWidth
                  disabled={!!preset?.id}
                >
                  {filters?.filtertype?.Lookup?.map((d) => (
                    <MenuItem
                      className="normal_normal_18_Manrope dark_grey"
                      value={d?.id}
                      key={d?.id}
                    >
                      {d?.label}
                    </MenuItem>
                  ))}
                </Select>
                {isError?.isRequired && !preset?.filtertype && (
                  <FormHelperText className="red">
                    {VALIDATION_MSG.REQUIRED}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel
                  shrink={true}
                  style={{
                    lineHeight: "2.0375",
                    marginLeft: "-12px",
                    color: "#01ce3f",
                  }}
                >
                  Time Unit
                </InputLabel>
                <Select
                  className="formControlField"
                  variant="standard"
                  value={preset?.timeUnit || ""}
                  label="Time Unit"
                  onChange={(event) =>
                    handleChange(event?.target?.value, "timeUnit")
                  }
                  disabled={!!preset?.id}
                >
                  {filters?.timeUnit?.Lookup?.map((d) => (
                    <MenuItem
                      className="normal_normal_18_Manrope dark_grey"
                      value={d?.id}
                      key={d?.id}
                    >
                      {d?.label}
                    </MenuItem>
                  ))}
                </Select>
                {isError?.isRequired && !preset?.timeUnit && (
                  <FormHelperText className="red">
                    {VALIDATION_MSG.REQUIRED}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="timeValue"
                label="Time Value"
                className="inputTextField border_color"
                variant="standard"
                size="small"
                type="number"
                value={preset?.timeValue || ""}
                onChange={(event) =>
                  handleChange(event?.target?.value, "timeValue")
                }
                disabled={!!preset?.id}
                error={
                  (isError?.isRequired && !preset?.timeValue) ||
                  isError?.isNegative
                }
                helperText={
                  (isError?.isRequired &&
                    !preset?.timeValue &&
                    VALIDATION_MSG.REQUIRED) ||
                  (isError?.isNegative && VALIDATION_MSG.TIME_VALUE)
                }
                required
                fullWidth
              />
            </Grid>
            {/* WHEN UNIT IS WEEK SHOW BELOW SECTION  */}
            <Grid
              xs={12}
              container
              mt={1}
              ml={0.5}
              spacing={2}
              style={{ display: "flex", alignItems: "center" }}
            >
              {selectedTimeUnit === "week" ? (
                <>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel
                        shrink={true}
                        style={{
                          lineHeight: "2.0375",
                          marginLeft: "-12px",
                          color: "#01ce3f",
                        }}
                      >
                        Start day
                      </InputLabel>
                      <Select
                        name="StartDay"
                        className="formControlField"
                        variant="standard"
                        size="small"
                        value={preset?.weekStartDay || ""}
                        onChange={(event) =>
                          handleChangeDates(
                            event?.target?.value,
                            "weekStartDay"
                          )
                        }
                        fullWidth
                      >
                        {WEEKDAYS?.map((d) => (
                          <MenuItem
                            className="normal_normal_18_Manrope dark_grey"
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        ))}
                      </Select>
                      {isError?.isRequired &&
                        !preset?.weekStartDay &&
                        selectedTimeUnit === "week" && (
                          <FormHelperText className="red">
                            {VALIDATION_MSG.REQUIRED}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>{" "}
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel
                        shrink={true}
                        style={{
                          lineHeight: "2.0375",
                          marginLeft: "-12px",
                          color: "#01ce3f",
                        }}
                      >
                        End day
                      </InputLabel>
                      <Select
                        name="EndDay"
                        className="formControlField"
                        variant="standard"
                        size="small"
                        value={preset?.weekEndDay || ""}
                        onChange={(event) =>
                          handleChangeDates(event?.target?.value, "weekEndDay")
                        }
                        fullWidth
                      >
                        {WEEKDAYS?.map((d) => (
                          <MenuItem
                            className="normal_normal_18_Manrope dark_grey"
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        ))}
                      </Select>
                      {isError?.isRequired &&
                        !preset?.weekEndDay &&
                        selectedTimeUnit === "week" && (
                          <FormHelperText className="red">
                            {VALIDATION_MSG.REQUIRED}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </>
              ) : null}
              {/* DEFAULT CHECKBOX SHOWING ONLY ON EDIT  */}
              {preset?.id && (
                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "#00CE3F",
                          "&.Mui-checked": {
                            color: "#00CE3F",
                          },
                        }}
                        checked={preset?.is_default}
                        onChange={(e) =>
                          handleChangeDates(e?.target?.checked, "is_default")
                        }
                      />
                    }
                    label="Default"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Save Button  */}
          <Grid
            item
            mt={2}
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
            border={0}
          >
            <Button className="save_button" onClick={() => handleSavePreset()}>
              {preset?.id ? "Update" : "Add"}
            </Button>
          </Grid>
          <Divider
            sx={{
              orientation: "horizontal",
              border: `1px solid #DCDCE1`,
              width: "100%",
              marginRight: "20px",
              marginLeft: "5px",
              marginBottom: "0.8rem",
              marginTop: "0.8rem",
            }}
          />
        </Grid>
        {/* Task Table */}
        <Grid
          item
          xs={12}
          style={{
            width: "100%",
            height: "70vh",
          }}
        >
          <DataGridComponent
            columns={columns}
            rows={filterData}
            onRowClick={() => {}}
          />
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem />
      {/*------------------------- Reports  section ----------------------*/}
      <Grid item xs={4.9} mt={2}>
        <Grid item xs={12}>
          <Typography className="serif_display_regular_22 green">
            Reports
          </Typography>
          <Grid
            xs={12}
            mt={2}
            ml={2}
            style={{
              maxHeight: "40rem",
              overflowY: "auto",
            }}
          >
            <Grid item xs={12} mt={4}>
              {reportParams?.map((parameter) => (
                <>
                  <Grid
                    item
                    display={"flex"}
                    justifyContent={"space-between"}
                    key={parameter?.id}
                  >
                    <Grid item xs={8}>
                      <Typography className="normal_normal_16_Manrope dark_grey">
                        {parameter?.lable}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Checkbox
                        sx={{
                          color: "#00CE3F",
                          "&.Mui-checked": {
                            color: "#00CE3F",
                          },
                        }}
                        checked={parameter?.isVisible}
                        onChange={(e) =>
                          ChangeReportParamStatus(
                            e?.target?.checked,
                            parameter?.id
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                  <Divider
                    sx={{
                      orientation: "horizontal",
                      border: `1px solid #DCDCE1`,
                      width: "100%",
                      marginBottom: "0.6rem",
                      // marginTop: "0.6rem",
                    }}
                  />
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Company;
