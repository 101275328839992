import { NumberValidation } from "../../../../utils/util";

// Form Validation for Plan
export const formValidation = (data) => {
  const instructionTypeId =
    data?.InstructionTypeProduct[0]?.instructionTypeId ||
    data?.instructionTypeId?.id;
  if (
    !data?.name ||
    !instructionTypeId ||
    !data?.description ||
    !data?.singleStandardFees ||
    !data?.jointStandardFees ||
    !data?.perVat ||
    data?.PlanProduct?.length <= 0 ||
    !NumberValidation(data?.singleStandardFees) ||
    !NumberValidation(data?.jointStandardFees) ||
    !NumberValidation(data?.perVat) ||
    (data?.costOfProbate && !NumberValidation(data?.costOfProbate)) ||
    (data?.lossOfCapacity?.forSingle &&
      !NumberValidation(data?.lossOfCapacity?.forSingle)) ||
    (data?.lossOfCapacity?.forJoint &&
      !NumberValidation(data?.lossOfCapacity?.forJoint))
  ) {
    return false;
  } else {
    return true;
  }
};
