import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { getInstructionType } from "../../../../thunks/instruction";
import { createTask, getTaskById, updateTask } from "../../../../thunks/task";
import { VALIDATION_MSG } from "../../../../utils/constant";
import { slaValidation } from "../../../../utils/util";

const AddNewTaskDialog = ({ open, handleClose, selectedTask, getTasks }) => {
  const dispatch = useDispatch();
  const [isLoader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const instructionTypes = useSelector(
    (state) => state?.instruction?.instructionTypeData
  );
  const lookups = useSelector((state) => state?.lookupData);
  let { Role: roles, slaUnit: slaUnit } = lookups || {};

  const [taskData, setTaskData] = useState({
    name: "",
    isGeneric: false,
    instructionTypeId: 1,
    actor: "",
    slaUnitId: null,
  });
  // Method to handle close dialog
  const handleCloseDialog = () => {
    setTaskData({});
    handleClose();
  };
  /* Method to get instruction type data */
  const getInstructionTypeData = async () => {
    try {
      const response = dispatch(getInstructionType()).unwrap();
    } catch (error) {}
  };

  // Method to change form fields
  const handleChangeFormFields = (value, key) => {
    setTaskData({
      ...taskData,
      [key]: value,
    });
  };
  // Method to create task
  const handleCreateTask = async () => {
    if (
      !taskData?.name ||
      !taskData?.sla ||
      !slaValidation(taskData?.sla) ||
      !taskData?.slaUnitId ||
      !taskData?.actor ||
      !taskData?.instructionTypeId
    ) {
      setIsError(true);
    } else {
      setIsError(false);
      const payload = {
        ...taskData,
        sla: Number(taskData?.sla),
        id: selectedTask,
      };
      try {
        setLoader(true);
        let response = null;
        if (selectedTask) {
          response = await dispatch(updateTask(payload)).unwrap();
        } else {
          response = await dispatch(createTask(payload)).unwrap();
        }
        if (response) {
          dispatch(
            showSnackbar({
              show: true,
              message: `${
                selectedTask
                  ? "Successfully updated task"
                  : "Successfully created task"
              }`,
              severity: "success",
            })
          );
          setLoader(false);
          handleCloseDialog();
          getTasks();
        }
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: `${error?.message}`,
            severity: "error",
          })
        );
        handleCloseDialog();
      }
    }
  };
  // Method to get task by id
  const getTaskDataByID = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getTaskById(selectedTask)).unwrap();
      if (response) {
        setTaskData({
          ...taskData,
          name: response?.name,
          isGeneric: response?.isGeneric,
          sla: response?.sla,
          slaUnitId: response?.slaUnitId,
          actor: response?.actor,
          instructionTypeId: response?.instructionTypeId,
        });
        setLoader(false);
      } else {
        setTaskData(null);
      }
    } catch (error) {
      setTaskData(null);
      setLoader(false);
      return error;
    }
  };
  // useEffect hook to get instruction type data
  useEffect(() => {
    getInstructionTypeData();
  }, []);

  useEffect(() => {
    if (selectedTask) {
      getTaskDataByID();
    }
  }, [selectedTask]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* Loader Component */}
        {isLoader && <Loader />}
        <DialogContent>
          <Grid container spacing={3}>
            {/* Create Task Label Section */}
            <Grid item xs={12}>
              <Typography
                className="serif_display_regular_26 green"
                textAlign={"center"}
              >
                {selectedTask ? "Update" : "Create"} Task
              </Typography>
            </Grid>

            {/* Task Name Field Section */}
            <Grid item xs={6}>
              <TextField
                className="inputTextField normal_normal_600_12_Manropee"
                label="Task Name"
                placeholder="Task Name"
                fullWidth
                required
                size="small"
                value={taskData?.name}
                onChange={(e) =>
                  handleChangeFormFields(e?.target?.value, "name")
                }
                error={isError && !taskData?.name}
                helperText={
                  isError && !taskData?.name && VALIDATION_MSG.REQUIRED
                }
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>

            {/* Task Type Field Section */}
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#00CE3F",
                      "&.Mui-checked": {
                        color: "#00CE3F",
                      },
                    }}
                    checked={taskData?.isGeneric}
                    onChange={(e) =>
                      handleChangeFormFields(e?.target?.checked, "isGeneric")
                    }
                    disabled={selectedTask}
                  />
                }
                label="Is Generic?"
              />
            </Grid>

            {/* SLA Field Section */}
            <Grid item xs={6}>
              <TextField
                className="inputTextField normal_normal_600_12_Manropee"
                label="SLA"
                placeholder="SLA"
                fullWidth
                required
                size="small"
                value={taskData?.sla}
                onChange={(e) =>
                  handleChangeFormFields(e?.target?.value, "sla")
                }
                error={
                  (isError && !taskData?.sla) ||
                  (isError && !slaValidation(taskData?.sla))
                }
                helperText={
                  (isError && !taskData?.sla && VALIDATION_MSG.REQUIRED) ||
                  (isError &&
                    !slaValidation(taskData?.sla) &&
                    VALIDATION_MSG.DIGIT_NUMBER)
                }
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>

            {/* SLA Unit Section */}
            <Grid item xs={6}>
              <FormControl
                fullWidth
                required
                error={isError && !taskData?.slaUnitId}
              >
                <InputLabel
                  shrink={true}
                  className="normal_normal_600_12_Manropee"
                  style={{
                    lineHeight: "2.0375",
                    marginLeft: "-12px",
                    top: "3px",
                  }}
                >
                  SLA Unit
                </InputLabel>
                <Select
                  className="formControlField"
                  value={taskData?.slaUnitId}
                  label="SLA Unit"
                  onChange={(e) =>
                    handleChangeFormFields(e?.target?.value, "slaUnitId")
                  }
                  variant="standard"
                  size="small"
                >
                  {slaUnit?.length > 0 &&
                    slaUnit?.map((d) => (
                      <MenuItem key={d?.id} value={d?.id}>
                        {d?.name}
                      </MenuItem>
                    ))}
                </Select>
                {isError && !taskData?.slaUnitId && (
                  <FormHelperText className="red">
                    {VALIDATION_MSG.REQUIRED}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* Actor Section */}
            <Grid item xs={6}>
              <FormControl
                fullWidth
                required
                error={isError && !taskData?.actor}
                disabled={selectedTask}
              >
                <InputLabel
                  shrink={true}
                  className="normal_normal_600_12_Manropee"
                  style={{
                    lineHeight: "2.0375",
                    marginLeft: "-12px",
                    top: "3px",
                  }}
                >
                  Actor
                </InputLabel>
                <Select
                  className="formControlField"
                  value={taskData?.actor}
                  label="Actor"
                  onChange={(e) =>
                    handleChangeFormFields(e?.target?.value, "actor")
                  }
                  variant="standard"
                  size="small"
                >
                  {roles?.length > 0 &&
                    roles?.map((d) => (
                      <MenuItem key={d?.id} value={d?.value}>
                        {d?.name}
                      </MenuItem>
                    ))}
                </Select>
                {isError && !taskData?.actor && (
                  <FormHelperText className="red">
                    {VALIDATION_MSG.REQUIRED}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* Instruction Type Section */}
            <Grid item xs={6}>
              <FormControl
                fullWidth
                required
                disabled={selectedTask}
                error={isError && !taskData?.instructionTypeId}
              >
                <InputLabel
                  shrink={true}
                  className="normal_normal_600_12_Manropee"
                  style={{
                    lineHeight: "2.0375",
                    marginLeft: "-12px",
                    top: "3px",
                  }}
                >
                  Instruction Type
                </InputLabel>
                <Select
                  className="formControlField"
                  value={taskData?.instructionTypeId}
                  label="Instruction Type"
                  onChange={(e) =>
                    handleChangeFormFields(
                      e?.target?.value,
                      "instructionTypeId"
                    )
                  }
                  variant="standard"
                  size="small"
                >
                  {instructionTypes?.length > 0 &&
                    instructionTypes?.map((d) => (
                      <MenuItem key={d?.id} value={d?.id}>
                        {d?.name}
                      </MenuItem>
                    ))}
                </Select>
                {isError && !taskData?.instructionTypeId && (
                  <FormHelperText className="red">
                    {VALIDATION_MSG.REQUIRED}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {/* Submit Button */}
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button onClick={handleCreateTask} className="save_button">
                {selectedTask ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddNewTaskDialog;
