import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import {
  getProductTaskAsPerInstructionType,
  updateProductTask,
} from "../../../../thunks/product";

const AddTask = ({
  open,
  handleClose = () => {},
  getProductByIdData = () => {},
  data,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [isLoader, setLoader] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [productData, setProductData] = useState(data || []);
  /* Method to get Tasks */
  const getTasksData = async (value) => {
    try {
      const response = await dispatch(
        getProductTaskAsPerInstructionType(value)
      ).unwrap();
      const filtered = response.filter((task) => {
        return !data.some((preTask) => task.id === preTask.id);
      });
      if (response?.length > 0) {
        setTasks(data ? filtered : response);
      } else {
        setTasks([]);
      }
    } catch (error) {}
  };

  /* Method to handleChange Task field */
  const handleChangeTasks = (value) => {
    //REMOVED ADDED TASK FROM DROPDOWN
    const removeAddedTask = tasks.filter((task) => task?.id !== value?.id);
    setTasks(removeAddedTask);
    const findDuplicate = productData?.find((data) => data?.id === value?.id);
    // If block to handle null and duplicate id's
    if (value === null || findDuplicate) {
      setSelectedTask(null);
      if (findDuplicate) {
        dispatch(
          showSnackbar({
            show: true,
            message: "Task already present",
            severity: "error",
          })
        );
      }
    } else {
      setSelectedTask(value);
      setProductData([...productData, value]);
    }
  };
  // Method to handle update task
  const handleUpdateTask = async () => {
    try {
      const taskIdArr = productData?.map((item) => ({ taskId: item?.id }));
      setLoader(true);
      const payload = { productId: Number(params?.id), taskIdArr: taskIdArr };
      const response = await dispatch(updateProductTask(payload)).unwrap();
      if (response) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: "Task Updated Successfully.",
            severity: "success",
          })
        );
        getProductByIdData();
      }
      handleClose();
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  /* Method to delete single task */
  const handleDeleteTask = (d) => {
    const filtered = productData?.filter((obj) => {
      return obj?.id !== d?.id;
    });
    setProductData(filtered);
    //ADD DELETED TASKS AGAIN IN DROPDOWN
    const AddRemovedTask = productData.filter((task) => task?.id === d?.id);
    setTasks([...tasks, AddRemovedTask[0]]);
  };

  useEffect(() => {
    getTasksData(1);
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers={true} sx={{ marginRight: "5px" }}>
          {isLoader && <Loader />}
          <Grid item xs={12} mt={5} mb={3}>
            <Autocomplete
              // multiple
              value={selectedTask || null}
              options={tasks || []}
              getOptionLabel={(option) => option?.name}
              onChange={(event, value) => handleChangeTasks(value)}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  className="normal_normal_600_12_Manropee"
                  size="small"
                  label="Tasks"
                  placeholder="Select Task"
                />
              )}
            />
          </Grid>
          {productData?.length > 0 ? (
            <>
              <Grid item xs={12} mb={3}>
                <Grid
                  style={{
                    border: "1px solid #dcdce1",
                    borderRadius: "6px",
                    padding: "3px",
                  }}
                >
                  {productData?.length > 0 &&
                    productData?.map((d, ind) => (
                      <Chip
                        style={{ margin: 6 }}
                        key={ind}
                        label={d?.name}
                        onDelete={() => handleDeleteTask(d)}
                      />
                    ))}
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Typography>No tasks added.</Typography>
            </Grid>
          )}

          <Grid
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "2rem 0 0 0",
              gap: "1rem",
            }}
          >
            <Button
              className="cancel_button"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button className="save_button" onClick={() => handleUpdateTask()}>
              Update
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AddTask;
