import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InfoIcon from "@mui/icons-material/Info";
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "chart.js/auto";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import {
  getDateRangeFilterLookup,
  getDetailReportStats,
  getInstructionMeetingStats,
} from "../../../thunks/dashboard";
import { getConsultant } from "../../../thunks/user";
import { DATE_FORMAT } from "../../../utils/constant";
import { getColorCodeForQc } from "../../../utils/util";

const Reports = () => {
  /* Configuration for Pie chart */
  const options = {
    pieHole: 0.4,
    is3D: false,
    colors: ["#67E28C", "#33cc80", "#59a680", "#B2F0C5", "#D9F8E2"],
    height: 230,
    legend: {
      // position: "bottom",
      // alignment: "start",
      textStyle: { fontSize: 14, textWrap: "wrap" },
    },
    fontName: "Manrope-Regular",
    chartArea: {
      width: "50%",
      left: 0,
      right: 30,
      bottom: 0,
      top: 0,
    },
    // title: "New Meeting",
  };

  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // states
  const [isLoader, setLoader] = useState(false);
  const [consultants, setConsultants] = useState([]);
  const [selectedConsultant, setSelectedConsultant] = useState({
    id: 10001,
    firstName: "All",
    lastName: "",
    role: "Consultant",
    isActive: true,
    email: "",
    phoneNumber: "",
    scheduling_url: null,
    calendlyUserUri: null,
  });
  const [isConsultant, setIsConsultant] = useState(false);
  const [dateFilter, setDateFilter] = useState([]);
  const [filterInput, setFilterInput] = useState({
    meetingFrom: null,
    meetingTo: null,
    detailReportFrom: null,
    detailReportTo: null,
    consultant: {
      id: 10001,
      firstName: "All",
      lastName: "",
      role: "Consultant",
      isActive: true,
      email: "",
      phoneNumber: "",
      scheduling_url: null,
      calendlyUserUri: null,
    },
  });
  const [selectedFilter, setSelectedFilter] = useState({});
  const [selectedFilterDetailReport, setSelectedFilterDetailReport] = useState(
    {}
  );
  const [instructionMeetingData, setInstructionMeetingData] = useState({});
  const [detailReportData, setDetailReportData] = useState({});
  const [chartData, setChartData] = useState([]);
  const [happeningMeetingChartData, setHappeningMeetingChartData] = useState(
    []
  );

  /* Method to Change consultant */
  const handleChangeConsultant = (value) => {
    if (value) {
      setSelectedConsultant(value);
    } else {
      setSelectedConsultant(null);
    }
  };

  /* Method to fetch all consultants */
  const getAllConsultants = async () => {
    try {
      const response = await dispatch(getConsultant()).unwrap();
      if (response?.length > 0) {
        const all_option = [
          {
            id: 10001,
            firstName: "All",
            lastName: "",
            role: "Consultant",
            isActive: true,
            email: "",
            phoneNumber: "",
            scheduling_url: null,
            calendlyUserUri: null,
          },
        ];
        // concat all option with response
        let tempResponse = [...all_option, ...response];
        setConsultants(tempResponse);
        setIsConsultant(true);
      } else {
        setConsultants([]);
        setIsConsultant(false);
      }
    } catch (error) {}
  };

  /* Method to HandleChange Filter Input*/
  const handleChangeFilter = (value, key) => {
    setFilterInput({
      ...filterInput,
      [key]: value,
    });
  };

  // Method to get date filter lookup data
  const getDateRangeFilterLookupData = async () => {
    try {
      let response = await dispatch(getDateRangeFilterLookup()).unwrap();
      if (response) {
        let temp = response?.map((item, index) => ({
          ...item,
          value: {
            startDate: new Date(item?.startDate),
            endDate: new Date(item?.endDate),
          },
          is_default: item?.is_default,
        }));
        setDateFilter([...temp]);

        let defaultDate = temp.find((d) => d?.is_default === true);
        if (defaultDate) {
          setFilterInput((prev) => ({
            ...prev,
            meetingFrom: new Date(defaultDate?.value?.startDate),
            meetingTo: new Date(defaultDate?.value?.endDate),
            detailReportFrom: new Date(defaultDate?.value?.startDate),
            detailReportTo: new Date(defaultDate?.value?.endDate),
          }));
          setSelectedFilter(defaultDate?.value);
          setSelectedFilterDetailReport(defaultDate?.value);
        }
      }
    } catch (error) {}
  };
  /* Method to get Instruction count + Meeting count + Pie Chart */
  const getFilterMeeting = async () => {
    try {
      setLoader(true);
      const payload = {
        startDate:
          filterInput?.meetingFrom !== null
            ? moment(filterInput?.meetingFrom)?.startOf("day")?.toISOString()
            : null,
        endDate:
          filterInput?.meetingTo !== null
            ? moment(filterInput?.meetingTo)?.endOf("day")?.toISOString()
            : null,
        userId: filterInput?.consultant?.id,
        isAllUser: filterInput?.consultant?.firstName === "All" ? true : false,
      };
      const response = await dispatch(
        getInstructionMeetingStats(payload)
      ).unwrap();

      if (response) {
        setInstructionMeetingData(response);
        /* Method to update charts labels and data of new and happening meetings */
        // new meeting data
        if (response?.eventTypeNameCount?.length > 0) {
          let titleArray = ["Meetings", "percentage"];
          let finalArray = response?.eventTypeNameCount?.map((d) => [
            d?.eventTypeName,
            d?._count,
          ]);
          let joinArray = [titleArray, ...finalArray];
          setChartData(joinArray);
        } else {
          setChartData([]);
        }

        // happening meeting data
        if (response?.happeningMeetingsNameCount?.length > 0) {
          let titleArray = ["Meetings", "percentage"];
          let finalArray = response?.happeningMeetingsNameCount?.map((d) => [
            d?.eventTypeName,
            d?._count,
          ]);
          let joinArray = [titleArray, ...finalArray];
          setHappeningMeetingChartData(joinArray);
        } else {
          setHappeningMeetingChartData([]);
        }
        setLoader(false);
      } else {
        setInstructionMeetingData({});
        setLoader(false);
      }
    } catch (error) {
      setInstructionMeetingData({});
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  /* Method to get Instruction count + Meeting count + Pie Chart */
  const getFilterReport = async () => {
    try {
      setLoader(true);
      let payload = {
        userId: selectedConsultant?.id || 2, // 2 is dummy number if there is no selected consultant
        startDate:
          filterInput?.detailReportFrom !== null
            ? moment(filterInput?.detailReportFrom)
                ?.startOf("day")
                ?.toISOString()
            : null,
        endDate:
          filterInput?.detailReportTo !== null
            ? moment(filterInput?.detailReportTo)?.startOf("day")?.toISOString()
            : null,
        isAllUser: selectedConsultant?.firstName === "All" ? true : false,
      };

      const response = await dispatch(getDetailReportStats(payload)).unwrap();
      if (response) {
        setDetailReportData(response);
        setLoader(false);
        setIsConsultant(false);
      } else {
        setDetailReportData({});
        setLoader(false);
        setIsConsultant(false);
      }
    } catch (error) {
      setInstructionMeetingData({});
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  // use effect
  useEffect(() => {
    if (isConsultant) {
      getFilterReport();
    }
  }, [isConsultant]);

  useEffect(() => {
    getDateRangeFilterLookupData();
    getFilterMeeting();
    getAllConsultants();
  }, []);

  return (
    <>
      {/* Loader component */}
      {isLoader && <Loader />}
      <Grid container spacing={3} mt={0}>
        <Grid
          item
          xs={8.5}
          style={{
            borderRight: `1px solid #DCDCE1`,
            height: "100vh",
          }}
        >
          <Grid container spacing={3}>
            <Grid container item xs={12} gap={3}>
              <Grid item xs={2.5} mt={1}>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "30px",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#00ce3f", // Always keep the label color green
                      // fontSize:"16px"
                    },
                    "& .Mui-focused .MuiInputLabel-root": {
                      color: "#00ce3f", // Ensure label color stays green when focused
                      // fontSize:"16px"
                    },
                    // "& .Mui-not-focused .MuiInputLabel-root": {
                    //   color: "#00ce3f", // Ensure label color stays green when focused
                    // },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#00ce3f", // Ensure label color stays green when focused
                    },
                    "& .MuiInputLabel-root.MuiFormLabel-filled": {
                      color: "#00ce3f", // Ensure label color stays green when the input is filled
                    },
                  }}
                >
                  <InputLabel
                    className="normal_normal_600_12_Manropee"
                    // variant="standard"
                    sx={{ color: "#00ce3f" }}
                  >
                    Filter
                  </InputLabel>
                  <Select
                    className="textField_outlined_border"
                    label="Filter"
                    value={selectedFilter}
                    onChange={(e) => {
                      setFilterInput((prev) => ({
                        ...prev,
                        meetingFrom: new Date(e.target.value.startDate),
                        meetingTo: new Date(e.target.value.endDate),
                      }));
                      setSelectedFilter(e.target.value);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#00ce3f", // Border color based on selection
                        color: "#00ce3f",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#00ce3f",
                        color: "#00ce3f",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#00ce3f",
                        color: "#00ce3f",
                      },
                    }}
                  >
                    {dateFilter?.length > 0 &&
                      dateFilter?.map((d) => (
                        <MenuItem
                          key={d?.id}
                          value={d?.value}
                          className="normal_normal_medium_14_Manrope dark_grey"
                        >
                          {d?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Meeting from date Section */}
              <Grid item xs={2.5}>
                <FormControl fullWidth variant="standard">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format={DATE_FORMAT}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "standard",
                          InputLabelProps: { shrink: true },
                          className: "inputTextField",
                        },
                      }}
                      value={filterInput?.meetingFrom}
                      label="From"
                      onChange={(e) => handleChangeFilter(e, "meetingFrom")}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                      className="normal_normal_600_12_Manropee outlined_border"
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              {/* Meeting to date Section  */}
              <Grid item xs={2.5}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format={DATE_FORMAT}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "standard",
                          InputLabelProps: { shrink: true },
                          className: "inputTextField",
                        },
                      }}
                      value={filterInput?.meetingTo}
                      label="To"
                      onChange={(e) => handleChangeFilter(e, "meetingTo")}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                      className="normal_normal_600_12_Manropee outlined_border"
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              {/* Autocomplete for consultant list */}
              <Grid item xs={2.5} mt={1}>
                <Autocomplete
                  fullWidth
                  value={filterInput?.consultant || null}
                  options={consultants || []}
                  getOptionLabel={(option) =>
                    `${option?.firstName || ""} ${option?.lastName || ""}`
                  }
                  onChange={(event, value) =>
                    handleChangeFilter(value, "consultant")
                  }
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...params}
                      className="normal_normal_600_12_Manropee inputTextField border_color"
                      size="small"
                      label="Consultant"
                      placeholder="Select consultant"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: 24,
                          borderRadius: "24px",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            {/* Filter button for get instruction & meeting chat PI */}
            <Grid
              item
              mr={2}
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Tooltip title="click to filter meetings">
                <Button className="save_button" onClick={getFilterMeeting}>
                  Filter
                </Button>
              </Tooltip>
            </Grid>
            {/* New Instruction label + count section */}
            <Grid
              item
              xs={12}
              ml={1}
              pb={2}
              display={"flex"}
              alignItems={"center"}
              gap={2}
              style={{
                height: "10vh",
              }}
            >
              <Typography
                textAlign={"start"}
                className="normal_normal_18_Manrope dark_grey"
              >
                New Instructions
              </Typography>
              <Typography
                textAlign={"start"}
                className="normal_normal_34_Manrope green"
              >
                {instructionMeetingData?.newInstructionCount || 0}
              </Typography>
            </Grid>

            {/* Chart sections */}
            <Grid
              xs={12}
              display={"flex"}
              alignItems={"flex-start"}
              height={"67vh"}
            >
              <Grid xs={6} ml={4} pr={0} pt={2} height={"100%"}>
                {/* New Meeting label + count section */}
                <Grid display={"flex"} alignItems={"center"} gap={2}>
                  <Grid display={"flex"} alignItems={"center"}>
                    <Typography className="normal_normal_18_Manrope dark_grey">
                      New Meetings
                    </Typography>
                    {filterInput.meetingFrom == null &&
                      filterInput.meetingTo == null && (
                        <Grid>
                          <Tooltip title="( This week's meetings )">
                            <IconButton>
                              <InfoIcon
                                className="green"
                                style={{ marginTop: "0.3rem" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                  </Grid>
                  <Typography
                    textAlign={"start"}
                    className="normal_normal_34_Manrope green"
                  >
                    {instructionMeetingData?.meetingCount || 0}
                  </Typography>
                </Grid>

                {/* Pie chart section */}
                <Grid height={"100%"} mt={2}>
                  {chartData?.length > 0 ? (
                    <Chart
                      chartType="PieChart"
                      data={chartData || []}
                      options={options}
                    />
                  ) : (
                    <Grid
                      height={"150px"}
                      width={"100%"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      No new meetings
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid xs={6} pt={2} height={"100%"}>
                {/* New Meeting label + count section */}
                <Grid ml={0} display={"flex"} alignItems={"center"} gap={3}>
                  <Grid display={"flex"} alignItems={"center"} ml={2}>
                    <Typography className="normal_normal_18_Manrope dark_grey">
                      Happening Meetings
                    </Typography>
                    {filterInput.meetingFrom == null &&
                      filterInput.meetingTo == null && (
                        <Grid>
                          <Tooltip title="( This week's meetings )">
                            <IconButton>
                              <InfoIcon
                                className="green"
                                style={{ marginTop: "0.3rem" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                  </Grid>
                  <Typography
                    textAlign={"start"}
                    className="normal_normal_34_Manrope green"
                  >
                    {instructionMeetingData?.happeningMeetingsCount || 0}
                  </Typography>
                </Grid>

                {/* Pie chart section */}
                <Grid p={0} height={"100%"} mt={2}>
                  {happeningMeetingChartData?.length > 0 ? (
                    <Chart
                      chartType="PieChart"
                      data={happeningMeetingChartData || []}
                      options={options}
                    />
                  ) : (
                    <Grid
                      height={"150px"}
                      width={"100%"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      No meetings happening
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={3.5}
          sx={{ height: "100vh", paddingBottom: "30px", overflowY: "scroll" }}
        >
          <Grid container spacing={2}>
            {/* Detail report section */}
            <Grid item xs={6} mb={1}>
              <Typography className="serif_display_regular_22 dark_grey">
                Detailed Report
              </Typography>
            </Grid>

            {/* Setup meeting section */}
            <Grid item xs={6} display={"flex"} justifyContent={"center"}>
              <Button
                className="setUpNewMeeting normal_normal_600_12_Manrope"
                onClick={() => {
                  navigate("/app/setupNewMeeting");
                }}
              >
                New Meeting
              </Button>
            </Grid>

            <Grid item xs={8}>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#00ce3f", // Always keep the label color green
                  },
                  "& .Mui-focused .MuiInputLabel-root": {
                    color: "#00ce3f", // Ensure label color stays green when focused
                  },
                  // "& .Mui-not-focused .MuiInputLabel-root": {
                  //   color: "#00ce3f", // Ensure label color stays green when focused
                  // },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#00ce3f", // Ensure label color stays green when focused
                  },
                  "& .MuiInputLabel-root.MuiFormLabel-filled": {
                    color: "#00ce3f", // Ensure label color stays green when the input is filled
                  },
                }}
              >
                <InputLabel
                  className="normal_normal_600_12_Manropee"
                  sx={{ color: "#00ce3f" }}
                >
                  Filter
                </InputLabel>
                <Select
                  className="textField_outlined_border"
                  label="Filter"
                  value={selectedFilterDetailReport}
                  onChange={(e) => {
                    setFilterInput((prev) => ({
                      ...prev,
                      detailReportFrom: new Date(e.target.value.startDate),
                      detailReportTo: new Date(e.target.value.endDate),
                    }));
                    setSelectedFilterDetailReport(e.target.value);
                  }}
                >
                  {dateFilter?.length > 0 &&
                    dateFilter?.map((d) => (
                      <MenuItem
                        key={d?.id}
                        value={d?.value}
                        className="normal_normal_medium_14_Manrope dark_grey"
                      >
                        {d?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}></Grid>

            {/* Detail report from date Section */}
            <Grid item xs={5}>
              <FormControl fullWidth variant="standard">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format={DATE_FORMAT}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "standard",
                        InputLabelProps: { shrink: true },
                        className: "inputTextField",
                      },
                    }}
                    value={filterInput?.detailReportFrom}
                    label="From"
                    onChange={(e) => handleChangeFilter(e, "detailReportFrom")}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    className="normal_normal_600_12_Manropee outlined_border"
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            {/* Detail report to date Section */}
            <Grid item xs={5} ml={3} maxWidth={"100%"}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format={DATE_FORMAT}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "standard",
                        InputLabelProps: { shrink: true },
                        className: "inputTextField",
                      },
                    }}
                    value={filterInput?.detailReportTo}
                    label="To"
                    onChange={(e) => handleChangeFilter(e, "detailReportTo")}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    className="normal_normal_600_12_Manropee outlined_border"
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            {/* Autocomplete for consultant list */}
            <Grid
              item
              xs={6}
              mt={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Autocomplete
                fullWidth
                value={selectedConsultant || null}
                options={consultants || []}
                getOptionLabel={(option) =>
                  `${option?.firstName || ""} ${option?.lastName || ""}`
                }
                onChange={(event, value) => handleChangeConsultant(value)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    className="normal_normal_600_12_Manropee inputTextField border_color"
                    size="small"
                    label="Consultant"
                    placeholder="Select consultant"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: 24,
                        borderRadius: "24px",
                      },
                    }}
                  />
                )}
              />
            </Grid>

            {/* Filter button for detail report */}
            <Grid item xs={2} mt={2}>
              {/* Filter button for get instruction & meeting chat PI */}
              <Tooltip title="click to filter detail report">
                <Button className="save_button" onClick={getFilterReport}>
                  Filter
                </Button>
              </Tooltip>
            </Grid>

            {/* Report detail section */}
            <Grid xs={12} mt={4} border={0} px={2}>
              <Grid mt={2}>
                {Object.keys(detailReportData)?.length
                  ? Object.entries(detailReportData).map(([key, value]) => (
                      <>
                        <Grid
                          display={"flex"}
                          justifyContent={"space-between"}
                          key={key}
                        >
                          {key === "Conversion Rate" ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography className="normal_normal_16_Manrope dark_grey">
                                  {key}
                                </Typography>
                                <Typography
                                  className="normal_normal_16_Manrope dark_grey"
                                  style={{
                                    marginLeft: "1rem",
                                    fontStyle: "italic",
                                  }}
                                >
                                  Instructed
                                </Typography>
                                <Typography
                                  className="normal_normal_16_Manrope dark_grey"
                                  style={{
                                    marginLeft: "1rem",
                                    fontStyle: "italic",
                                  }}
                                >
                                  Meetings
                                </Typography>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography className="normal_normal_16_Manrope">
                                  {key === "Conversion Rate"
                                    ? detailReportData[key].value
                                    : value}
                                </Typography>

                                <Typography className="normal_normal_16_Manrope">
                                  {key === "Conversion Rate"
                                    ? detailReportData[
                                        key
                                      ].Instructions?.replace("%", "").trim()
                                    : value}
                                </Typography>

                                <Typography className="normal_normal_16_Manrope">
                                  {key === "Conversion Rate"
                                    ? detailReportData[key].Meetings?.replace(
                                        "%",
                                        ""
                                      ).trim()
                                    : value}
                                </Typography>
                              </div>
                            </>
                          ) : (
                            <>
                              <Typography className="normal_normal_16_Manrope dark_grey">
                                {key}
                              </Typography>

                              <Typography
                                className="normal_normal_16_Manrope"
                                style={{
                                  color:
                                    key === "QC Accuracy"
                                      ? getColorCodeForQc(value)
                                      : "",
                                }}
                              >
                                {value}
                              </Typography>
                            </>
                          )}
                        </Grid>
                        <Divider
                          sx={{
                            orientation: "horizontal",
                            border: `1px solid #DCDCE1`,
                            width: "100%",
                            marginBottom: "0.6rem",
                            marginTop: "0.6rem",
                          }}
                        />
                      </>
                    ))
                  : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Reports;
