import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import Tree from "react-d3-tree";
import Graph from "react-graph-vis";
import { useSelector } from "react-redux";

const PreviewTaskConfig = ({ open, handleClose }) => {
  const taskTriggers = useSelector((state) => state?.task?.taskData);
  const [previewData, setPreviewData] = useState(null);

  const options = {
    layout: {
      hierarchical: false, // Disable hierarchical layout
      // direction: "LR", // "UD" for top-to-bottom, "LR" for left-to-right
      randomSeed: 2,
      improvedLayout: true,
      clusterThreshold: 150,
    },
    physics: {
      enabled: false, // Disable physics to maintain fixed positions
    },
    edges: {
      color: "green",
      smooth: false, // Enable smooth edges
    },
    nodes: {
      shape: "box", // Ensure nodes are boxes for clarity
    },
    height: "500px",
    width: "100%",
    autoResize: true,
  };

  const events = {
    select: function (event) {
      var { nodes, edges } = event;
    },
  };

  const getPreviewData = () => {
    const nodes = [];
    const edges = [];
    taskTriggers?.forEach((item, index) => {
      // Add the node
      nodes?.push({
        id: item.Task.id,
        // label: item.Task.id?.toString(),
        label: item.Task?.name,
        level: item?.Task?.id,
        taskTriggerId: item?.taskTriggerTypeId,
        color: {
          background: item?.taskTriggerTypeId === 608 ? "#FFA200" : "#00ce3f",
          border: "#00ce3f",
        },
      });

      // Add the edges
      item?.sucessorTaskIds?.forEach((successor) => {
        edges?.push({
          from: item?.Task?.id,
          to: successor?.id,
        });
      });
    });
    const obj = {};
    obj.nodes = nodes;
    obj.edges = edges;
    setPreviewData(obj);
    return { nodes, edges };
  };
  useEffect(() => {
    getPreviewData();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"lg"}
        PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
      >
        <DialogTitle
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography
            className="serif_display_regular_26 green"
            textAlign={"center"}
          >
            Preview Task Configuration
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Graph
                graph={previewData || {}}
                options={options}
                events={events}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PreviewTaskConfig;
