import AddressBookPage from "../pages/Instructions/components/Forms/AddressBookPage/AddressBookPage";
import AssetForm from "../pages/Instructions/components/Forms/FactFindForm/AssetForm";
import BeneficiariesForm from "../pages/Instructions/components/Forms/FactFindForm/BeneficiariesForm";
import BusinessAssetForm from "../pages/Instructions/components/Forms/FactFindForm/BusinessAssetForm";
import ClientDetailsForm from "../pages/Instructions/components/Forms/FactFindForm/ClientDetailsForm";
import DependentForm from "../pages/Instructions/components/Forms/FactFindForm/DependentForm";
import ExclusionsForm from "../pages/Instructions/components/Forms/FactFindForm/ExclusionsForm";
import GiftForm from "../pages/Instructions/components/Forms/FactFindForm/GiftForm";
import LiabilitiesForm from "../pages/Instructions/components/Forms/FactFindForm/LiabilitiesForm";
import LifeInsurenceForm from "../pages/Instructions/components/Forms/FactFindForm/LifeInsurenceForm";
import PensionForm from "../pages/Instructions/components/Forms/FactFindForm/Pension";
import PropertiesForm from "../pages/Instructions/components/Forms/FactFindForm/PropertiesForm";
import SavingForm from "../pages/Instructions/components/Forms/FactFindForm/SavingForm";
import SharesAndInvestmentForm from "../pages/Instructions/components/Forms/FactFindForm/SharesAndInvestmentForm";
import AttorneysItem from "../pages/Instructions/components/Forms/LPAQuestionnaireForm/Attorneys";
import CertificateProvideItem from "../pages/Instructions/components/Forms/LPAQuestionnaireForm/CertificateProviderDetails";
import PeopleToBeToldItem from "../pages/Instructions/components/Forms/LPAQuestionnaireForm/PeopleToBeTold";
import ReplacementAttorneysItem from "../pages/Instructions/components/Forms/LPAQuestionnaireForm/ReplacementAttorneys";
import LivingWillQuestionnaireForm from "../pages/Instructions/components/Forms/LivingWillQuestionnaire";
import GuidanceAssetsBeingTransferred from "../pages/Instructions/components/Forms/TrustQuestionnaire/AssetsBeingTransferred";
import GuidanceToTrustees from "../pages/Instructions/components/Forms/TrustQuestionnaire/GuidanceToTrustees";
import Trustees from "../pages/Instructions/components/Forms/TrustQuestionnaire/Trustees";
import AdditionalNote from "../pages/Instructions/components/Forms/WillQuestionnaireForm/AdditionalNote";
import BusinessInterests from "../pages/Instructions/components/Forms/WillQuestionnaireForm/BusinessInterests";
import ChattelsAndGifts from "../pages/Instructions/components/Forms/WillQuestionnaireForm/ChattelsAndGifts";
import Exclusions from "../pages/Instructions/components/Forms/WillQuestionnaireForm/Exclusions";
import ExecutorAndTrustee from "../pages/Instructions/components/Forms/WillQuestionnaireForm/ExecutorAndTrustee";
import FuneralWishes from "../pages/Instructions/components/Forms/WillQuestionnaireForm/FuneralWishes";
import Guardians from "../pages/Instructions/components/Forms/WillQuestionnaireForm/Guardians";
import MonetaryLegacies from "../pages/Instructions/components/Forms/WillQuestionnaireForm/MonetaryLegacies";
import ProfessionalExecutor from "../pages/Instructions/components/Forms/WillQuestionnaireForm/ProfessionalExecutor";
import ResidueOfEstate from "../pages/Instructions/components/Forms/WillQuestionnaireForm/ResidueOfEstate";
import TestatorItem from "../pages/Instructions/components/Forms/WillQuestionnaireForm/Testators";
import WillTrusts from "../pages/Instructions/components/Forms/WillQuestionnaireForm/WillTrusts";

// Allowing only 30 MB file size
export const MAX_FILE_SIZE = 30 * 1024 * 1024;

//pagination count
export const PAGINATION_LIMIT = 50;

// Allowing only 20 MB file size for email attatchment
export const MAX_EMAIL_FILE_SIZE = 19.5 * 1024 * 1024;

export const API_URL = process.env.REACT_APP_API_PATH;

// Sendgrid inbound URL to parse email
export const INBOUND_PARSE_URL = process.env.REACT_APP_INBOUND_SENDGRID;

export const IMG_BASE_URL = `/file/read-image?filename=`;

export const DOWNLOAD_IMG_BASE_URL = `/file/download-image?filename=`;

export const DATE_FORMAT = "dd/M/yyyy";

export const GMT_DATE_FORMAT = "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx (zzzz)";

export const OFFICE_ADMIN_MAIL_ID = "hello@squiggleconsult.co.uk";

export const VAT_NUMBER_START = "GB";

export const OFFICE_ADMIN_PHONE_NUMBER = "01233 659796";

const INSTRUCTION_ROOT_ROUTE = "/instruction";

export const SUPPORT_TICKET_BASE_URL =
  "https://iconnectinfosolutions.freshservice.com/api/v2";

// Application User Roles
export const USER_ROLE = {
  CONSULTANT: "Consultant",
  OFFICE_ADMIN: "OfficeAdmin",
  SUPER_ADMIN: "SuperAdmin",
  INTRODUCER: "Introducer",
  LEGAL_PARTNER: "LegalPartner",
};

// All API Routes
export const API_ROUTES_PATHS = {
  USERS_LIST: "/user/allusers",
  GET_PRODUCTS: "/product",
  LOOKUP_ROOT: "/lookupType",
  CREATE_PLAN: "/plan",
  GET_PLANS: "/plan",
  EMAIL_TEMPLATES: "/email_templates",
  GET_PLANS_WHAT_IFS: "/whatif",
  GET_INTRODUCERS: "/user/introducers",
  GET_CONSULTANT: "/user/counsultant",
  CREATE_USER: "/user",
  UPDATE_USER: "/user",
  LOGIN: "/auth/external/signin",
  GET_USER_ME: "/user/me",
  GET_USER_SHOW_ME: "/user/showme",
  EDIT_ME: "/user/editMe",
  GOOGLE_LOGIN: "/auth/google/login",
  GET_INSTRUCTION_TYPE: "/instructiontype",
  GET_PRODUCT_QUES_TASK_DISBUS_BY_INSTRUCTION_TYPE: "/instructiontype",
  CREATE_PRODUCT: "/product",
  VERIFY_EMAIL: "/auth/forgot/password",
  VERIFY_OTP: "/auth/verify/otp",
  RESET_PASSWORD: "/auth/reset/password",
  GET_PRODUCT_BY_ID: "/product",
  UPDATE_PRODUCT_TASK: "/product",
  GET_ALL_TASKS: "/tasksettings",
  GET_ALL_PROD_QUESTIONS: "/questionnaire",
  GET_ALL_INSTRUCTIONS: INSTRUCTION_ROOT_ROUTE,
  UPDATE_PRODUCT: "/product",
  UPDATE_PLAN: "/plan",
  UPDATE_INSTRUCTION: INSTRUCTION_ROOT_ROUTE,
  INSTRUCTION_FIELD_HISTORY: "instructionFieldHistory",
  GET_CLIENT_BY_ID: "/leadclient",
  CREATE_CLIENT: "/leadclient",
  UPDATE_CLIENT: "/leadclient",
  GET_INSTRUCTION_BY_ID: INSTRUCTION_ROOT_ROUTE,
  GET_CLIENT_CARE_LETTER: "clientCare",
  GET_ALL_LEAD_CLIENT: "/leadclient/getAllLeadClient",
  GET_MILESTONE: "/milestone",
  IMPORT_CLIENT: "/leadclient/leadexcelupload",
  XERO_LOGIN: "/xero/signin",
  XERO_PAY: INSTRUCTION_ROOT_ROUTE,
  UPDATE_WILL_SUIT: "willSuite",
  UPLOAD_FILE: "/file/upload",
  CREATE_INSTRUCTION_NOTES: INSTRUCTION_ROOT_ROUTE,
  GET_NOTES_AND_DOCUMENT_BY_ID: INSTRUCTION_ROOT_ROUTE,
  GET_INSTRUCTION_PRODUCT_BY_ID: INSTRUCTION_ROOT_ROUTE,
  GET_CLIENTS_FOR_MEETING: "/LeadClient/getLeadClients",
  CREATE_MEETING: "/LeadClient/leadClientMeeting",
  GET_INSTRUCTION_PRODUCT_TASK_BY_ID: "/instructiontask",
  GET_GENERIC_TASK_FOR_INSTRUCTION: "/instructiontask",
  GET_INSTRUCTION_TASK_ANSWER_DETAILS: INSTRUCTION_ROOT_ROUTE,
  UPDATE_INSTRUCTION_TASK_ANSWER: "/instructiontask",
  GET_INSTRUCTION_TASK_HISTORY: "/instructiontask",
  GET_INSTRUCTION_DOCUMENTS_AS_PER_DOCUMENT_TYPE: "/instruction",
  GET_MY_USERS: "/user/myusers",
  GET_ALL_MEETINGS: "/leadclient",
  ALLOCATE_OR_COMPLETE_TASK: "/instructiontask",
  // ALLOCATE_OR_COMPLETE_TASK: "/V3/instructiontaskNewest",
  SEND_EMAIL: INSTRUCTION_ROOT_ROUTE,
  GET_EMAILS: "/instruction/get_emails",
  GET_MEETING_LOGS: "/instruction",
  GET_INSTRUCTION_COMMISSIONS: "/instruction-commissions",
  UPDATE_COMMISSION: INSTRUCTION_ROOT_ROUTE,
  GET_QC_CHECKLIST_BY_ID: INSTRUCTION_ROOT_ROUTE,
  CREATE_QC_CHECKLIST_QUESTIONS: INSTRUCTION_ROOT_ROUTE,
  UPDATE_QC_CHECKLIST: INSTRUCTION_ROOT_ROUTE,
  EMAIL_AI_PATH: "https://api.openai.com/v1/engines/davinci/completions",
  GET_CONSULTANT_INTRODUCER_COMMISSION_LIST: INSTRUCTION_ROOT_ROUTE,
  GENERATE_CALENDLY_USER_ID: "/user/updateMyCalendlyInfo",
  GET_DISBURSEMENT_PRICE: "/disbursementpricing",
  UPDATE_DISBURSEMENT_PRICE: "/disbursementfeeslookup",
  UPDATE_INTRODUCTER_CONSULTANT_COMMISSION_STATUS: "/instruction-commissions",
  GET_MEETING_INSTRUCTION_STATS: "/user/dashboard_stats",
  GET_DETAIL_REPORT: "/user/dashboard_detail",
  GET_THINGS_TO_DO: "/user/mydashboard",
  GET_INTRODUCER_STATS: "/user/mydashboard",
  TRANSFER_INSTRUCTION: INSTRUCTION_ROOT_ROUTE,
  GET_USER_FOR_ASSIGN_TASK: INSTRUCTION_ROOT_ROUTE,
  CREATE_NEW_TASK: "/instructionTask",
  GET_INDIVIDUAL_TASKS: "/instructiontask",
  GET_CUSTOM_TASKS: "/instructiontask",
  UPDATE_INDIVIDUAL_INSTRUCTION_TASK_ANSWER: "/instructiontask",
  ALLOCATE_OR_COMPLETE_INDIVIDUAL_TASK: "/instructiontask",
  DELETE_CLIENT: "/leadclient",
  IS_VALID_INSTRUCTION_TO_RAISE_BILL: INSTRUCTION_ROOT_ROUTE,
  GET_CUSTOM_INSTRUCTION_TASK_ANSWER_HISTORY:
    "instructiontask/instructionCustomTask",
  GET_CURRENT_WEEK_INSTRUCTION: "/user/currentWeeksInstructions",
  CREATE_NOTE: "/leadclient",
  GET_ALL_NOTES: "/user",
  GET_NOTES_BY_CLIENT_ID: "/leadclient",
  GET_THINGS_TO_PICK: "/user/myPickableTasks",
  GET_XERO_OPTION_SET: "/xero/Xero-OptionSet",
  GET_XERO_SUSPENSE_USER: "/xero/accountPayements",
  READ_IMAGE: "/file/read-image2",
  CREATE_SUPPORT_TICKET: `${SUPPORT_TICKET_BASE_URL}/service_catalog/items/56/place_request`,
  UPDATE_SUPPORT_TICKET: `${SUPPORT_TICKET_BASE_URL}`,
  GET_SUPPORT_TICKET_LOOKUP: `${SUPPORT_TICKET_BASE_URL}/service_catalog/items/56`,
  GET_SUPPORT_TICKETS: `${SUPPORT_TICKET_BASE_URL}/tickets/filter?query=%22group_id:27000948374`,
  CREATE_TICKET_COMMENT: `${SUPPORT_TICKET_BASE_URL}/tickets`,
  // End point for task customization
  CREATE_TASK: "/tasksettings",
  GET_MASTER_TASK: "/tasksettings",
  GET_TASK_BY_ID: "/tasksettings",
  GET_TASK_TRIGGERS: "/task-triggers",
  UPDATE_TASK_CONFIG: "/task-triggers",
  UPDATE_DUE_DATE: "instructiontask",
  GET_DUE_DATE_HISTORY: "instructiontask",
  UPDATE_INDIVIDUAL_DUE_DATE: "instructiontask",
  GET_INDIVIDUAL_DUE_DATE_HISTORY: "instructiontask/instructionCustomTask",
  GET_ADDITIONAL_DOCUMENTS: INSTRUCTION_ROOT_ROUTE,
  INSTRUCTION_ROUTE: "/instruction",
  MAKE_PDF_ROUTE: "/makepdf",
  ADDRESS_BOOK_DOCUMENT_PDF: "addressBookCertification",
  CERTIFICATE_TYPE: "certTypeName",
  CERTIFICATE_ID: "certId",
  SIGNATURE_ID: "signatureId",
  CREATE_SHARED_EMAIL: "/sharedemail",
  GET_SHARED_EMAIL: "/sharedemail",
  GET_DOCUMENT_DETAILS: "/file/informationOfFiles",
  UPDATE_DOC: "instructiondoc",
  TRANSFER_EMAIL: INSTRUCTION_ROOT_ROUTE,
  MEETING_INVITEE_SHOW: 'invitee_no_shows',
  MEETING_INVITEE_NO_SHOW_DELETE: 'invitee_no_shows_delete',
  CALENDLY:'/calendly',
  FILTER:'/filter',
  REPORT_PARAMS: "reportparams",
  PRESETS: "filter",
};

// API response codes
export const API_RESPONSE_CODES = {
  SUCCESS: 200,
  SUCCESS_CREATE: 201,
  SUCCESS_NO_CONTENT: 204,
  UNAUTHORISED: 401,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
};

// Regex
export const REGEX = {
  EMAIL_REGEX:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE_NUMBER_REGEX: /^\d{11}$/,
  UPLOAD_DOCUMENT: /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx)$/i,
  NUMBER_REGEX: /^[0-9]+(?:\.[0-9]+)?$/,
  VAT_NUMBER_REGEX: /^\d{9}$/,
  SLA_REGEX: /^\d+$/,
};

// Notification messages
export const NOTIFICATION_MESSAGE = {
  SUCCESS_CLIENT_CREATE: "Client created successfully.",
  SUCCESS_CLIENT_UPDATE: "Client updated successfully.",
  DATA_NOT_AVAILABLE: "Data not available.",
  DATA_SAVE_SUCCESS: "Data saved successfully.",
  RESOLVED_ERROR_OR_REQUIRED: "Please resolve errors or fill mandatory fields.",
  SUCCESS_CLIENT_IMPORT: "File uploaded and processed successfully.",
  ERROR_CLIENT_IMPORT: "Error processing file.",
  MEETING_CREATE_SUCCESS: "Meeting created successfully.",
  OTP_SUCCESS: "OTP sent successfully to email.",
  NOTE_CREATE_SUCCESS: "Note created successfully.",
  SOMETHING_WENT_WRONG: "Something went wrong.",
  INSTRUCTION_UPDATE_SUCCESS: "Instruction updated successfully.",
  ASSIGN_TASK_SUCCESS: "Task assigned successfully.",
  COMPLETE_TASK_SUCCESS: "Task completed successfully.",
  DROP_TASK_SUCCESS: "Task droped successfully.",
  LINK_COPY_SUCCESS: "Link copied to clipboard.",
  RESOLVED_ERROR: "Please resolve errors and save data.",
  UNSAVE_CHANGES: "There are unsaved changes, please save changes first.",
  UPDATE_QC_DATA_SUCCESS: "Data updated successfully.",
  QC_CHECKLIST_CREATE_SUCCESS: "Checklist created successfully.",
  QC_CHECKLIST_UPDATE_SUCCESS: "Checklist updated successfully.",
  TASK_ANSWER_UPDATE_SUCCESS: "Task answer updated successfully.",
  PLAN_CREATE_SUCCESS: "Plan created successfully.",
  PLAN_UPDATE_SUCCESS: "Plan updated successfully.",
  PLAN_ACTIVATE_SUCCESS: "Plan activated successfully.",
  PLAN_DEACTIVATE_SUCCESS: "Plan deactivated successfully.",
  PRODUCT_CREATE_SUCCESS: "Product created successfully.",
  PRODUCT_UPDATE_SUCCESS: "Product updated successfully.",
  PRODUCT_ACTIVATE_SUCCESS: "Product activated successfully.",
  PRODUCT_DEACTIVATE_SUCCESS: "Product deactivated successfully.",
  USER_CREATE_SUCCESS: "User created successfully.",
  USER_UPDATE_SUCCESS: "User updated successfully.",
  USER_ACTIVATE_SUCCESS: "User activated successfully.",
  USER_DEACTIVATE_SUCCESS: "User deactivated successfully.",
  CHANGE_PASSWORD_SUCCESS: "Password changed successfully.",
  CALENDLY_ID_GENERATE_SUCCESS: "Calendly ID generated successfully.",
  DISBURSEMENT_UPDATE_SUCCESS: "Disbursement updated successfully.",
  MEETING_DATA_NOT_FOUND_ERROR: "Meeting data not found.",
  REQUIRED_FIELD_ERROR: "Please fill required fields.",
  IMPORT_CLIENT_RECORD_ERROR: "The number of entries cannot exceed 100.",
  SUCCESS_TRANSFER_INSTRUCTION: "Instruction transferred successfully.",
  SUCCESS_CLIENT_DELETE: "Client deleted successfully.",
  EMAIL_TEMPLATE_CREATE_SUCCESS: "Email Template created successfully.",
  EMAIL_TEMPLATE_UPDATE_SUCCESS: "Email Template updated successfully.",
  EMAIL_TEMPLATE_ACTIVATE_SUCCESS: "Email Template activated successfully.",
  EMAIL_TEMPLATE_DEACTIVATE_SUCCESS: "Email Template deleted successfully.",
  ERROR_COMMENT_CREATE: "Failed to add comment.",
  SUCCESS_COMMENT_CREATE: "Comment added successfully.",
  SUCCESS_TICKET_CREATE: "Ticket created successfully.",
  SUCCESS_NOTE_CREATE: "Note created successfully",
  DUE_DATE_UPDATE_SUCCESS: "Due date updated successfully",
  EMAIL_FILE_SIZE: "Total file size must be under 20MB.",
  DOC_UPDATE_FAILURE: "You can't update document with same data",
  DOC_UPDATE_SUCCESS: "Document updated successfully. ",
  SUCCESS_TRANSFER_MAIL: "Email transferred successfully.",
  EMAIL_SENT_SUCCESS: "Email sent successfully.",
  CAMPAIGN_CREATE_SUCCESS: "Campaign created successfully.",
  CAMPAIGN_UPDATE_SUCCESS: "Campaign updated successfully.",
  ACTION_COMPLETE_SUCESS: "Action completed successfully. ",
  REPORT_UPDATED_SUCESS: "Report updated successfully",
  PRESET_CREATED_SUCESS: "Filter created successfully",
  PRESET_UPDATED_SUCESS: "Filter updated successfully",
  MEETING_STATUS_UPDATED_SUCCESS: "Meeting status updated successfully. It will take time to update",
};

// Instruction Screen Form List Cofiguration

export const INSTRUCTION_FORMS = [
  {
    id: 0,
    isExpanded: true,
    prop: "InstructionClients",
    title: "Fact Find",
    formTabLabels: "FF",
  },
  {
    id: 1,
    isExpanded: false,
    prop: "InstructionWillQuestionnaire",
    title: "Will Q",
    formTabLabels: "W",
    unlockKey: "willQuestionnaire",
  },
  {
    id: 2,
    isExpanded: false,
    prop: "InstructionLPA_Questionnaire",
    title: "LPA Q",
    formTabLabels: "LPA",
    unlockKey: "lpaQuestionnaire",
  },
  {
    id: 3,
    isExpanded: false,
    prop: "InstructionTrustQuestionnaire",
    title: "Trust Q",
    formTabLabels: "T",
    unlockKey: "trustQuestionnaire",
  },
  {
    id: 4,
    isExpanded: false,
    prop: "InstructionDecOfTrustQuestionnaire",
    title: "Decl. of Trust Q",
    formTabLabels: "DoT",
    unlockKey: "declarationOfTrustQuestionnaire",
  },
  {
    id: 5,
    isExpanded: false,
    prop: "InstructionLivingWillQuestionnaire",
    title: "Living Will Q",
    formTabLabels: "LW",
    unlockKey: "livingWillQuestionnaire",
    component: <LivingWillQuestionnaireForm />,
  },
  {
    id: 6,
    isExpanded: false,
    prop: "InstructionAddressBook",
    title: "Address Book",
    formTabLabels: "ADD",
    unlockKey: "addressBooks",
    component: <AddressBookPage />,
  },
  // {
  //   id: 7,
  //   isExpanded: false,
  //   prop: "InstructionAppointmentDeclarations",
  //   title: "All Notes",
  //   formTabLabels: "Notes",
  // },
];

// Fact Find Form Section
export const factFindform_sections = () => {
  return [
    {
      id: 1,
      isExpanded: false,
      title: "Client Details",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <ClientDetailsForm />,
    },
    {
      id: 2,
      isExpanded: false,
      title: "Dependants",
      titleBigBlack: "",
      titleSmallBlack:
        "Dependents are children under the age of 18 or vulnerable adults",
      title2Big: "",
      title3Small: "",
      component: <DependentForm />,
    },
    {
      id: 3,
      isExpanded: false,
      title: "Gift",
      titleBigBlack: "",
      titleSmallBlack:
        "Have you made any significant asset or monetary gifts in the last 7 years?",
      title2Big: "",
      title3Small: "",
      component: <GiftForm />,
    },
    {
      id: 4,
      isExpanded: false,
      title: "Properties",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <PropertiesForm />,
    },
    {
      id: 5,
      isExpanded: false,
      title: "Savings",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <SavingForm />,
    },
    {
      id: 6,
      isExpanded: false,
      title: "Shares & Investments",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <SharesAndInvestmentForm />,
    },
    {
      id: 7,
      isExpanded: false,
      title: "Life Insurance",
      titleBigBlack: "",
      titleSmallBlack:
        "Policies with a named beneficiary or written into trust will NOT form part of a client's estate for IHT purposes",
      title2Big: "",
      title3Small: "",
      component: <LifeInsurenceForm />,
    },
    {
      id: 8,
      isExpanded: false,
      title: "Pension",
      titleBigBlack: "",
      titleSmallBlack:
        "These can generally be considered to NOT be included in a Client's Estate.",
      title2Big: "",
      title3Small: "",
      component: <PensionForm />,
    },
    {
      id: 9,
      isExpanded: false,
      title: "Assets",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <AssetForm />,
    },
    {
      id: 10,
      isExpanded: false,
      title: "Business Assets",
      titleBigBlack: "",
      titleSmallBlack:
        "Shares can attract up to 100% Relief / Property at up to 50% Relief",
      title2Big: "",
      title3Small: "",
      component: <BusinessAssetForm />,
    },
    {
      id: 11,
      isExpanded: false,
      title: "Liabilities",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <LiabilitiesForm />,
    },
    {
      id: 13,
      isExpanded: false,
      title: "Exclusions",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <ExclusionsForm />,
    },
    {
      id: 12,
      isExpanded: false,
      title: "Beneficiaries",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <BeneficiariesForm />,
    },
  ];
};

// Will Questionnaire Form Section
export const willQuestionnaireform_sections = () => {
  return [
    {
      id: 1,
      isExpanded: false,
      title: "Testators",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <TestatorItem />,
    },
    {
      id: 2,
      isExpanded: false,
      title: "Executors And Trustees",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <ExecutorAndTrustee />,
    },
    {
      id: 3,
      isExpanded: false,
      title: "Professional Executor",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <ProfessionalExecutor />,
    },
    {
      id: 4,
      isExpanded: false,
      title: "Guardians",
      titleBigBlack: "(on second death)",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <Guardians />,
    },
    {
      id: 5,
      isExpanded: false,
      title: "Chattels and Gifts / Special Legacies",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "Expression of Wishes",
      title3Small:
        "You may leave your chattels and personal items “to be distributed according to any expression of wishes”. Your trustees will then carry out your wishes as directed.",
      component: <ChattelsAndGifts />,
    },
    {
      id: 6,
      isExpanded: false,
      title: "Monetary Legacies",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <MonetaryLegacies />,
    },
    {
      id: 7,
      isExpanded: false,
      title: "Will Trusts",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <WillTrusts />,
    },
    {
      id: 8,
      isExpanded: false,
      title: "Residue Of Estate",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <ResidueOfEstate />,
    },
    {
      id: 9,
      isExpanded: false,
      title: "Exclusions",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <Exclusions />,
    },
    {
      id: 10,
      isExpanded: false,
      title: "Business Interests",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <BusinessInterests />,
    },
    {
      id: 11,
      isExpanded: false,
      title: "Funeral Wishes",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <FuneralWishes />,
    },
    {
      id: 12,
      isExpanded: false,
      title: "Additional or Useful Information & Explanatory Notes",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <AdditionalNote />,
    },
  ];
};
// LPA Questionnaire Form Sections
export const lpaQuestionnaireFromSections = () => {
  return [
    {
      id: 1,
      isExpanded: false,
      title: "Attorneys",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <AttorneysItem />,
    },
    {
      id: 2,
      isExpanded: false,
      title: "Replacement Attorneys",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <ReplacementAttorneysItem />,
    },
    {
      id: 3,
      isExpanded: false,
      title: "People to be told",
      titleBigBlack: "(optional)",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <PeopleToBeToldItem />,
    },
    {
      id: 4,
      isExpanded: false,
      title: "Certificate Provider Details",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: <CertificateProvideItem />,
    },
  ];
};
// Nested Trust Form Sections
export const nestedTrustFormSections = (arr, isJoint, trustIndex) => {
  return [
    {
      id: 1,
      isExpanded: false,
      title: "Trustees",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: (
        <Trustees arr={arr} isJoint={isJoint} trustIndex={trustIndex} />
      ),
    },
    {
      id: 2,
      isExpanded: false,
      title: "Guidance to Trustees",
      titleBigBlack: "",
      titleSmallBlack:
        "Guidance is not binding on the Trustees but it is incumbent on them to take a Settlor’s wishes into account. A Trustee must always act in the best interest of the beneficiaries.",
      title2Big: "",
      title3Small: "",
      component: (
        <GuidanceToTrustees
          arr={arr}
          isJoint={isJoint}
          trustIndex={trustIndex}
        />
      ),
    },
    {
      id: 3,
      isExpanded: false,
      title: "Assets being transferred",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: (
        <GuidanceAssetsBeingTransferred
          arr={arr}
          isJoint={isJoint}
          trustIndex={trustIndex}
        />
      ),
    },
  ];
};
// Form names
export const formNames = {
  factFind: "Fact Find",
  willQuestionnaire: "Will Questionnaire",
  lpaQuestionnaire: "LPA Questionnaire",
  trustQuestionnaire: "Trust Questionnaire",
  declarationOfTrustQuestionnaire: "Declaration of Trust Questionnaire",
  livingWillQuestionnaire: "Living Will Questionnaire",
  addressBook: "Address Book",
  appointmentDeclaration: "Appointment Declaration",
};
// Form header list
export const formHeaderList = {
  clientName: [
    formNames.factFind,
    formNames.willQuestionnaire,
    formNames.lpaQuestionnaire,
    formNames.livingWillQuestionnaire,
  ],
  applicationTypeAndRelation: [formNames.factFind],
  editClients: [formNames.fact],
};
// sidebar with icon and name route path
export const sideBarWithIconAndName = [
  "/app/instructions/dashboard",
  "/app/instructions/forms",
  "/app/setupNewMeeting",
  "/app/client/import-list",
  "/app/manage/create-task-management",
];
// Validation messages
export const VALIDATION_MSG = {
  REQUIRED: "This field is required.",
  VALID_MOBILE_NUMBER: "Enter a valid mobile number.",
  VALID_EMAIL_ID: "Enter a valid email.",
  NUM_ONLY: "Please enter a valid number.",
  DROPDOWN: "Select one option.",
  VALID_DOCUMENT: "Please upload a valid document.",
  FILE_SIZE: "File size must be under 30MB.",
  NOTES_DOCUMENT_REQUIRED: "Note or document is required.",
  INVALID_DATE: "Invalid date format.",
  DIGIT_NUMBER: "Enter only digit number",
  TIME_VALUE: "Please enter negative value.",
};
// QC loop up names
export const QC_LookupNames = [
  "yes_no_lookupType",
  "beneficiary_classification",
  "pregancy_lookupType",
  "Property Financial Affairs",
  "living_will_q_consiousness",
  "Relation With Client",
  "willQuestionnaire_executor_type",
  "RELATIONSHIP",
  "relationshipAddr",
  "PERSONAL_PROOF_TYPE",
  "RESIDENCY_PROOF_TYPE",
  "CERTIFICATION_TYPE",

  "liabilitiesData",
  "Pension Type",
  "Pension Type Status",
  "Business Type Asset",
  "Insurance Type",
  "Assets",
  "savingAreas",
  "ElectedResidence",
  "shareTypeData",
  // following lookups need mapping, todo tell backend to change format
  "Executers & Trustee --> Role",
  "Employment Status",
  "Gender",
  // used in instruction dashboard
  "Instruction Status",
  "Invoice Status",
  "Docuemnt Type",

  // Generic lookups
  "Role",
  "Gender",
  "Salutation",
  "Country",
  "milestone",
  "Meeting Type",
  "Source Type",
  "Commission Status",
  "Document Type",
  "slaUnit",
  "TriggerType",
];

// Addressbook lookups
export const ADD_BOOK_ROLES = {
  Guardian: "Guardian",
  Client: "Client",
  Trustee: "Trustee",
  Attorney: "Attorney",
  Replacement_Attorney: "Replacement Attorney",
  Beneficiary: "Beneficiary",
  Testator: "Testator",
  Settlor: "Settlor",
  Reserve_Beneficiaries: "Reserve Beneficiaries",
  Certificate_Provider: "Certificate Provider",
  Dependant: "Dependant",
  Exclusion: "Exclusion",

  Both: "Executors & Trustees",
  ExecutorReserve: "Reserve Executor",
  ReserveTrustee: "Reserve Trustee",
  ReserveExec_Trustee: "Reserve Exec/Trustee",
  Executor: "Executor",
  WillTrustTrustee: "Will Trust Trustee",
  PersonTobeNotified: "Person to be Notified",
  Donor: "Donor",
  WillTrustBeneficiary: "Will Trust Beneficiary",
  monetaryLegacies: "Monetary Legacies",
  chattlesLegacies: "Chattles Legacies",
};

// used for QC form validation
export const VALIDATION_FORMAT = {
  percentageOnly: {
    pattern: /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/,
    message: "Please enter valid percentage",
  },
  alphabetOnly: { pattern: /^[a-zA-Z ]*$/, message: "Please enter valid text" },
  ukPostCode: {
    pattern:
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
    message: "Please enter valid postcode",
  },
  moneyOnly: {
    pattern: /^[0-9]{0,12}([,][0-9]{2,2})?$/,
    message: "Please enter valid amount",
  },
  elevenDigit: {
    pattern: /^0\d{10}$/,
    message: "Please enter valid phone number",
  },
  dateOnly: {
    pattern:
      /(^([0]?[1-9]|[1-2][0-9]|(3)[0-1])(\/)(([0]?[0-9])|((1)[0-2]))(\/)\d{4}$|^[A-Z][a-z]{2}\s[A-Z][a-z]{2}\s\d{2}\s\d{4}\s\d{2}:\d{2}:\d{2}\sGMT[-+]\d{4}\s\([A-Za-z\s]+\)$)/,
    // /^(((0[1-9]|[12][0-9]|3[01])[- /.](0[13578]|1[02])|(0[1-9]|[12][0-9]|30)[- /.](0[469]|11)|(0[1-9]|1\d|2[0-8])[- /.]02)[- /.]\d{4}|29[- /.]02[- /.](\d{2}(0[48]|[2468][048]|[13579][26])|([02468][048]|[1359][26])00))$/,
    message: "Please enter valid date",
  },
  quantityOnly: {
    pattern: /^[\d]*$/,
    message: "Invalid quantity",
  },
  addressOnly: {
    pattern:
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
    message: "Please enter a valid address",
  },
  townName: {
    pattern: /^\p{Lu}\p{L}*(?:[\s-]\p{Lu}\p{L}*)*$/,
    message: "Please enter a valid town",
  },
  phoneNumOnly: {
    pattern:
      /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
    message: "Please enter a valid number",
  },
  emailOnly: {
    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    message: "Please enter valid email",
  },
  fullName: {
    pattern:
      /(^[A-Za-z]{3,16})([ ]{0,1})([A-Za-z]{3,16})?([ ]{0,1})?([A-Za-z]{3,16})?([ ]{0,1})?([A-Za-z]{3,16})/,
    message: "Please enter valid name",
  },
  nameHyphens: {
    pattern: /^[A-Za-z\s]+(?:-[A-Za-z\s]+)*$/,
    message: "Please enter valid name",
  },
  notes: {
    pattern: /\/\*[.\w\s]*\*\//,
    message: "Please enter a valid message",
  },
  ukPhone: {
    pattern: /^(?:(?:00)?|0)7(?:[45789]\d{2}|624)\d{6}$/,
    message: "Invalid phone number",
  },
};
// person name keys of form
export const PERSON_NAME_KEYS_OF_FORM = [
  "name",
  "fullName",
  "giftTo",
  "beneficiaryName",
  "trusteeName",
  "assetName",
  "practiceName",
  "beneficiary",
  "to",
];
// commission status
export const COMMISSION_STATUS = [
  {
    id: 1,
    name: "Yes",
    value: true,
  },
];

// is verified data
export const IS_VAT_VERIFIED_DATA = [
  { id: 1, value: true, label: "Yes" },
  { id: 2, value: false, label: "No" },
];
// yes no data
export const YES_NO_DATA = [
  { id: 1, value: true, label: "Yes" },
  { id: 2, value: false, label: "No" },
];
// Questionnair cards fullname property names
export const AddressBookSourceFullNames = [
  "fullName",
  "ultimateBeneficiary",
  "beneficiary",
  "trusteeName",
  "name",
  "beneficiaryName",
  "ultimate",
  "recipientName",
];

// Nationality Data
export const NATIONALITY_DATA = [
  { label: "Afghan", value: "Afghan" },
  { label: "Albanian", value: "Albanian" },
  { label: "Algerian", value: "Algerian" },
  { label: "American", value: "American" },
  { label: "Andorran", value: "Andorran" },
  { label: "Angolan", value: "Angolan" },
  { label: "Antiguans", value: "Antiguans" },
  { label: "Argentinean", value: "Argentinean" },
  { label: "Armenian", value: "Armenian" },
  { label: "Australian", value: "Australian" },
  { label: "Austrian", value: "Austrian" },
  { label: "Azerbaijani", value: "Azerbaijani" },
  { label: "Bahamian", value: "Bahamian" },
  { label: "Bahraini", value: "Bahraini" },
  { label: "Bangladeshi", value: "Bangladeshi" },
  { label: "Barbadian", value: "Barbadian" },
  { label: "Barbudans", value: "Barbudans" },
  { label: "Batswana", value: "Batswana" },
  { label: "Belarusian", value: "Belarusian" },
  { label: "Belgian", value: "Belgian" },
  { label: "Belizean", value: "Belizean" },
  { label: "Beninese", value: "Beninese" },
  { label: "Bhutanese", value: "Bhutanese" },
  { label: "Bolivian", value: "Bolivian" },
  { label: "Bosnian", value: "Bosnian" },
  { label: "Brazilian", value: "Brazilian" },
  { label: "British", value: "British" },
  { label: "Bruneian", value: "Bruneian" },
  { label: "Bulgarian", value: "Bulgarian" },
  { label: "Burkinabe", value: "Burkinabe" },
  { label: "Burmese", value: "Burmese" },
  { label: "Burundian", value: "Burundian" },
  { label: "Cambodian", value: "Cambodian" },
  { label: "Cameroonian", value: "Cameroonian" },
  { label: "Canadian", value: "Canadian" },
  { label: "Cape Verdean", value: "Cape Verdean" },
  { label: "Central African", value: "Central African" },
  { label: "Chadian", value: "Chadian" },
  { label: "Chilean", value: "Chilean" },
  { label: "Chinese", value: "Chinese" },
  { label: "Colombian", value: "Colombian" },
  { label: "Comoran", value: "Comoran" },
  { label: "Congolese", value: "Congolese" },
  { label: "Costa Rican", value: "Costa Rican" },
  { label: "Croatian", value: "Croatian" },
  { label: "Cuban", value: "Cuban" },
  { label: "Cypriot", value: "Cypriot" },
  { label: "Czech", value: "Czech" },
  { label: "Danish", value: "Danish" },
  { label: "Djibouti", value: "Djibouti" },
  { label: "Dominican", value: "Dominican" },
  { label: "Dutch", value: "Dutch" },
  { label: "East Timorese", value: "East Timorese" },
  { label: "Ecuadorean", value: "Ecuadorean" },
  { label: "Egyptian", value: "Egyptian" },
  { label: "Emirian", value: "Emirian" },
  { label: "Equatorial Guinean", value: "Equatorial Guinean" },
  { label: "Eritrean", value: "Eritrean" },
  { label: "Estonian", value: "Estonian" },
  { label: "Ethiopian", value: "Ethiopian" },
  { label: "Fijian", value: "Fijian" },
  { label: "Filipino", value: "Filipino" },
  { label: "Finnish", value: "Finnish" },
  { label: "French", value: "French" },
  { label: "Gabonese", value: "Gabonese" },
  { label: "Gambian", value: "Gambian" },
  { label: "Georgian", value: "Georgian" },
  { label: "German", value: "German" },
  { label: "Ghanaian", value: "Ghanaian" },
  { label: "Greek", value: "Greek" },
  { label: "Grenadian", value: "Grenadian" },
  { label: "Guatemalan", value: "Guatemalan" },
  { label: "Guinea-Bissauan", value: "Guinea-Bissauan" },
  { label: "Guinean", value: "Guinean" },
  { label: "Guyanese", value: "Guyanese" },
  { label: "Haitian", value: "Haitian" },
  { label: "Herzegovinian", value: "Herzegovinian" },
  { label: "Honduran", value: "Honduran" },
  { label: "Hungarian", value: "Hungarian" },
  { label: "I-Kiribati", value: "I-Kiribati" },
  { label: "Icelander", value: "Icelander" },
  { label: "Indian", value: "Indian" },
  { label: "Indonesian", value: "Indonesian" },
  { label: "Iranian", value: "Iranian" },
  { label: "Iraqi", value: "Iraqi" },
  { label: "Irish", value: "Irish" },
  { label: "Israeli", value: "Israeli" },
  { label: "Italian", value: "Italian" },
  { label: "Ivorian", value: "Ivorian" },
  { label: "Jamaican", value: "Jamaican" },
  { label: "Japanese", value: "Japanese" },
  { label: "Jordanian", value: "Jordanian" },
  { label: "Kazakhstani", value: "Kazakhstani" },
  { label: "Kenyan", value: "Kenyan" },
  { label: "Kittian and Nevisian", value: "Kittian and Nevisian" },
  { label: "Kuwaiti", value: "Kuwaiti" },
  { label: "Kyrgyz", value: "Kyrgyz" },
  { label: "Laotian", value: "Laotian" },
  { label: "Latvian", value: "Latvian" },
  { label: "Lebanese", value: "Lebanese" },
  { label: "Liberian", value: "Liberian" },
  { label: "Libyan", value: "Libyan" },
  { label: "Liechtensteiner", value: "Liechtensteiner" },
  { label: "Lithuanian", value: "Lithuanian" },
  { label: "Luxembourger", value: "Luxembourger" },
  { label: "Macedonian", value: "Macedonian" },
  { label: "Malagasy", value: "Malagasy" },
  { label: "Malawian", value: "Malawian" },
  { label: "Malaysian", value: "Malaysian" },
  { label: "Maldivan", value: "Maldivan" },
  { label: "Malian", value: "Malian" },
  { label: "Maltese", value: "Maltese" },
  { label: "Marshallese", value: "Marshallese" },
  { label: "Mauritanian", value: "Mauritanian" },
  { label: "Mauritian", value: "Mauritian" },
  { label: "Mexican", value: "Mexican" },
  { label: "Micronesian", value: "Micronesian" },
  { label: "Moldovan", value: "Moldovan" },
  { label: "Monacan", value: "Monacan" },
  { label: "Mongolian", value: "Mongolian" },
  { label: "Moroccan", value: "Moroccan" },
  { label: "Mosotho", value: "Mosotho" },
  { label: "Motswana", value: "Motswana" },
  { label: "Mozambican", value: "Mozambican" },
  { label: "Namibian", value: "Namibian" },
  { label: "Nauruan", value: "Nauruan" },
  { label: "Nepalese", value: "Nepalese" },
  { label: "New Zealander", value: "New Zealander" },
  { label: "Nicaraguan", value: "Nicaraguan" },
  { label: "Nigerian", value: "Nigerian" },
  { label: "Nigerien", value: "Nigerien" },
  { label: "North Korean", value: "North Korean" },
  { label: "Northern Irish", value: "Northern Irish" },
  { label: "Norwegian", value: "Norwegian" },
  { label: "Omani", value: "Omani" },
  { label: "Pakistani", value: "Pakistani" },
  { label: "Palauan", value: "Palauan" },
  { label: "Panamanian", value: "Panamanian" },
  { label: "Papua New Guinean", value: "Papua New Guinean" },
  { label: "Paraguayan", value: "Paraguayan" },
  { label: "Peruvian", value: "Peruvian" },
  { label: "Polish", value: "Polish" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Qatari", value: "Qatari" },
  { label: "Romanian", value: "Romanian" },
  { label: "Russian", value: "Russian" },
  { label: "Rwandan", value: "Rwandan" },
  { label: "Saint Lucian", value: "Saint Lucian" },
  { label: "Salvadoran", value: "Salvadoran" },
  { label: "Samoan", value: "Samoan" },
  { label: "San Marinese", value: "San Marinese" },
  { label: "Sao Tomean", value: "Sao Tomean" },
  { label: "Saudi", value: "Saudi" },
  { label: "Scottish", value: "Scottish" },
  { label: "Senegalese", value: "Senegalese" },
  { label: "Serbian", value: "Serbian" },
  { label: "Seychellois", value: "Seychellois" },
  { label: "Sierra Leonean", value: "Sierra Leonean" },
  { label: "Singaporean", value: "Singaporean" },
  { label: "Slovakian", value: "Slovakian" },
  { label: "Slovenian", value: "Slovenian" },
  { label: "Solomon Islander", value: "Solomon Islander" },
  { label: "Somali", value: "Somali" },
  { label: "South African", value: "South African" },
  { label: "South Korean", value: "South Korean" },
  { label: "Spanish", value: "Spanish" },
  { label: "Sri Lankan", value: "Sri Lankan" },
  { label: "Sudanese", value: "Sudanese" },
  { label: "Surinamer", value: "Surinamer" },
  { label: "Swazi", value: "Swazi" },
  { label: "Swedish", value: "Swedish" },
  { label: "Swiss", value: "Swiss" },
  { label: "Syrian", value: "Syrian" },
  { label: "Taiwanese", value: "Taiwanese" },
  { label: "Tajik", value: "Tajik" },
  { label: "Tanzanian", value: "Tanzanian" },
  { label: "Thai", value: "Thai" },
  { label: "Togolese", value: "Togolese" },
  { label: "Tongan", value: "Tongan" },
  { label: "Trinidadian or Tobagonian", value: "Trinidadian or Tobagonian" },
  { label: "Tunisian", value: "Tunisian" },
  { label: "Turkish", value: "Turkish" },
  { label: "Tuvaluan", value: "Tuvaluan" },
  { label: "Ugandan", value: "Ugandan" },
  { label: "Ukrainian", value: "Ukrainian" },
  { label: "Uruguayan", value: "Uruguayan" },
  { label: "Uzbekistani", value: "Uzbekistani" },
  { label: "Venezuelan", value: "Venezuelan" },
  { label: "Vietnamese", value: "Vietnamese" },
  { label: "Welsh", value: "Welsh" },
  { label: "Yemenite", value: "Yemenite" },
  { label: "Zambian", value: "Zambian" },
  { label: "Zimbabwean", value: "Zimbabwean" },
];

// Instruction task Type constants
export const TASK_TYPES = [
  {
    id: 1,
    name: "All",
    value: "All",
  },
  {
    id: 2,
    name: "Instruction",
    value: "Instruction",
  },
  {
    id: 3,
    name: "Duplicate",
    value: "Individual",
  },
  {
    id: 4,
    name: "Custom",
    value: "Custom",
  },
];

// export const TASK_TYPES_CONSULTANT = [
//   {
//     id: 1,
//     name: "All",
//     value: "All",
//   },
//   {
//     id: 2,
//     name: "Duplicate",
//     value: "Individual",
//   },
//   {
//     id: 3,
//     name: "Custom",
//     value: "Custom",
//   },
// ];

export const supportTicketPrioritiesLookup = {
  1: {
    name: "Low",
    color: "#10b08f",
  },
  2: {
    name: "Medium",
    color: "#509dfb",
  },
  3: {
    name: "High",
    color: "#ed7d27",
  },
  4: {
    name: "Urgent",
    color: "#f33735",
  },
};

export const supportTicketStatusValuesLookup = {
  2: "Open",
  3: "Pending",
  4: "Resolved",
  5: "Closed",
};

export const containerTypes = ["Open", "In-progress", "Resolved", "Closed"];

export const seviceRequestSource = {
  IPAD: "iPad App",
  WEB: "Web App",
};

export const freshServiceNotifyEmailsList = ["rohant@iconnectsolutions.com"];

export const formNameForPdfGeneration = {
  0: "factFind",
  1: "willQuestionnaire",
  2: "lpaQuestionnaire",
  3: "trustQuestionnaire",
  4: "declarationOfTrustQuestionnaire",
  5: "livingWillQuestionnaire",
  6: "addressBooks",
};

export const trustQuestionnaire_assetsBeingTransferredMethod = [
  {
    value: "Full Transfer - TR1",
    label: "Full Transfer - TR1",
  },
  {
    value: "Deed of Assignment",
    label: "Deed of Assignment",
  },
];

export const WEEKDAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const MONDAY_TICKET_IFRAME_SRC_FORM="https://forms.monday.com/forms/embed/5f1c7d21b3a02edc952832e8e884b24e?r=euc1"
export const MONDAY_TICKET_IFRAME_SRC_BOARD="https://view.monday.com/embed/1599136946-3271d1276a711ff7c72421b8f28e8079?r=euc1"