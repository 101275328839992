import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Fab, Grid, Tooltip } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNotesDrawer } from "../../../slice/dashboard";
import CurrentWeeksInstruction from "../../CurrentWeekInstruction/component/currentWeeksInstruction";
import NotesDrawer from "../../Notes/notesDrawer";
import ThingsToDo from "../../ThingsToDo/components/thingsToDo";

const Dashboard = () => {
  const dispatch = useDispatch();
  const isNote = useSelector((state) => state?.dashboard?.isNote);
  return (
    <>
      {/* Tasks Comments Drawer */}
      {isNote && (
        <NotesDrawer
          open={isNote}
          handleClose={() => dispatch(updateNotesDrawer())}
        />
      )}
      <Grid container height={`${window.innerHeight - 10}px`} padding={1}>
        <Tooltip title="Notes">
          <Fab
            color="primary"
            aria-label="add"
            style={{
              position: "absolute",
              top: "40%",
              right: 1,
              backgroundColor: "#00ce3f",
              borderBottomLeftRadius: "20px",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
            }}
            size="small"
            onClick={() => dispatch(updateNotesDrawer())}
          >
            <NotificationsActiveIcon />
          </Fab>
        </Tooltip>
        <Grid
          item
          xs={0}
          display={"flex"}
          flexDirection={"row"}
          padding={2}
          boxSizing={"border-box"}
          margin={0}
          overflow={"hidden"}
          maxHeight={"90vh"}
          style={{ width: "100%" }}
        >
          {/* Grid Section for things to do */}
          <Grid
            xs={7}
            display={"flex"}
            flexDirection={"column"}
            pr={2}
            className="dashboard-scrollbar"
            height="90vh"
          >
            <ThingsToDo />
          </Grid>
          {/* Grid Section for New Instructions (Current Week) */}
          <Grid item xs={5} pl={1} maxHeight={"90vh"}>
            <CurrentWeeksInstruction />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
