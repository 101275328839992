import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FormControl } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import _ from "lodash";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useParams } from "react-router-dom";
import {
  DATE_FORMAT,
  GMT_DATE_FORMAT,
  PERSON_NAME_KEYS_OF_FORM,
} from "../utils/constant";
import { stringIncludesName } from "../utils/util";
import { format } from "date-fns";

export const TextInput = (props) => {
  const {
    name,
    label,
    placeholder,
    disabled = false,
    rules = { required: { value: false, message: "" } },
    inputType = null,
    multiline = false,
    maxRows = 0,
  } = props;
  const params = useParams();
  const { control, setValue, getValues } = useFormContext();

  const [inputFoused, setInputFocused] = useState(false);

  let isFormPage = window.location.pathname.includes("app/instructions/forms");

  // SECTION - get name suggestions
  const getNamesSuggestion = (input_text_value) => {
    let names_with_fullName = findValuesByKeys(
      getValues(),
      PERSON_NAME_KEYS_OF_FORM
    );

    let eliminate = [
      "estimated production costs",
      "potential costs of suffering a loss of capacity",
      "potential costs for probate",
      "estimated production costs and potential deductions",
      "funds to be removed from estate to provide beneficiaries with instant access to funds",
      "prospect",
    ];

    let namesData1 = props?.namesData || [];

    let names_array = [...names_with_fullName, ...namesData1].filter((item) => {
      if (
        names_with_fullName &&
        input_text_value?.length > 1 &&
        item !== undefined &&
        item !== null &&
        item !== "" &&
        typeof item === "string" &&
        !item.toLowerCase().includes("prospect")
      ) {
        return (
          item?.toLowerCase().includes(input_text_value?.toLowerCase()) &&
          item?.toLowerCase() !== input_text_value?.toLowerCase() &&
          !eliminate.some((item) =>
            item.toLowerCase().includes(input_text_value?.toLowerCase())
          )
        );
      } else {
        return "";
      }
    });

    let unique_names = _.uniqBy(names_array, (value) => value.toLowerCase());
    return unique_names;
  };

  function findValuesByKeys(obj, keys, collection = []) {
    if (typeof obj !== "object" || obj === null) {
      return collection;
    }

    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        findValuesByKeys(obj[i], keys, collection);
      }
    } else {
      const objKeys = Object.keys(obj);
      for (let i = 0; i < objKeys.length; i++) {
        const key = objKeys[i];
        if (keys.includes(key) && obj[key] !== "") {
          collection.push(obj[key]);
        }
        findValuesByKeys(obj[key], keys, collection);
      }
    }
    return collection;
  }

  return (
    <FormControl fullWidth>
      <div className="normal_normal_12_Manrope green inputTextField">
        {label}
      </div>
      <Controller
        name={name}
        rules={rules}
        label={label}
        control={control}
        placeholder="some"
        render={({ field, fieldState: { error } }) => (
          <>
            <TextField
              multiline={multiline}
              maxRows={maxRows}
              {...params}
              className="inputTextField border_color"
              id={name}
              inputMode={inputType !== null ? inputType : "text"}
              error={error?.message?.length}
              helperText={error?.message}
              disabled={disabled}
              variant="standard"
              placeholder={placeholder}
              onFocus={() => setInputFocused(true)}
              onBlur={() => {
                setTimeout(() => {
                  setInputFocused(false);
                }, 100);
              }}
              InputProps={{
                className: "normal_normal_16_Manrope",
              }}
              InputLabelProps={{
                className: "normal_normal_12_Manrope",
              }}
              value={field?.value}
              onChange={(e) => {
                // default field change event
                field?.onChange(e);
                // get name suggestion
                let names = getNamesSuggestion(e.target.value);
              }}
            />
            {/* SECTION -- names suggestion container */}
            {getNamesSuggestion(field.value)?.length &&
            inputFoused &&
            stringIncludesName(name, PERSON_NAME_KEYS_OF_FORM) ? (
              <Paper
                elevation={3}
                style={{
                  position: "absolute",
                  zIndex: 100,
                  top: "100%",
                  left: 0,
                  width: "100%",
                }}
              >
                <List>
                  {getNamesSuggestion(field.value)?.map(
                    (listItemName, listItemIndex) => (
                      <ListItem
                        key={listItemIndex}
                        button
                        onClick={() => setValue(name, listItemName)}
                      >
                        {listItemName}
                      </ListItem>
                    )
                  )}
                </List>
              </Paper>
            ) : null}
          </>
        )}
      />
    </FormControl>
  );
};
// hookform component without context
export const TextController = ({
  name,
  label,
  placeholder,
  disabled = false,
  rules = { required: { value: false, message: "" } },
  inputType = null,
  InputProps,
  methods,
  ...textfieldProps
}) => {
  const { control } = methods;
  return (
    <Controller
      name={name}
      rules={rules}
      label={label}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            id={name}
            size="small"
            inputMode={inputType !== null ? inputType : "text"}
            error={error?.message.length}
            helperText={error?.message}
            disabled={disabled}
            variant="outlined"
            placeholder={placeholder || ""}
            InputProps={{
              ...(InputProps && { ...InputProps }),
            }}
            value={field?.value}
            onChange={field?.onChange}
            {...textfieldProps}
          />
        </>
      )}
    />
  );
};

// customized auto complete field for email template
export const AutoCompleteField = (props) => {
  const {
    options,
    setOptions,
    onChange,
    validationName,
    inputAdornment,
    label,
    defaultValue,
    consultantEmail,
    ...rest
  } = props;

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const handleInputChange = (event, value) => {
    if (!value) return;
    if (validationName === "email" && !validateEmail(value)) return;
    const inputValue = value.trim();
    if (
      !options.some(
        (option) => option.label.toLowerCase() === inputValue.toLowerCase()
      )
    ) {
      setOptions([
        ...options,
        { label: inputValue, id: Math.random().toString() },
      ]);
    }
  };

  return (
    <Autocomplete
      multiple
      defaultValue={defaultValue || []}
      size="small"
      options={consultantEmail?.concat(options)}
      getOptionLabel={(option) => option.label}
      filterOptions={(options, params) => {
        const filter = params.inputValue.toLowerCase();
        return options.filter((option) =>
          option.label.toLowerCase().includes(filter)
        );
      }}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          label={label}
          placeholder={label}
          className="normal_normal_600_12_Manropee"
          {...params}
          InputProps={{
            ...params.InputProps,
            ...(inputAdornment && {
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    {inputAdornment}
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }),
          }}
          // sx={{
          //   "& .MuiOutlinedInput-root": {
          //     border: "1px solid rgba(0, 0, 0, 0.26)",
          //   },
          // }}
          variant="outlined"
        />
      )}
      onChange={onChange}
    />
  );
};

// useFormContext only autocomplete
export const AutoCompleteInput = (props) => {
  const { name, label, formMethods, inputStyle, ...rest } = props;

  let methods = useFormContext();
  if (!methods && formMethods) {
    methods = formMethods;
  }
  const { control, setValue, getValues } = methods;

  return (
    <>
      <div className="normal_normal_12_Manrope green" style={{ width: "100%" }}>
        {label}
      </div>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            value={field?.value}
            onChange={(event, value) => {
              field.onChange(value);
              if (props?.isRelationShip) {
                props.updateRelationship(value);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className="inputTextField border_color"
                variant={props?.variant || "standard"}
                error={error?.message.length}
                helperText={error?.message}
                sx={{ ...inputStyle }}
              />
            )}
            {...rest}
          />
        )}
      />
    </>
  );
};

export const NumberInput = ({
  name,
  label,
  rules = { required: { values: false, message: "" } },
  isCurrency,
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormControl fullWidth>
      <div className="normal_normal_12_Manrope green">{label}</div>
      <Controller
        name={name}
        rules={rules}
        control={control}
        defaultValue=""
        render={({ field, fieldState: { error } }) => (
          <>
            <TextField
              className="inputTextField border_color"
              // type="number"
              variant="standard"
              value={field.value}
              onChange={(value) => field.onChange(value)}
              error={error?.message.length}
              helperText={error?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {isCurrency ? "£" : ""}
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
      />
    </FormControl>
  );
};

export const DateInput = ({
  name,
  label,
  value,
  rules = { required: false, message: "" },
}) => {

  const { control } = useFormContext();

  const formatDate = (date) => {
    return format(date, GMT_DATE_FORMAT);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div>
        <div className="normal_normal_12_Manrope green">{label}</div>
        <Controller
          rules={rules}
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <DatePicker
                format={DATE_FORMAT}
                slotProps={{
                  textField: {
                    size: "small",
                    // helperText: dateError ? VALIDATION_MSG?.INVALID_DATE : "",
                  },
                }}
                value={field.value ? new Date(field.value) : null}
                onChange={(date) => {
                  const formattedDate = formatDate(date);
                  field.onChange(formattedDate);
                  // field.onChange(date)
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
                // onError={(newError) => setDateError(newError)}
                className="outlined_border"
                error={error?.message.length}
                helperText={error?.message}
              />
            </>
          )}
        />
      </div>
    </LocalizationProvider>
  );
};

export const RadioInput = ({
  name,
  label,
  options,
  orientation = "row",
  defaultValue,
  rules = { required: { value: false, message: "" } },
}) => {
  const { control, setValue, getValues } = useFormContext();

  const handleRadioChange = (value) => {
    setValue(name, value);
  };

  useEffect(() => {
    const activeRadioValue = getValues(name);
    let lastValue = options?.[options?.length - 1]?.value;
    if (activeRadioValue === undefined && lastValue !== undefined)
      setValue(name, lastValue?.toString());
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {label ? (
        <div className="normal_normal_12_Manrope green inputTextField">
          {label}
        </div>
      ) : null}
      <Controller
        name={name}
        rules={rules}
        control={control}
        defaultValue=""
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <RadioGroup
                sx={{
                  padding: 0,
                  verticalAlign: "top",
                }}
                row={orientation === "row"}
                {...field}
                value={field.value?.toString()} // convert boolean to string for comparison
                onChange={(e) =>
                  handleRadioChange(
                    ["true", "false"].includes(e.target.value)
                      ? e.target.value === "true"
                      : e.target.value
                  )
                }
              >
                {options?.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value?.toString()} // convert boolean to string
                    control={
                      <Radio
                        sx={{
                          "&, &.Mui-checked": {
                            color: "#00CE3F",
                          },
                        }}
                      />
                    }
                    label={
                      <span className="normal_normal_18_Manrope dark_grey">
                        {option.label}
                      </span>
                    }
                  />
                ))}
              </RadioGroup>
              {error?.message && (
                <span style={{ color: "red", fontSize: "13px" }}>
                  {error?.message}
                </span>
              )}
            </>
          );
        }}
      />
    </div>
  );
};

// dropdown input component
export const DropdownInput = ({
  name,
  label,
  options,
  rules = { required: false, message: "" },
  SelectProps,
}) => {
  const { control } = useFormContext();

  return (
    <div style={{ width: "100%" }}>
      <div className="normal_normal_12_Manrope green" style={{ width: "100%" }}>
        {label}
      </div>
      <Controller
        rules={rules}
        name={name}
        control={control}
        defaultValue=""
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <TextField
                defaultValue=""
                SelectProps={SelectProps}
                className="inputTextField border_color"
                select
                variant="standard"
                fullWidth
                onChange={field.onChange}
                value={field.value}
                error={error?.message.length}
                helperText={error?.message}
              >
                {options?.length ? (
                  options?.map((option) => (
                    <MenuItem
                      className="normal_normal_18_Manrope dark_grey"
                      key={option?.value}
                      value={option?.value}
                    >
                      {option?.label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem
                    className="normal_normal_18_Manrope dark_grey"
                    value=""
                  >
                    No option to select
                  </MenuItem>
                )}
              </TextField>
            </>
          );
        }}
      />
    </div>
  );
};

// Round White Dropdown Input component
export const RoundWhiteDropdownInput = ({
  label,
  options,
  error = false,
  errorMessage = "",
  handleChange,
  value,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <FormControl fullWidth>
        <div style={{ fontSize: "12px", color: "#3D4740", width: "100%" }}>
          {label}
        </div>

        <Select
          value={value}
          label={null}
          onChange={handleChange}
          sx={{
            border: "1px solid white !important",
            borderRadius: "30px",
            backgroundColor: "white",
          }}
        >
          {options?.map((opt, index) => {
            return (
              <MenuItem key={index} value={opt.value}>
                {opt.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

// Custom Checkbox Group component
export const CustomCheckboxGroup = ({
  name,
  label,
  options,
  orientation = "row",
  rules = { required: false, message: "" },
}) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <FormControl>
      <div className="normal_normal_12_Manrope green">{label}</div>

      <div
        style={{
          display: "flex",
          flexDirection: orientation === "row" ? "row" : "column",
        }}
      >
        {options?.map((option) => (
          <div key={option.value}>
            <Controller
              rules={rules}
              name={name}
              control={control}
              defaultValue={false}
              render={({ field, fieldState: { error } }) => (
                <>
                  <FormControlLabel
                    className="normal_normal_18_Manropee dark_grey"
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        sx={{
                          color: "#00CE3F",
                          "&.Mui-checked": {
                            color: "#00CE3F",
                          },
                        }}
                      />
                    }
                    label={option.label}
                  />
                </>
              )}
            />
          </div>
        ))}
      </div>

      {errors[name] && (
        <div style={{ fontSize: "12px", color: "red" }}>
          {errors[name].message}
        </div>
      )}
    </FormControl>
  );
};

// Single Checkbox component
export const SingleCheckbox = ({
  name,
  label,
  rules = { required: false, message: "" },
}) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControlLabel
            className="normal_normal_18_Manropee dark_grey"
            control={
              <Checkbox
                checked={field.value}
                onChange={field.onChange}
                sx={{
                  marginTop: -1.1,
                  color: "#00CE3F",
                  "&.Mui-checked": {
                    color: "#00CE3F",
                  },
                }}
              />
            }
            label={label}
          />
        )}
      />

      {errors[name] && (
        <div style={{ fontSize: "12px", color: "red" }}>
          {errors[name].message}
        </div>
      )}
    </div>
  );
};

/* Mobile number component with country code selection -->  react-phone-number-input library */

export const MobileNumberInput = ({
  name,
  label,
  rules = { required: { values: false, message: "" } },
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormControl fullWidth>
      <div className="normal_normal_12_Manrope green">{label}</div>
      <Controller
        name={name}
        rules={rules}
        control={control}
        defaultValue=""
        render={({ field, fieldState: { error } }) => (
          <>
            <label
              style={{ position: "relative", bottom: 5 }}
              className="normal_normal_600_10_Manropee_QC_Form_Mobile"
            >
              Mobile Number
            </label>
            <PhoneInput
              country="UK"
              defaultCountry="GB"
              placeholder="Mobile Number"
              className="inputTextField border_color normal_normal_600_12_Manropee"
              value={field.value}
              onChange={(value) => field.onChange(value)}
            />
            {error?.message?.length && (
              <FormHelperText className="red">{error?.message}</FormHelperText>
            )}
            {/* <TextField
              className="inputTextField border_color"
              // type="number"
              variant="standard"
              value={field.value}
              onChange={(value) => field.onChange(value)}
              error={error?.message.length}
              helperText={error?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {isCurrency ? "£" : ""}
                  </InputAdornment>
                ),
              }}
            /> */}
          </>
        )}
      />
    </FormControl>
  );
};
