import { NumberValidation } from "../../../../utils/util";

// Form validation for product add / update
export const formValidation = (data) => {
  const instructionTypeId =
    data?.InstructionTypeProduct[0]?.instructionTypeId ||
    data?.InstructionTypeProduct[0]?.id;
  if (
    !data?.name ||
    !instructionTypeId ||
    !data?.standardFees ||
    !data?.perVat ||
    !NumberValidation(data?.standardFees) ||
    !NumberValidation(data?.perVat) ||
    !data?.quantityForTaskOrDocuments ||
    !NumberValidation(data?.quantityForTaskOrDocuments)
  ) {
    return false;
  } else {
    return true;
  }
};
