import NorthIcon from "@mui/icons-material/North";
import SearchIcon from "@mui/icons-material/Search";
import SouthIcon from "@mui/icons-material/South";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import DataGridComponent from "../../../components/DataGridComponent";
import Loader from "../../../components/Loader";
import PaginationComponent from "../../../components/PaginationComponent";
import { getClients } from "../../../thunks/client";
import { getLookups } from "../../../thunks/lookup";
import { getMyUsers } from "../../../thunks/user";
import { PAGINATION_LIMIT, USER_ROLE } from "../../../utils/constant";
import AddClient from "./CreateClient";

const ClientList = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state?.auth?.currentUser);

  const instructionStatusList = useSelector((state) => {
    // Map instruction status with client list status filter
    let list = state?.lookupData?.["Instruction Status"];
    return list?.length
      ? list?.reduce(
          (a, obj) => {
            let newList = [...a];
            switch (obj?.name?.toLowerCase()) {
              case "cancelled":
                newList = [...a, { name: "Declined", id: obj?.id }];
                break;
              case "open":
                newList = [...a, { name: "Ongoing Instructions", id: obj?.id }];
                break;
            }
            return newList;
          },
          [{ name: "Setup a Meeting", id: 0 }]
        )
      : [];
  });
  // Method to get introducer list
  // const consultantList = useSelector(
  //   (state) =>
  //     state?.user?.consultantsData?.map((obj) => ({
  //       label: `${obj?.firstName || "NA"} ${obj?.lastName || "NA"}`,
  //       value: obj?.id,
  //       id: obj?.id,
  //     })) || []
  // );

  // This method is to fetch data into search autocomplete component.
  const getMyUsersData = async () => {
    try {
      const response = await dispatch(getMyUsers()).unwrap();
      if (response?.length > 0) {
        setMyUsers(response);
      } else {
        setMyUsers([]);
      }
    } catch (error) {}
  };

  // states
  const [leadData, setLeadData] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: PAGINATION_LIMIT,
    search: "",
    sourceTypeLookupId: null,
    instructionStatusId: null,
    orderByOnCreatedAt: "desc",
  });
  const [timer, setTimer] = useState(null);
  const [isShowClientForm, setIsShowClientForm] = useState(false);
  const [sourceType, setSourceType] = useState([]);
  const [myUsers, setMyUsers] = useState([]);

  // Client Columns
  const columns = [
    {
      field: "firstName",
      headerName: "FirstName",
      minWidth: 160,
      sortable: false,
    },
    {
      field: "middleName",
      headerName: "MiddleName",
      minWidth: 160,
      sortable: false,
    },
    {
      field: "lastName",
      headerName: "LastName",
      minWidth: 160,
      sortable: false,
    },
    {
      field: "mobileNo",
      headerName: "Contact",
      minWidth: 250,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 350,
      sortable: false,
    },
    {
      field: "leadCreator",
      headerName: "Introducer",
      minWidth: 350,
      sortable: false,
      renderCell: (params) => (
        <Typography className="normal_normal_16_Manrope dark_grey">
          {`${params?.row?.leadCreator?.firstName || ""} ${
            params?.row?.leadCreator?.lastName || ""
          }`}
        </Typography>
      ),
    },
    {
      field: "postalCode",
      headerName: "Post Code",
      minWidth: 170,
      sortable: false,
    },
    {
      field: "addedSince",
      headerName: `ADDED SINCE`,
      minWidth: 170,
      sortable: false,
      renderHeader: (params) => (
        <strong
          style={{ textTransform: "uppercase" }}
          className="normal_normal_600_12_Manrope green"
        >
          {"ADDED SINCE"}
          <span
            role="img"
            aria-label="enjoy"
            style={{ cursor: "pointer" }}
            orderByOnCreatedAt
            onClick={() => handleOnCreatedAtFilter(params)}
          >
            {filterPayload?.orderByOnCreatedAt === "desc" ? (
              <SouthIcon style={{ cursor: "pointer", height: "13px" }} />
            ) : (
              <NorthIcon style={{ cursor: "pointer", height: "13px" }} />
            )}
          </span>
        </strong>
      ),
      renderCell: (params) => {
        let timeInSeconds = Math.floor(
          new Date(params?.row?.createdAt).getTime() / 1000
        );

        const dateDiff = moment.unix(timeInSeconds, "YYYYMMDD").fromNow();
        const formattedDate = moment(timeInSeconds).format("Do MMMM, YYYY");

        return (
          <div
            className="d-flex justify-content-center"
            style={{ fontSize: "16px" }}
          >
            <Typography className="normal_normal_16_Manrope dark_grey">
              {dateDiff} <br />
            </Typography>
          </div>
        );
      },
    },
    {
      field: "sourceType",
      headerName: "SOURCE",
      minWidth: 300,
      sortable: false,
    },
    {
      field: "onGoingInstruction",
      headerName: "Ongoing Instruction",
      minWidth: 170,
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <div className="d-flex ms-3 justify-content-center text-center">
          {params?.row?.ongoingInstructionCount ? (
            params?.row?.ongoingInstructionCount
          ) : userDetails?.role === USER_ROLE?.INTRODUCER ? (
            "-"
          ) : (
            <Button
              type="button"
              fullWidth
              size="small"
              variant="contained"
              style={{
                boxShadow: "none",
              }}
              className="set_up_meeting_button"
              onClick={(e) => {
                onRowClick(params, "button", e);
              }}
            >
              Setup Meeting
            </Button>
          )}
        </div>
      ),
    },
  ];

  // DataGrid onRowClick Method

  const onRowClick = (params, type, e) => {
    e?.stopPropagation();
    if (type === "button") {
      navigate("/app/setupNewMeeting", {
        state: {
          data: params?.row || {},
        },
      });
    } else {
      navigate(`/app/client/update-client/${params?.row?.id}`);
    }
  };

  /* Methond to get source type */
  const getSourceTypeData = async () => {
    try {
      const lookupKeys = ["Source Type"];
      const response = await dispatch(
        getLookups({ searchStrings: JSON.stringify(lookupKeys) })
      );
      if (response?.payload?.[0]?.Lookup?.length > 0) {
        let data = response?.payload?.[0]?.Lookup.map((item) => ({
          ...item,
          value: item.name,
          label: item.name,
        }));
        setSourceType(data);
      }
    } catch (error) {}
  };

  /* Method to get client data */
  const getClientData = async (payload = filterPayload) => {
    try {
      setLoader(true);
      const responseData = await dispatch(getClients(payload)).unwrap();

      // for filters
      if (!instructionStatusList?.length) {
        dispatch(
          getLookups({ searchStrings: JSON.stringify(["Instruction Status"]) })
        );
      }

      if (responseData?.findAllLeadClient?.length > 0) {
        setLeadData(responseData?.findAllLeadClient);
        setTotalCount(responseData?.recordLength);
        setLoader(false);
      } else {
        setLeadData([]);
        setLoader(false);
        setTotalCount(0);
      }
    } catch (error) {
      setLeadData([]);
      setTotalCount(0);
      setLoader(false);
    }
  };

  /* handlesuccess method to get client data after client create from dialog component */
  const handlesucess = () => {
    getClientData();
  };

  /* Handlechange sourece type */
  const handleChangeSourceType = (value) => {
    if (value) {
      setFilterPayload({
        ...filterPayload,
        page: 1,
        sourceTypeLookupId: value.id,
      });
      const payload = {
        ...filterPayload,
        page: 1,
        sourceTypeLookupId: value.id,
      };
      getClientData(payload);
    } else {
      setFilterPayload({
        ...filterPayload,
        page: 1,
        sourceTypeLookupId: null,
      });
      const payload = {
        ...filterPayload,
        page: 1,
        sourceTypeLookupId: null,
      };
      delete payload.sourceTypeLookupId;
      getClientData(payload);
    }
  };

  /* handleChange filter */
  const handleChangeFilter = (key, value) => {
    setFilterPayload({
      ...filterPayload,
      page: 1,
      [key]: value,
    });
    const payload = {
      ...filterPayload,
      page: 1,
      [key]: value,
    };
    getClientData(payload);
  };

  /* handleChange Name */
  const handleChangeName = (value) => {
    setFilterPayload({ ...filterPayload, search: value, page: 1 });
    if (value?.length >= 3) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        const payload = {
          ...filterPayload,
          page: 1,
          search: value,
        };
        getClientData(payload);
      }, 500);
      setTimer(newTimer);
    }

    if (value?.length === 0) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        const payload = {
          ...filterPayload,
          page: 1,
          search: value,
        };
        getClientData(payload);
      }, 500);
      setTimer(newTimer);
    }
  };

  /* HandleChange page --> for pagination */
  const handleChangePage = (event, value) => {
    setFilterPayload({ ...filterPayload, page: value });
    const payload = {
      ...filterPayload,
      page: value,
    };
    getClientData(payload);
  };

  /* handle click for on created at filter method */
  const handleOnCreatedAtFilter = (params) => {
    let tempFilterState = null;
    if (filterPayload?.orderByOnCreatedAt === "desc") {
      tempFilterState = "asc";
    } else {
      tempFilterState = "desc";
    }
    setFilterPayload({
      ...filterPayload,
      orderByOnCreatedAt: tempFilterState,
      page: 1,
    });
    const payload = {
      ...filterPayload,
      orderByOnCreatedAt: tempFilterState,
      page: 1,
    };
    getClientData(payload);
  };

  useEffect(() => {
    getClientData();
    getSourceTypeData();
    getMyUsersData();
  }, []);

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "0px" }}>
        {/* Loader Component */}
        {isLoader && <Loader />}

        {/* Filter Container */}
        <Grid item xs={2}>
          <TextField
            fullWidth
            placeholder="Search"
            className="textField_outlined_border"
            size="small"
            value={filterPayload?.search}
            onChange={(e) => handleChangeName(e?.target?.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
                height: 38,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Source Type */}
        <Grid item xs={2}>
          <Autocomplete
            options={sourceType}
            onChange={(event, value) => handleChangeSourceType(value)}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                className="normal_normal_medium_14_Manropee textField_outlined_border"
                variant="outlined"
                size="small"
                label="Source Type"
                placeholder="Select Source Type"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                    height: 38,
                  },
                }}
              />
            )}
          />
        </Grid>

        {/* Status */}

        <Grid item xs={2}>
          <FormControl
            fullWidth
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
          >
            <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
              Status
            </InputLabel>
            <Select
              className="textField_outlined_border"
              label="Status"
              onChange={(e) =>
                handleChangeFilter("instructionStatusId", e?.target?.value)
              }
            >
              <MenuItem>--</MenuItem>
              {instructionStatusList?.length > 0 &&
                instructionStatusList.map((d) => (
                  <MenuItem
                    key={d?.id}
                    value={d?.id}
                    className="normal_normal_medium_14_Manrope dark_grey"
                  >
                    {d?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Introduced By */}

        <Grid item xs={2}>
          {/* <Autocomplete
            options={myUsers || []}
            onChange={(e, value) =>
              handleChangeFilter(
                "introducedByOrTo",
                value ? [value?.id] : undefined
              )
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                className="normal_normal_medium_14_Manropee textField_outlined_border"
                variant="outlined"
                size="small"
                label="Introduced To"
                placeholder="Select Consultant"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                    height: 38,
                  },
                }}
              />
            )}
          /> */}

          <Autocomplete
            options={myUsers || []}
            getOptionLabel={(option) =>
              `${option?.firstName || ""} ${option?.lastName || ""}`
            }
            onChange={(e, value) =>
              handleChangeFilter(
                "introducedByOrTo",
                value ? [value?.id] : undefined
              )
            }
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                className="normal_normal_medium_14_Manropee inputTextField border_color"
                size="small"
                label="Introduced By"
                placeholder="Introduced By"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: 24,
                    borderRadius: "24px",
                  },
                }}
              />
            )}
          />
        </Grid>

        {/* Create Client Button */}

        <Grid item xs={4} sx={{ display: "flex", flexDirection: "row" }}>
          <Grid class="hover-container d-flex-inline">
            <Typography class="default-content">
              <button
                size="small"
                variant="contained"
                className="normal_normal_600_16_Manrope green createClient_button"
              >
                Create a Client
              </button>
            </Typography>
            <Typography class="hover-content d-flex-inline justify-content-center align-items-center createClient_hover_button">
              {/* --------file-upload-btn----  */}
              <Button
                component="label"
                variant="contained"
                onClick={() => navigate("/app/client/import-list")}
                className="normal_normal_semibold_14_Manrope import_button"
              >
                Import List
              </Button>
              <Grid className="d-inline-flex create_client_vertical_border" />
              <Button
                component="label"
                variant="contained"
                style={{
                  boxShadow: "none",
                  backgroundColor: "#00ce3f",
                  borderRadius: "0px 24px 24px 0px",
                }}
                onClick={() => {
                  setIsShowClientForm(true);
                }}
                className="normal_normal_semibold_14_Manrope"
              >
                Add Manually
              </Button>

              {/* Add Manually client */}
              {isShowClientForm && (
                <AddClient
                  open={isShowClientForm}
                  handlesucess={handlesucess}
                  handleClose={() => setIsShowClientForm(false)}
                />
              )}
            </Typography>
          </Grid>
        </Grid>

        {/* Client Table */}

        <Grid item xs={12} style={{ width: "100%", height: "75vh" }}>
          <DataGridComponent
            columns={columns}
            rows={leadData || []}
            onRowClick={(params) => onRowClick(params, "row")}
          />
        </Grid>

        {/* PAGINATION */}
        <PaginationComponent
          totalCount={totalCount}
          limit={filterPayload?.limit}
          page={filterPayload?.page}
          onChange={handleChangePage}
        />
      </Grid>
    </>
  );
};
export default ClientList;
