import { createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS } from "../utils/constant";

//Method to get All Clients API
export const getClients = createAsyncThunk(
  "lead/getClients",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        API_ROUTES_PATHS.GET_ALL_LEAD_CLIENT,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get client by ID API
export const getClientById = createAsyncThunk(
  "clientUser/getClientById",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_CLIENT_BY_ID}/${model}/leadclient`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to create new client API
export const createClient = createAsyncThunk(
  "client/createClient",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(API_ROUTES_PATHS.CREATE_CLIENT, model);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Update existing client API
export const updateClient = createAsyncThunk(
  "client/updateClient",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_CLIENT}/${model?.id}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get client API for create meeting screen.
export const getClientsForMeeting = createAsyncThunk(
  "client/getClientsForMeeting",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_CLIENTS_FOR_MEETING}`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to create meeting API
export const createMeeting = createAsyncThunk(
  "client/createMeeting",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(API_ROUTES_PATHS.CREATE_MEETING, model);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to create multiple clients API by import excel functionality
export const createMultipleClients = createAsyncThunk(
  "client/createMultipleClients",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.IMPORT_CLIENT}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get all meetings API
export const getAllMeetings = createAsyncThunk(
  "client/getAllMeetings",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_ALL_MEETINGS}/leadclientmeetings`,
        { params: model?.query }
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to delete client API
export const deleteClient = createAsyncThunk(
  "client/deleteClient",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().delete(
        `${API_ROUTES_PATHS.DELETE_CLIENT}/${model}`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to create note for client API
export const createNote = createAsyncThunk(
  "client/createNote",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.CREATE_NOTE}/${model?.clientId}/notes`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get all note for client API --> Dashboard
export const getAllNotes = createAsyncThunk(
  "client/getAllNotes",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.GET_ALL_NOTES}/myClientsNotes`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get all note for client API --> Dashboard
export const getNotesByClientId = createAsyncThunk(
  "client/getNotesByClientId",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_NOTES_BY_CLIENT_ID}/${model}/getallnotes`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update consultant's meeting show/no-show status
export const updateMeetingShow = createAsyncThunk(
  "client/updateMeetingShow",
  async (model, thunkApi) => {
    try {
      let res = model?.isShow
        ? await getAxios().patch(
            `${API_ROUTES_PATHS.CALENDLY}/${model.meetingId}/${API_ROUTES_PATHS.MEETING_INVITEE_NO_SHOW_DELETE}`
          )
        : await getAxios().post(
            `${API_ROUTES_PATHS.CALENDLY}/${model.meetingId}/${API_ROUTES_PATHS.MEETING_INVITEE_SHOW}`
          );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
