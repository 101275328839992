import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../../components/Loader";
import { getDocumentSubType } from "../../../../thunks/documents";
import { getLookups } from "../../../../thunks/lookup";
import AddUpdateDocumentSubType from "./AddUpdateDocumentSubType";
import AddUpdateDocumentType from "./AddUpdateDocumentType";
import UpdateDocType from "../../../Instructions/components/UpdateDocType";
import UpdateDocSubType from "../../../Instructions/components/UpdateDocSubType";

const DocumentTypeList = () => {
  const dispatch = useDispatch();
  const [documentTypes, setDocumentTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [isDocumentType, setDocumentType] = useState(false);
  const [isSubType, setSubType] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isLoader, setLoader] = useState(false);
  const [openDocType, setOpenDocType] = useState(null);
  const [openDocSubType, setOpenDocSubType] = useState(null);

  const handleUpdateDocumentType = (data) => {
    setSelectedData(data?.row);
    setOpenDocType(openDocType === data?.index ? null : data?.index);
  };

  const handleUpdateDocumentSubType = (data) => {
    setSelectedData(data?.row);
    setOpenDocSubType(openDocSubType === data?.index ? null : data?.index);
  };

  const handleCloseDocumentType = () => {
    setDocumentType(false);
    setSelectedData(null);
  };
  const columns_types = [
    {
      field: "name",
      headerName: "DOCUEMNT TYPE",
      width: 300,
    },
    {
      field: "Action",
      headerName: "ACTION",
      width: 90,
      // renderCell: (params) => (
      //   <>
      //     <Tooltip title="Update Document Type">
      //       <IconButton onClick={() => handleUpdateDocumentType(params)}>
      //         <EditIcon className="01ce3f" />
      //       </IconButton>
      //     </Tooltip>
      //   </>
      // ),
    },
  ];
  const columns_sub_types = [
    {
      field: "name",
      headerName: "DOCUMENT TYPE",
      width: 300,
    },
    {
      field: "Action",
      headerName: "ACTION",
      width: 90,
      // renderCell: (params) => (
      //   <>
      //     <Tooltip title="Update Document Type">
      //       <IconButton onClick={() => handleUpdateDocumentSubType(params)}>
      //         <EditIcon className="01ce3f" />
      //       </IconButton>
      //     </Tooltip>
      //   </>
      // ),
    },
  ];

  const getDocumentTypesData = async () => {
    setLoader(true);
    try {
      const lookupKeys = ["Document Type"];
      const response = await dispatch(
        getLookups({ searchStrings: JSON.stringify(lookupKeys) })
      ).unwrap();
      if (response) {
        setDocumentTypes(response?.[0]?.Lookup || []);
        setLoader(false);
      } else {
        setDocumentTypes([]);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  const getDocumentSubTypeData = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getDocumentSubType()).unwrap();
      if (response?.length > 0) {
        setSubTypes(response);
        setLoader(false);
      } else {
        setSubTypes([]);
        setLoader(false);
      }
    } catch (error) {
      setSubTypes([]);
      setLoader(false);
      return error;
    }
  };

  useEffect(() => {
    getDocumentTypesData();
    getDocumentSubTypeData();
  }, []);

  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}
      {/* Document Type add update dialog */}
      {isDocumentType && (
        <AddUpdateDocumentType
          open={isDocumentType}
          handleClose={handleCloseDocumentType}
          // selectedData={selectedData}
          getDocumentTypesData={getDocumentTypesData}
          subTypes={subTypes}
        />
      )}
      {/* Document Sub type add update dialog */}
      {isSubType && (
        <AddUpdateDocumentSubType
          open={isSubType}
          handleClose={() => setSubType(false)}
          // selectedData={selectedData}
          getDocumentSubTypeData={getDocumentSubTypeData}
        />
      )}
      <Grid container spacing={3}>
        {/* Document Type Section */}
        <Grid item xs={6} mt={1}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              gap: 20,
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Typography className="serif_display_regular_22 green">
              Document Types
            </Typography>
            <Button
              className="add_a_new_user_button"
              onClick={() => setDocumentType(true)}
            >
              <AddCircleOutlineIcon style={{ paddingRight: 3 }} />
              Add
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ width: "100%", height: "80vh", overflow: "auto" }}
          >
            {/* <DataGridComponent
              columns={columns_types}
              rows={documentTypes || []}
              onRowClick={() => {}}
            /> */}
            {/* ----DOCUMENT TYPE ACCORDIAN----- */}
            <TableContainer>
              <Table aria-label="collapsible table" stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    {columns_types.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        className="green"
                      >
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {/* ----TABLE-BODY----- */}
                <TableBody>
                  {documentTypes?.map((row, index) => (
                    <>
                      <TableRow key={row.id}>
                        <TableCell
                          align="left"
                          style={{
                            fontSize: 17,
                          }}
                          className={openDocType === index && "green"}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            // className="add_a_new_user_button"
                            onClick={() =>
                              handleUpdateDocumentType({ row, index })
                            }
                            className={openDocType === index && "green"}
                          >
                            {/* <EditIcon /> */}
                            {openDocType === index ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          colSpan={6}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <Collapse
                            in={openDocType === index}
                            timeout="auto"
                            unmountOnExit
                          >
                            <UpdateDocType
                              selectedData={selectedData}
                              subTypes={subTypes}
                              getDocumentTypesData={getDocumentTypesData}
                              setOpenDocType={setOpenDocType}
                            />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        {/*------------------------- Document sub type section ------------------------------*/}
        <Grid item xs={6} mt={1}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              gap: 20,
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Typography className="serif_display_regular_22 green">
              Document Sub Types
            </Typography>
            <Button
              className="add_a_new_user_button"
              onClick={() => setSubType(true)}
            >
              <AddCircleOutlineIcon style={{ paddingRight: 3 }} />
              Add
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ width: "100%", height: "80vh", overflow: "auto" }}
          >
            {/* <DataGridComponent
              columns={columns_sub_types}
              rows={subTypes || []}
              onRowClick={() => {}}
            /> */}
            {/* ----DOCUMENT SUB TYPE ACCORDIAN----- */}
            <TableContainer>
              <Table
                aria-label="collapsible table"
                stickyHeader
                // aria-label="sticky-table a dense table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    {columns_sub_types.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        className="green"
                      >
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {/* ----TABLE-BODY----- */}
                <TableBody>
                  {subTypes?.map((row, index) => (
                    <>
                      <TableRow key={row.id}>
                        <TableCell
                          align="left"
                          style={{
                            fontSize: 17,
                          }}
                          className={openDocSubType === index && "green"}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            style={{
                              fontSize: 17,
                            }}
                            className={openDocSubType === index && "green"}
                            onClick={() =>
                              handleUpdateDocumentSubType({ row, index })
                            }
                          >
                            {/* <EditIcon /> */}
                            {openDocSubType === index ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          colSpan={6}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <Collapse
                            in={openDocSubType === index}
                            timeout="auto"
                            unmountOnExit
                          >
                            <UpdateDocSubType
                              selectedData={selectedData}
                              getDocumentSubTypeData={getDocumentSubTypeData}
                              setOpenDocSubType={setOpenDocSubType}
                            />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentTypeList;
