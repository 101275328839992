import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import SetupNewMeeting from "../ConsultantDashboard/components/setupNewMeeting";
import Dashboard from "./Components/dashboard";

const OfficeAdminDashboard = () => {
  const Location = useLocation();
  return (
    <>
      <div className="main-page-container">
        <Routes>
          <Route path={"/"} element={<Dashboard />}></Route>
          <Route path={"setupNewMeeting"} element={<SetupNewMeeting />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default OfficeAdminDashboard;
