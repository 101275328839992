import { createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS } from "../utils/constant";

// Method to get DIS.PRICING API
export const getDisbursementPrice = createAsyncThunk(
  "disbursement/getDisbursement",
  async (thunkApi) => {
    try {
      let res = await getAxios().get(API_ROUTES_PATHS.GET_DISBURSEMENT_PRICE);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update Disbursement Price
export const updateDisbursementPrice = createAsyncThunk(
  "disbursement/updateDisbursement",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_DISBURSEMENT_PRICE}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
