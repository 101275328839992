import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import {
  clearDocuments,
  documentData,
  removeDocument,
  updateFormData,
  uploadSuccessData,
} from "../../../slice/documents";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { getDocumentTypeById, uploadFile } from "../../../thunks/documents";
import {
  getCustomInstructionTaskAnswerHistory,
  getInstructionTaskAnswerDetails,
  getInstructionTaskAnswerHistory,
  readImage,
  updateIndividualInstructionTaskAnswer,
  updateInstructionTaskAnswer,
} from "../../../thunks/instruction";
import { NOTIFICATION_MESSAGE } from "../../../utils/constant";
import { downloadDocument } from "../../../utils/util";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UpdateTaskAnswerDialog = ({
  open,
  handleClose,
  selectedAnswer,
  selectedTask,
  instructionId,
  isIndividualTask,
  isViewOnly = false,
}) => {
  const dispatch = useDispatch();
  const documentsData = useSelector((state) => state?.document?.documentData);
  const lookups = useSelector((state) => state?.lookupData);
  let { "Document Type": documentType } = lookups || {};
  const [clientData, setClientData] = useState([]);
  const [answerHistory, setAnswerHistory] = useState([]);
  const [lpaType, setLpaType] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isError, setError] = useState(false);
  const [fileDrag, setFileDrag] = useState(false);
  const [taskAnswerData, setTaskAnswerData] = useState({
    notes: "",
    documentUrl: "",
    ans: selectedAnswer?.name || "",
    ansId: selectedAnswer?.id || 0,
    documentId: null,
    clientId: 0,
    forClient: "",
    quantityNumber: "",
    lpaType: null,
    documentTypeId: null,
  });
  const [note, setNote] = useState(null);
  const [progress, setProgress] = useState("0/0");
  const [isUploading, setUploading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [isNote, setIsNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  console.log("answerHistory", answerHistory);
  // Method to Handle change tab value
  const handleChangeTabValue = (event, newVal) => {
    setTabValue(newVal);
  };

  // Method to read image
  const handleReadImage = async (doc) => {
    try {
      const response = await dispatch(
        readImage({ query: { filename: doc } })
      ).unwrap();
    } catch (error) {
      return error;
    }
  };

  // Method to view note
  const handleViewNote = (param) => {
    setSelectedNote(param?.notes);
    setIsNote(true);
  };

  const columns = [
    {
      id: "clientName",
      label: (
        <Typography className="table_cell_heading">Client Name</Typography>
      ),
      minWidth: 400,
    },
    {
      id: "documentTypeId",
      label: (
        <Typography className="table_cell_heading">Document Type</Typography>
      ),
      minWidth: 100,
    },
    {
      id: "documentSubTypeId",
      label: (
        <Typography className="table_cell_heading">Document SubType</Typography>
      ),
      minWidth: 150,
    },
    {
      id: "fileName",
      label: (
        <Typography className="table_cell_heading">
          Original file name
        </Typography>
      ),
      maxWidth: 100,
    },
    {
      id: "modifiedFileName",
      label: <Typography className="table_cell_heading">File name</Typography>,
      maxWidth: 100,
    },
    {
      id: "action",
      label: <Typography className="table_cell_heading">Action</Typography>,
      maxWidth: 100,
    },
  ];
  const UPDATE_TASK_ANSWER_COLUMNS = [
    {
      id: "clientName",
      label: (
        <Typography className="table_cell_heading">Client Name</Typography>
      ),
      minWidth: 150,
    },
    {
      id: "documentTypeId",
      label: (
        <Typography className="table_cell_heading">Document Type</Typography>
      ),
      minWidth: 200,
    },
    {
      id: "documentSubTypeId",
      label: (
        <Typography className="table_cell_heading">Document SubType</Typography>
      ),
      minWidth: 170,
    },
    {
      id: "modifiedFileName",
      label: <Typography className="table_cell_heading">File name</Typography>,
      minWidth: 250,
    },
    {
      id: "version",
      label: <Typography className="table_cell_heading">Version</Typography>,
      minWidth: 50,
    },
    {
      id: "note",
      label: <Typography className="table_cell_heading">Note</Typography>,
      minWidth: 50,
    },
    {
      id: "action",
      label: <Typography className="table_cell_heading">Action</Typography>,
      maxWidth: 100,
    },
    {
      id: "fileName",
      label: (
        <Typography className="table_cell_heading">
          Original file name
        </Typography>
      ),
      minWidth: 250,
    },
  ];

  /* Method to get tasks answer details by passing instruction ID */
  const getTaskAnswerDetails = async () => {
    try {
      const response = await dispatch(
        getInstructionTaskAnswerDetails(instructionId)
      ).unwrap();
      if (response) {
        if (response?.length > 1) {
          setTaskAnswerData({
            ...taskAnswerData,
            forClient: "Both",
            clientId: null,
          });
        } else {
          setTaskAnswerData({
            ...taskAnswerData,
            forClient: `${response?.[0]?.firstName || ""} ${
              response?.[0]?.lastName || ""
            }`,
            clientId: response?.[0]?.id || null,
          });
        }
        setClientData(response);
      } else {
        setClientData([]);
      }
    } catch (error) {
      return error;
    }
  };

  /* Method to get instruction task answer history --> shows in table */
  const getInstructionTaskAnswerHistoryData = async () => {
    if (selectedTask?.taskType === "Custom") {
      try {
        setLoader(true);
        const response = await dispatch(
          getCustomInstructionTaskAnswerHistory(selectedTask?.id)
        ).unwrap();
        if (response) {
          setAnswerHistory(response);
          setLoader(false);
        } else {
          setAnswerHistory([]);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        return error;
      }
    } else {
      try {
        setLoader(true);
        const id = selectedTask?.isIndividualTask
          ? selectedTask?.instructionTaskId
          : selectedTask?.id;
        const response = await dispatch(
          getInstructionTaskAnswerHistory(id)
        ).unwrap();
        if (response) {
          setAnswerHistory(response);
          setLoader(false);
        } else {
          setAnswerHistory([]);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        return error;
      }
    }
  };

  /* Method to handle add file with client name, documentType, subType */
  const handleFileUpload = (e, key) => {
    dispatch(documentData(e?.target?.files));
  };

  /* Method to save tasks and answer */
  const handleSaveTaskAndAnswer = async (data) => {
    if (data?.length > 0) {
      try {
        const payload = {
          tempData: data,
          id: selectedTask?.id,
        };
        const updateMethod = selectedTask?.isIndividualTask
          ? updateIndividualInstructionTaskAnswer
          : updateInstructionTaskAnswer;
        setLoader(true);
        const response = await dispatch(updateMethod(payload)).unwrap();
        if (response) {
          setLoader(false);
          // handleCloseDialog();
          dispatch(
            showSnackbar({
              show: true,
              message: NOTIFICATION_MESSAGE?.TASK_ANSWER_UPDATE_SUCCESS,
              severity: "success",
            })
          );
          setNote("");
          dispatch(clearDocuments());
          getInstructionTaskAnswerHistoryData();
        } else {
          setNote("");
          setLoader(false);
          // handleCloseDialog();
          dispatch(clearDocuments());
          getInstructionTaskAnswerHistoryData();
        }
      } catch (error) {
        setNote("");
        setLoader(false);
        dispatch(clearDocuments());
        handleCloseDialog();
        dispatch(
          showSnackbar({
            show: true,
            message: `${error?.message}`,
            severity: "error",
          })
        );
      }
    }
  };

  // HandleChange form data
  const handleChangeFormData = async (e, index, name) => {
    // If name === documentTypeId get subType and pass to slice method
    if (name === "documentTypeId") {
      const subType = await getDocumentTypeDetails(e);
      const payload = {
        e,
        index,
        name,
        subType,
      };
      dispatch(updateFormData(payload));
    }
    // If name === clientId get client selected ID client details
    else if (name === "clientId") {
      const selectedClient = clientData?.filter((d) => d?.id === e);
      const payload = {
        e,
        index,
        name,
        selectedClient: selectedClient || "Both",
      };
      dispatch(updateFormData(payload));
    } else {
      const payload = {
        e,
        index,
        name,
      };
      dispatch(updateFormData(payload));
    }
  };

  const getDocumentTypeDetails = async (id) => {
    try {
      const response = await dispatch(getDocumentTypeById(id)).unwrap();
      if (response) {
        return response;
      }
    } catch (error) {
      return error;
    }
  };

  // Handle upload documents
  const handleUpload = async () => {
    setError(false);
    // Method to check clientName, documentType,documentSubType are present..
    const validateData = documentsData.some(
      (document) =>
        document.clientName === null ||
        document.clientId === null ||
        document.documentTypeId === null ||
        document.documentSubTypeId === null
    );
    if (validateData) {
      setError(false);
      dispatch(
        showSnackbar({
          show: true,
          message: "Required client name or document type or document subType.",
          severity: "error",
        })
      );
    } else if (!note && !documentsData?.length > 0) {
      setError(true);
    } else {
      setError(false);
      let completed = 0;
      const total = documentsData?.length;

      let uploadFileResponse = "";
      const tempData = [];
      if (documentsData?.length > 0) {
        for (let index = 0; index < documentsData.length; index++) {
          const data = documentsData[index];
          let formData = new FormData();
          formData.append("myfile", data?.fileName, data?.fileName?.name);
          // Serialize the clientBody object to JSON
          const clientBodyData = {
            clientName: data?.clientName,
            clientId: data?.clientId,
            documentTypeId: data?.documentTypeId,
            subDocumentTypeId: data?.documentSubTypeId,
            index: index,
          };
          formData.append("clientBody", JSON.stringify(clientBodyData));
          const payload = {
            formData,
            url: `/instruction/${instructionId}/uploadInstructionClientDocuments`,
          };
          try {
            setUploading(true);
            uploadFileResponse = await dispatch(uploadFile(payload)).unwrap();
            if (uploadFileResponse) {
              completed += 1;
              setProgress(`${completed}/${total}`);
              tempData.push({
                id: 0,
                notes: note,
                ans: taskAnswerData?.ans,
                ansId: taskAnswerData?.ansId,
                clientId: data?.clientId || null,
                forClient: data?.clientName || null,
                documentTypeId: data?.documentTypeId || null,
                subDocumentTypeId: data?.documentSubTypeId || null,
                fileInfo: uploadFileResponse,
              });
              dispatch(uploadSuccessData(uploadFileResponse));
              setUploading(false);
            } else {
              setUploading(false);
            }
          } catch (error) {
            setUploading(false);
            dispatch(
              showSnackbar({
                show: true,
                message: `${error?.message}`,
                severity: "error",
              })
            );
          }
        }
        await handleSaveTaskAndAnswer(tempData);
      } else {
        tempData.push({
          id: 0,
          notes: note,
        });
        await handleSaveTaskAndAnswer(tempData);
      }
      console.log("tempData...", tempData);
    }
  };

  // Method to delete document locally
  const handleDeleteDocument = (index) => {
    dispatch(removeDocument(index));
  };

  // Method to clos dialog
  const handleCloseDialog = () => {
    dispatch(clearDocuments());
    handleClose();
  };

  // // handle default drag behaviour
  const handleFileDragOver = (e) => {
    e.preventDefault();
    setFileDrag(true);
  };

  // file drag function to add files to table
  const handleFileDrop = (e) => {
    e.preventDefault();
    dispatch(documentData(e.dataTransfer.files));
    setFileDrag(false);
  };

  /* UseEffect to getTaksAnswerDetails, getInstructionTaskAnswerHistoryData */
  useEffect(() => {
    getTaskAnswerDetails();
    getInstructionTaskAnswerHistoryData();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth={"xl"}
        fullScreen
      >
        {loader && <Loader />}

        {/* TaskAnswer View Notes Dialog */}
        {isNote && (
          <Dialog
            sx={{ border: 1 }}
            open={isNote}
            onClose={() => setIsNote(false)}
            fullWidth
            maxWidth={"sm"}
          >
            <IconButton
              aria-label="close"
              onClick={() => setIsNote(false)}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogTitle>
              {" "}
              <Typography
                className="serif_display_regular_26 green"
                textAlign={"center"}
              >
                Note
              </Typography>
            </DialogTitle>

            <DialogContent dividers={true}>
              <Typography className="normal_normal_14_Manrope dark_gray">
                {selectedNote || "Note is not available"}
              </Typography>
            </DialogContent>
          </Dialog>
        )}

        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 10px",
          }}
        >
          <Grid style={{ display: "flex", gap: 10 }}>
            <Typography className="serif_display_regular_20 green">
              Task Name :-
            </Typography>
            <Typography className="normal_normal_18_Manrope dark_grey" mt={0.5}>
              {selectedTask?.name || "NA"}
            </Typography>
          </Grid>
          {/* Loading Section */}
          {isUploading && (
            <Grid item xs={8}>
              <>
                <LinearProgress color="success" style={{ width: "80%" }} />
                <Typography className="normal_normal_18_Manrope dark_grey">
                  Uploading... {progress}
                </Typography>
              </>
            </Grid>
          )}

          <IconButton aria-label="close" onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <>
            <Grid container spacing={3}>
              {/* LEFT HAND SIDE SECTION  */}
              {!isViewOnly && (
                <Grid item xs={3}>
                  {/* NOTE INPUT  */}
                  <TextField
                    label="Add Comment"
                    fullWidth
                    placeholder="Add Comment"
                    multiline
                    rows={10}
                    value={note || ""}
                    onChange={(e) => setNote(e?.target?.value)}
                    error={isError && !note}
                    helperText={
                      isError && !note && "Required note or document."
                    }
                  />
                  {/* ADD BTN  */}
                  <Grid xs={12}>
                    <Button
                      fullWidth
                      style={{
                        padding: "20px 0",
                        backgroundColor: "#E5FAEB",
                        cursor: "pointer",
                        borderRadius: "10px",
                        color: "rgb(0, 206, 63)",
                        boxShadow: "none",
                        marginTop: 10,
                      }}
                      component="label"
                      variant="contained"
                      startIcon={<FileUploadOutlinedIcon />}
                      type="file"
                      onChange={(event) => handleFileUpload(event)}
                    >
                      Add file
                      <VisuallyHiddenInput type="file" multiple />
                    </Button>
                  </Grid>

                  {/* Save Notes and Document */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{
                        boxShadow: "none",
                        backgroundColor: "rgb(0, 206, 63)",
                        borderRadius: "24px",
                        marginTop: 10,
                      }}
                      onClick={handleUpload}
                    >
                      Save & Upload
                    </Button>
                  </Grid>
                </Grid>
              )}
              {/* RIGHT HAND SIDE SCTION  */}
              <Grid
                item
                xs={!isViewOnly ? 9 : 12}
                onDragOver={handleFileDragOver}
                onDragEnter={() => setFileDrag(true)}
                onDragLeave={() => setFileDrag(false)}
                onDrop={handleFileDrop}
                backgroundColor={fileDrag ? "#f0fbff" : null}
                boxSizing={"border-box"}
              >
                {documentsData?.length > 0 && (
                  <>
                    {/* UPLOADED FILES  */}
                    <Grid item>
                      <Grid sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer
                          style={{ cursor: "pointer", maxHeight: 250 }}
                          className="instruction_dashboard_scrollbar"
                        >
                          <Table
                            stickyHeader
                            aria-label="sticky-table a dense table"
                            size="small"
                          >
                            <TableHead>
                              {/* Columns section */}
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {documentsData?.map((d, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <FormControl fullWidth>
                                      <Select
                                        className="formControlField"
                                        value={d?.clientId}
                                        variant="standard"
                                        size="small"
                                        onChange={(event) =>
                                          handleChangeFormData(
                                            event?.target?.value,
                                            index,
                                            "clientId"
                                          )
                                        }
                                      >
                                        {clientData?.map((data) => (
                                          <MenuItem
                                            key={data?.id}
                                            value={data?.id}
                                          >
                                            {`${data?.firstName} ${data?.lastName}`}
                                          </MenuItem>
                                        ))}
                                        <MenuItem
                                          value={"Both"}
                                          disabled={clientData?.length < 2}
                                        >
                                          Both
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </TableCell>

                                  <TableCell>
                                    <FormControl fullWidth>
                                      <Select
                                        className="formControlField"
                                        value={d?.documentTypeId}
                                        variant="standard"
                                        size="small"
                                        onChange={(event) =>
                                          handleChangeFormData(
                                            event?.target?.value,
                                            index,
                                            "documentTypeId"
                                          )
                                        }
                                      >
                                        {documentType?.map((data) => (
                                          <MenuItem
                                            key={data?.id}
                                            value={data?.id}
                                          >
                                            {data?.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </TableCell>

                                  <TableCell>
                                    <FormControl fullWidth>
                                      <Select
                                        className="formControlField"
                                        value={d?.documentSubTypeId}
                                        variant="standard"
                                        size="small"
                                        onChange={(event) =>
                                          handleChangeFormData(
                                            event?.target?.value,
                                            index,
                                            "documentSubTypeId"
                                          )
                                        }
                                      >
                                        {d?.documentSubType?.map((data) => (
                                          <MenuItem
                                            key={data?.id}
                                            value={data?.id}
                                          >
                                            {data?.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </TableCell>

                                  <TableCell>
                                    {d?.fileName?.name || "NA"}
                                  </TableCell>
                                  <TableCell>
                                    {d?.fileInfo?.modifiedFileName || "NA"}
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      onClick={() =>
                                        handleDeleteDocument(index)
                                      }
                                    >
                                      <DeleteIcon
                                        style={{ color: "#ff0000" }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </>
                )}
                {documentsData?.length > 0 && answerHistory?.length > 0 && (
                  <>
                    {/* DIVIDER  */}
                    <Grid item xs={12}>
                      <Divider
                        sx={{
                          border: "4px solid #01ce3f",
                          margin: "1rem",
                        }}
                      />
                    </Grid>
                  </>
                )}
                {/* HISTORY OF FILES  */}
                {answerHistory?.length > 0 && (
                  <Grid>
                    <Grid sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer
                        sx={{
                          maxHeight: !isViewOnly ? 400 : 550,
                          cursor: "pointer",
                        }}
                        className="instruction_dashboard_scrollbar"
                      >
                        <Table
                          stickyHeader
                          aria-label="sticky-table a dense table"
                          size="small"
                        >
                          <TableHead>
                            {/* Columns section */}
                            <TableRow>
                              {UPDATE_TASK_ANSWER_COLUMNS.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {answerHistory?.map((d, index) => (
                              <TableRow key={index}>
                                {/* NAME  */}
                                <TableCell>
                                  <Typography>
                                    {d?.fileInfo?.clientName ||
                                      `${
                                        d?.InstructionClient?.firstName || "NA"
                                      } ${
                                        d?.InstructionClient?.lastName || "NA"
                                      }`}
                                  </Typography>
                                </TableCell>
                                {/* TYPE  */}
                                <TableCell>
                                  <Typography>
                                    {d?.fileInfo?.documentTypeName ||
                                      d?.documentType?.name ||
                                      "NA"}
                                  </Typography>
                                </TableCell>
                                {/* SUB TYPE  */}
                                <TableCell>
                                  <Typography>
                                    {d?.fileInfo?.subdocumentTypeName || "NA"}
                                  </Typography>
                                </TableCell>
                                {/* FILE NAME  */}
                                <TableCell>
                                  <Typography
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    {d?.fileInfo?.modifiedFileName || "NA"}
                                  </Typography>
                                </TableCell>
                                {/* VERSION  */}
                                <TableCell>
                                  <Typography
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {d?.version}
                                  </Typography>
                                </TableCell>
                                {/* NOTE  */}
                                <TableCell>
                                  <IconButton onClick={() => handleViewNote(d)}>
                                    <SpeakerNotesIcon />
                                  </IconButton>
                                </TableCell>
                                {/* ACTION  */}
                                <TableCell>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                      alignItems: "center",
                                      gap: 10,
                                    }}
                                  >
                                    {d?.documentUrl ? (
                                      <VisibilityIcon
                                        color="action"
                                        onClick={() =>
                                          d?.documentUrl
                                            ? handleReadImage(d?.documentUrl)
                                            : null
                                        }
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {d?.documentUrl ? (
                                      <IconButton
                                        onClick={() =>
                                          d?.documentUrl
                                            ? downloadDocument(d, clientData)
                                            : null
                                        }
                                      >
                                        <DownloadIcon />
                                      </IconButton>
                                    ) : (
                                      "NA"
                                    )}
                                  </Grid>
                                </TableCell>

                                {/* OG FILE NAME  */}
                                <TableCell>
                                  <Typography
                                  // style={{
                                  //   display: "flex",
                                  //   justifyContent: "center",
                                  // }}
                                  >
                                    {d?.fileInfo?.fileName || "NA"}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateTaskAnswerDialog;
