import { createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS } from "../utils/constant";

// Methond to upload document API
export const uploadFile = createAsyncThunk(
  "document/uploadFile",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(model?.url, model?.formData);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get document sub types.
export const getDocumentSubType = createAsyncThunk(
  "document/getDocumentSubType",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.LOOKUP_ROOT}/lookupSubLookups`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to add document type
export const addUpdateDocumentTypeData = createAsyncThunk(
  "document/addUpdateDocumentTypeData",
  async (model, thunkApi) => {
    try {
      if (model?.isUpdate) {
        let res = await getAxios().patch(
          `${API_ROUTES_PATHS.LOOKUP_ROOT}/${model?.id}/lookup/subLookup`,
          model
        );

        const responseData = res.data;
        return responseData;
      } else {
        let res = await getAxios().post(
          `${API_ROUTES_PATHS.LOOKUP_ROOT}/${model?.id}/lookup/subLookup`,
          model
        );

        const responseData = res.data;
        return responseData;
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get document type details by ID
export const getDocumentTypeById = createAsyncThunk(
  "document/getDocumentTypeById",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.LOOKUP_ROOT}/${model}/lookup/subLookup`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to add document sub type
export const addDocumentSubTypeData = createAsyncThunk(
  "document/addDocumentSubTypeData",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.LOOKUP_ROOT}/subLookup`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update document sub type
export const updateDocumentSubType = createAsyncThunk(
  "document/updateDocumentSubType",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.LOOKUP_ROOT}/subLookup/${model.id}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get Additional Documents for address book.
export const getAdditionalDocuments = createAsyncThunk(
  "document/getAdditionalDocuments",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_ADDITIONAL_DOCUMENTS}/${model?.instructionId}/addressBookDocs/${model?.userId}`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get document details
export const getDocumentDetails = createAsyncThunk(
  "document/getDocumentSize",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.GET_DOCUMENT_DETAILS}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update document
export const UpdateDocument = createAsyncThunk(
  "document/updateDocument",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_DOC}/${model?.instructionId}`,
        model
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
