import { Tooltip, Typography } from "@mui/material";

// Plan Table columns
export const PLAN_TABLE_COLUMNS = [
  { field: "name", headerName: "Name", minWidth: 350, sortable: false },

  {
    field: "perVat",
    headerName: "Vat In %",
    minWidth: 200,
    sortable: false,
    renderCell: (params) => `${params.value}%`,
  },
  {
    field: "planProduct",
    headerName: "Products Included",
    minWidth: 500,
    sortable: false,
  },
  {
    field: "isActive",
    headerName: "Status",
    minWidth: 200,
    sortable: false,
    renderCell: (params) => (
      <Tooltip title={`${params?.row?.isActive ? "Active" : "Inactive"}`}>
        <Typography
          className="normal_normal_16_Manrope"
          style={{
            color: params?.row?.isActive ? "#00ce3f" : "#FF0000",
          }}
        >
          {String(params?.row?.isActive ? "Active" : "Inactive")}
        </Typography>
      </Tooltip>
    ),
  },
];
