import React from "react";
import { Routes, Route } from "react-router-dom";
import Tickets from "./components/Tickets";

const Support = () => {
  return (
    <>
      <div className="main-page-container">
        <Routes>
          <Route path={`/`} element={<Tickets />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default Support;
