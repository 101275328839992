import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";

import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import {
  getCustomTasks,
  getDueDateHistory,
  getGenericInstructionTasks,
  getIndividualDueDateHistory,
  getIndividualTasks,
  updateDueDate,
  updateIndividualDueDate,
} from "../../../thunks/instruction";
import { NOTIFICATION_MESSAGE, VALIDATION_MSG } from "../../../utils/constant";

const DueDateDialog = ({
  open,
  handleClose = () => {},
  selectedData = {},
  getThingsToDoData = () => {},
  isIndividual = false,
  isDashboardTask = false,
  getThingsToPickData = () => {},
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoader, setLoader] = useState(false);
  const [dueDateHistory, setDueDateHistory] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [updateDueDateData, setOpenDueDateData] = useState({
    dueDate: new Date(selectedData?.dueDate),
    note: "",
  });
  const [isError, setIsError] = useState(false);

  // Method to Handle change tab value
  const handleChangeTabValue = (event, newVal) => {
    setTabValue(newVal);
    if (newVal === 1) {
      if (isIndividual) {
        getIndividualDueDateHistoryData();
      } else {
        getDueDateHistoryData();
      }
    } else {
      return null;
    }
  };

  //   Method to handle change field
  const handleUpdateValue = (value, key) => {
    setIsError(false);
    setOpenDueDateData({ ...updateDueDateData, [key]: value });
  };

  /* Success method to update individual/custom task due date */
  const handleUpdateIndividualDueDate = async () => {
    if (!updateDueDateData?.note) {
      setIsError(true);
    } else {
      try {
        const payload = {
          id: selectedData?.id,
          dueDate: updateDueDateData?.dueDate,
          note: updateDueDateData?.note,
        };

        setLoader(true);
        const response = await dispatch(
          updateIndividualDueDate(payload)
        ).unwrap();
        if (response) {
          setLoader(false);
          dispatch(
            showSnackbar({
              show: true,
              message: NOTIFICATION_MESSAGE?.DUE_DATE_UPDATE_SUCCESS,
              severity: "success",
            })
          );
          if (isDashboardTask) {
            getThingsToDoData();
            getThingsToPickData();
          } else if (selectedData?.taskType === "Instance") {
            dispatch(getIndividualTasks(params?.id));
          } else {
            dispatch(getCustomTasks(params?.id));
          }
        }
        handleClose();
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: error?.message,
            severity: "error",
          })
        );
        handleClose();
      }
    }
  };

  /* Success method to update instruction task due date */
  const handleUpdateDueDate = async () => {
    if (!updateDueDateData?.note) {
      setIsError(true);
    } else {
      try {
        const payload = {
          id: selectedData?.id,
          dueDate: updateDueDateData?.dueDate,
          note: updateDueDateData?.note,
        };
        setLoader(true);
        const response = await dispatch(updateDueDate(payload)).unwrap();
        if (response) {
          setLoader(false);
          dispatch(
            showSnackbar({
              show: true,
              message: NOTIFICATION_MESSAGE?.DUE_DATE_UPDATE_SUCCESS,
              severity: "success",
            })
          );
          if (isDashboardTask) {
            getThingsToDoData();
            getThingsToPickData();
          } else {
            dispatch(getGenericInstructionTasks(params?.id));
          }
        }
        handleClose();
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: error?.message,
            severity: "error",
          })
        );
        handleClose();
      }
    }
  };

  // Method to get instruction due date history

  const getDueDateHistoryData = async () => {
    try {
      setLoader(true);
      const response = await dispatch(
        getDueDateHistory(selectedData?.id)
      ).unwrap();
      setDueDateHistory(response);
      setLoader(false);
    } catch (error) {
      setDueDateHistory([]);
      setLoader(false);
    }
  };

  // Method to get individual/custom task due date history

  const getIndividualDueDateHistoryData = async () => {
    try {
      setLoader(true);
      const response = await dispatch(
        getIndividualDueDateHistory(selectedData?.id)
      ).unwrap();
      setDueDateHistory(response);
      setLoader(false);
    } catch (error) {
      setDueDateHistory([]);
      setLoader(false);
    }
  };

  // Method to update due date of custom /individual / instruction task
  const handleClickUpdateDueDate = () => {
    if (isIndividual) {
      handleUpdateIndividualDueDate();
    } else {
      handleUpdateDueDate();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers={true} sx={{ marginRight: "5px" }}>
        {isLoader && <Loader />}
        <Grid item xs={12}>
          <Tabs
            fullWidth
            value={tabValue}
            onChange={handleChangeTabValue}
            aria-label="Due Date"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#00CE3F",
              },
              "& .MuiTab-root.Mui-selected": {
                color: "#00CE3F",
                fontWeight: 600,
              },
              "& .MuiTab-root": {
                color: "#3D4740",
              },
            }}
          >
            <Tab label="Update Due Date" />
            <Tab label="View History" />
          </Tabs>
        </Grid>

        {/* Add  Note Component */}
        {tabValue === 0 && (
          <>
            <Grid item xs={12} p={2}>
              <TextField
                type="text"
                label="Add Note"
                fullWidth
                placeholder="Enter a Note"
                multiline
                rows={5}
                value={updateDueDateData?.note}
                onChange={(e) => handleUpdateValue(e?.target?.value, "note")}
                error={isError && !updateDueDateData?.note}
                helperText={
                  isError && !updateDueDateData?.note && VALIDATION_MSG.REQUIRED
                }
                sx={{
                  "& label.Mui-focused": {
                    color: "#00ce3f",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#00ce3f",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#00ce3f",
                    },
                  },
                }}
              />
            </Grid>

            {/* Date Time Picker --> Due date */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    PopperProps={{
                      anchorOrigin: { vertical: "top", horizontal: "center" },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      getContentAnchorEl: null,
                    }}
                    slotProps={{
                      textField: {
                        InputLabelProps: { shrink: true },
                        readOnly: true,
                      },
                    }}
                    label="Due Date"
                    minDate={new Date()} //TO DISABLE PAST DATES
                    format="dd/MM/yyyy hh:mm a"
                    className="normal_normal_600_12_Manropee outlined_border"
                    value={updateDueDateData?.dueDate}
                    onChange={(e) => handleUpdateValue(e, "dueDate")}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "2rem 0 0 0",
                gap: "1rem",
              }}
            >
              <Button
                className="cancel_button"
                style={{ width: "130px" }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                className="save_button"
                onClick={() => handleClickUpdateDueDate()}
              >
                Save
              </Button>
            </Grid>
          </>
        )}

        {/* View Notes Component */}
        {tabValue === 1 && (
          <Grid container spacing={3} mt={2}>
            {dueDateHistory?.length > 0 &&
              dueDateHistory?.map((data, index) => (
                <Grid key={index}>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <IconButton>
                      <CalendarMonthIcon style={{ color: "#00CE3F" }} />
                    </IconButton>
                    <Typography
                      mt={1}
                      className="normal_normal_600_14_Manrope dark_grey"
                    >
                      {`Task is due by ${moment(data?.pastDueDate).format(
                        "DD/MM/yyyy"
                      )} pushed to ${moment(data?.newDueDate).format(
                        "DD/MM/yyyy"
                      )} by ${data?.createdBy?.firstName || "NA"} ${
                        data?.createdBy?.lastName || "NA"
                      } on ${moment(data?.createdAt).format(
                        "DD/MM/yyyy"
                      )} for reason: ${data?.note}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              ))}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DueDateDialog;
