import { Typography } from "@mui/material";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";

const SingleNoteContainer = ({ note }) => {
  const [expanded, setExpanded] = useState(false);
  const [text, setText] = useState(note);

  const handleExpandText = (expandValue) => {
    let noteText = expandValue ? note.slice(0, 100) : note;
    const formattedText = DOMPurify.sanitize(noteText.replace(/\n/g, "<br>"));
    setText(formattedText);
  };

  useEffect(() => {
    handleExpandText(true);
    return () => {};
  }, []);

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />

      {text?.length >= 100 ? (
        <Typography
          onClick={() => {
            handleExpandText(expanded);
            setExpanded(!expanded);
          }}
          className="normal_normal_medium_14_Manrope green"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {expanded ? "Show Less" : "Show More"}
        </Typography>
      ) : null}
    </div>
  );
};

export default SingleNoteContainer;
