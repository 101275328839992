import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../../assets/images/BUTTON_Back.svg";
import Loader from "../../../../components/Loader";
import "../../../../custom-bootstrap.scss";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { createEmailTemplate } from "../../../../thunks/email_template";
import {
  NOTIFICATION_MESSAGE,
  VALIDATION_MSG,
} from "../../../../utils/constant";
import { formValidation } from "./formValidation";
import JoditEditotRichText from "../../../../components/JoditEditotRichText";

const CreateEmailTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isError, setIsError] = useState(false);

  const [emailTemplateData, setEmailTemplateData] = useState({
    name: "",
    html: "",
    description: "",
    isActive: true,
  });

  const [isLoader, setLoader] = useState(false);

  /* Method to change form fields */
  const handleChangeFormFields = (value, key) => {
    setEmailTemplateData({ ...emailTemplateData, [key]: value });
  };

  const htmlRef = useRef(null);

  /* Method to create emailTemplate */
  const handleCreateEmailTemplate = async () => {
    // let htmlStr = htmlRef.current.getContent();
    // let payload = { ...emailTemplateData, html: htmlStr };
    let payload = { ...emailTemplateData };

    setEmailTemplateData(payload); // to update html prop

    const isValidForm = formValidation(payload);
    if (!isValidForm) {
      setIsError(true);
    } else {
      setIsError(false);

      try {
        setLoader(true);
        const response = await dispatch(createEmailTemplate(payload)).unwrap();
        if (response.id) {
          dispatch(
            showSnackbar({
              message: NOTIFICATION_MESSAGE?.EMAIL_TEMPLATE_CREATE_SUCCESS,
              severity: "success",
            })
          );
          navigate("../list");
        }
      } catch (error) {
        dispatch(
          showSnackbar({
            message: `${error?.message}`,
            severity: "error",
          })
        );
        navigate("../list");
      } finally {
        setLoader(false);
      }
    }
  };

  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}
      {/* Form Layout */}
      <Grid
        container
        spacing={2}
        rowGap={3}
        justify="flex-start"
        className="create-user instruction_dashboard_scrollbar"
        style={{
          height: "90vh",
          overflow: "auto",
        }}
        pl={5}
        pr={5}
      >
        {/* ADD NEW  Email Template Button */}
        <Grid item xs={12}>
          <Typography className="serif_display_regular_26">
            <img
              src={BackButton}
              onClick={() => navigate("../list")}
              style={{
                cursor: "pointer",
                marginRight: "10px",
              }}
            />
            Add New Email Template
          </Typography>
        </Grid>

        {/* EmailTemplate Name */}
        <Grid item xs={4} mt={0.5}>
          <TextField
            required
            className="inputTextField normal_normal_600_12_Manropee"
            label="Name"
            placeholder="Name"
            fullWidth
            size="small"
            error={isError && !emailTemplateData?.name}
            helperText={
              isError && !emailTemplateData?.name && VALIDATION_MSG.REQUIRED
            }
            value={emailTemplateData?.name}
            onChange={(e) => handleChangeFormFields(e?.target?.value, "name")}
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>

        {/* EmailTemplate Description */}
        <Grid item xs={5}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            fullWidth
            label="Description"
            placeholder="Description"
            multiline
            maxRows={4}
            value={emailTemplateData?.description}
            onChange={(e) =>
              handleChangeFormFields(e?.target?.value, "description")
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            fullWidth
            label="Subject"
            placeholder="Subject"
            multiline
            maxRows={4}
            value={emailTemplateData?.subject}
            onChange={(e) =>
              handleChangeFormFields(e?.target?.value, "subject")
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>
        {/* template editor */}
        <Grid xs={12} px={2}>
          <Typography color="#00CE3F" mb={1}>
            Content *
          </Typography>
          {/* <RichTextEditor
            initialValue={emailTemplateData?.html}
            ref={htmlRef}
            height={248}
          /> */}
          <JoditEditotRichText
            value={emailTemplateData?.html || ""}
            height={248}
            onChange={(value) => handleChangeFormFields(value, "html")}
            ref={htmlRef}
          />
          {isError && !emailTemplateData?.html && (
            <Typography color={"error"}>{VALIDATION_MSG.REQUIRED}</Typography>
          )}
        </Grid>
      </Grid>

      {/* Save EmailTemplate Button */}
      <Grid
        item
        xs={11}
        sx={{ display: "flex", justifyContent: "flex-end", pb: 2 }}
        m={1}
        className="Save_Button_Container normal_normal_600_12_Manropee"
      >
        <Button className="save_button" onClick={handleCreateEmailTemplate}>
          Save
        </Button>
      </Grid>
    </>
  );
};

export default CreateEmailTemplate;
