import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import DataGridComponent from "../../../../components/DataGridComponent";
import Loader from "../../../../components/Loader";
import PaginationComponent from "../../../../components/PaginationComponent";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { getAllTasks } from "../../../../thunks/task";
import { getUser } from "../../../../thunks/user";
import { USER_TABLE_COLUMNS } from "../../../../utils/TableColumns/UserTableColumns";
import { PAGINATION_LIMIT } from "../../../../utils/constant";
const UserTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lookups = useSelector((state) => state?.lookupData);
  let { Role: roles } = lookups || {};
  const userData = useSelector((state) => state?.user?.userData);
  const totalCount = useSelector((state) => state?.user?.totalCount);

  const [isLoader, setLoader] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: PAGINATION_LIMIT,
    rolesArray: [],
    isActive: undefined,
    search: "",
    taskSpecialization: [],
  });
  const [timer, setTimer] = useState(null);

  const getUsers = async (payload = filterPayload) => {
    try {
      setLoader(true);
      const response = await dispatch(getUser(payload)).unwrap();
      if (response) {
        setLoader(false);
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
      setLoader(false);
    }
  };

  /* Handlechange page method for pagination */
  const handleChangePage = (event, value) => {
    setFilterPayload({ ...filterPayload, page: value });
    const payload = {
      ...filterPayload,
      page: value,
    };
    getUsers(payload);
  };

  /* Handlechange role method */
  const handleChangeRoles = (e) => {
    const selectedRole = e === "clear" ? [] : [e];
    const payload = {
      ...filterPayload,
      page: 1,
      rolesArray: selectedRole,
    };
    setFilterPayload({ ...filterPayload, rolesArray: selectedRole, page: 1 });
    getUsers(payload);
  };

  /* Handlechange name method */
  const handleChangeName = (e) => {
    setFilterPayload({ ...filterPayload, search: e, page: 1 });
    const payload = {
      ...filterPayload,
      page: 1,
      search: e,
    };
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      getUsers(payload);
    }, 500);
    setTimer(newTimer);
  };

  /* Handlechange taskk specialization */
  const handleChangeTaskSpecialization = (values) => {
    const taskIdList = values.map((item) => item.id);
    const payload = {
      ...filterPayload,
      page: 1,
      taskSpecialization: taskIdList,
    };
    setFilterPayload(payload);
    getUsers(payload);
  };

  /* Method to get task data */
  const getTasksData = async () => {
    try {
      const response = await dispatch(getAllTasks()).unwrap();
      if (response?.length > 0) {
        setTasks(response);
      } else {
        setTasks([]);
      }
    } catch (error) {}
  };

  /* DataGrid onRowClick Method */
  const onRowClick = (e) => {
    navigate(`/app/manage/update-user/${e?.row?.id}`);
  };

  useEffect(() => {
    getUsers();
    getTasksData();
  }, []);

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "0px" }}>
        {/* Loader Component */}
        {isLoader && <Loader />}

        {/* Filter By Name Container */}
        <Grid item xs={3}>
          <TextField
            fullWidth
            placeholder="Search"
            size="small"
            className="textField_outlined_border"
            value={filterPayload?.search}
            onChange={(e) => handleChangeName(e?.target?.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Roles Filter Container */}
        <Grid item xs={3}>
          <FormControl
            fullWidth
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
          >
            <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
              Roles
            </InputLabel>
            <Select
              className="textField_outlined_border"
              label="Roles"
              onChange={(e) => handleChangeRoles(e?.target?.value, "roles")}
            >
              <MenuItem value={"clear"}>--</MenuItem>
              {roles?.length > 0 &&
                roles.map((d) => (
                  <MenuItem
                    key={d?.id}
                    value={d?.value}
                    className="normal_normal_medium_14_Manrope dark_grey"
                  >
                    {d?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Tasks Specialization Filter Container */}
        <Grid item xs={3}>
          <Autocomplete
            multiple
            value={userData?.TaskUser}
            id="tags-outlined"
            options={tasks || []}
            getOptionLabel={(option) => option?.name}
            onChange={(event, value) => handleChangeTaskSpecialization(value)}
            filterSelectedOptions
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className="normal_normal_medium_14_Manropee textField_outlined_border"
                variant="outlined"
                size="small"
                label="Speciality"
                placeholder="Select Speciality"
              />
            )}
          />
        </Grid>

        {/* ADD USER BUTTON */}
        <Grid item xs={3}>
          <Button
            className="add_a_new_user_button"
            onClick={() => navigate("/app/manage/create-user")}
          >
            Add a New User
          </Button>
        </Grid>

        {/* USER TABLE */}
        <Grid
          item
          xs={12}
          style={{ width: "100%", height: "75vh", overflow: "auto" }}
        >
          <DataGridComponent
            columns={USER_TABLE_COLUMNS}
            rows={userData}
            onRowClick={onRowClick}
          />
        </Grid>

        {/* PAGINATION */}
        <PaginationComponent
          totalCount={totalCount}
          limit={filterPayload?.limit}
          page={filterPayload?.page}
          onChange={handleChangePage}
        />
      </Grid>
    </>
  );
};

export default UserTable;
