import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { getStatsReport } from "../../../thunks/dashboard";
import { DATE_FORMAT } from "../../../utils/constant";

const Dashboard = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const [filterInput, setFilterInput] = useState({
    fromDate: null,
    toDate: null,
  });
  const [isLoader, setLoader] = useState(false);
  const [statsData, setStatsData] = useState(null);
  const [weekUpdatesData, setWeekUpdatesDate] = useState([]);
  /* Method to HandleChange Filter Input */
  const handleChangeFilter = (value, key) => {
    setFilterInput({
      ...filterInput,
      [key]: value,
    });
  };

  /* Method to get statis for Introducer dashboard */
  const handleClickStats = async () => {
    try {
      setLoader(true);
      const payload = {
        startDate:
          filterInput?.fromDate !== null
            ? moment(filterInput?.fromDate)?.startOf("day")?.toISOString()
            : null,
        endDate:
          filterInput?.toDate !== null
            ? moment(filterInput?.toDate)?.endOf("day")?.toISOString()
            : null,
      };
      const response = await dispatch(getStatsReport(payload)).unwrap();
      if (response) {
        setStatsData(response);
        setWeekUpdatesDate(response?.thisWeeksInstructionsInstructed || []);
        setLoader(false);
      } else {
        setStatsData(null);
        setWeekUpdatesDate([]);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  const hideShowCommission = () => {
    if (!userDetails?.isDashBoard) {
      return "none";
    }
    return "";
  };

  useState(() => {
    handleClickStats();
  }, []);
  return (
    <Grid
      container
      maxHeight={"100vh"}
      height={"100vh"}
      border={0}
      overflow={"hidden"}
    >
      {/* Loader Component */}
      {isLoader && <Loader />}
      {/* SECTION */}
      <Grid
        item
        xs={5.25}
        p={2}
        boxSizing={"border-box"}
        border={0}
        maxHeight="100%"
      >
        <Grid container spacing={2} mb={2}>
          {/* From Date */}
          <Grid item xs={4}>
            <FormControl fullWidth variant="standard">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  format={DATE_FORMAT}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "standard",
                      InputLabelProps: { shrink: true },
                      className: "inputTextField",
                    },
                  }}
                  value={filterInput?.fromDate}
                  label="From"
                  onChange={(e) => handleChangeFilter(e, "fromDate")}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                  className="normal_normal_600_12_Manropee outlined_border"
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          {/* To Date */}
          <Grid item xs={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  format={DATE_FORMAT}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "standard",
                      InputLabelProps: { shrink: true },
                      className: "inputTextField",
                    },
                  }}
                  value={filterInput?.meetingTo}
                  label="To"
                  onChange={(e) => handleChangeFilter(e, "toDate")}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                  className="normal_normal_600_12_Manropee outlined_border"
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          {/* Filter Icon */}
          <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={handleClickStats}>
              <FilterAltIcon className="green" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          rowGap={4}
          sx={{
            py: 2,
            marginTop: 5,
          }}
        >
          {/* Total Inteoduced Section */}
          <Grid xs={6} border={0}>
            <Typography className="normal_normal_600_16_Manrope">
              Total Introduced
            </Typography>
            <Typography
              className="normal_normal_34_Manrope"
              sx={{ color: "#00CE3F", letterSpacing: "0px" }}
            >
              {statsData?.countOfClients || 0}
            </Typography>
          </Grid>

          {/* Total Instructions Section */}
          <Grid xs={6} border={0}>
            <Typography className="normal_normal_600_16_Manrope">
              Total Instructions
            </Typography>
            <Typography
              className="normal_normal_34_Manrope"
              sx={{ color: "#00CE3F", letterSpacing: "0px" }}
            >
              {statsData?.countOfInstructions?.count || 0}
            </Typography>
          </Grid>

          {/* Conversion Rate Section */}
          <Grid xs={6} border={0}>
            <Typography className="normal_normal_600_16_Manrope">
              Conversion Rate
            </Typography>
            <Typography
              className="normal_normal_34_Manrope"
              sx={{ color: "#00CE3F", letterSpacing: "0px" }}
            >
              {statsData?.converionRate?.per?.toFixed(2) || 0} %
            </Typography>
          </Grid>

          {/* Total Commission Earned Section */}
          <Grid xs={6} border={0} style={{ display: hideShowCommission() }}>
            <Typography className="normal_normal_600_16_Manrope">
              Total Commission Earned
            </Typography>
            <Typography
              className="normal_normal_34_Manrope"
              sx={{ color: "#00CE3F", letterSpacing: "0px" }}
            >
              £ {statsData?.totalAmount?.sum?.toFixed(2) || 0}
            </Typography>
          </Grid>

          {/* Average Commission Section */}
          <Grid xs={6} border={0} style={{ display: hideShowCommission() }}>
            <Typography className="normal_normal_600_16_Manrope">
              Average Commission
            </Typography>
            <Typography
              className="normal_normal_34_Manrope"
              sx={{ color: "#00CE3F", letterSpacing: "0px" }}
            >
              £ {statsData?.avgAmount?.avg?.toFixed(2) || 0}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ py: 2 }}>
        <Divider
          sx={{
            border: `1px solid #00CE3F !important`,
            boxSizing: "border-box",
          }}
          orientation="vertical"
        />
      </Grid>

      {/* SECTION */}
      <Grid
        item
        xs={6.5}
        p={2}
        boxSizing={"border-box"}
        border={0}
        maxHeight="100%"
      >
        <Typography
          maxHeight="7%"
          height="7%"
          className="serif_display_regular_22"
        >
          Updates from this week
        </Typography>
        <Grid maxHeight="93%" height="93%" overflow={"auto"}>
          {weekUpdatesData?.length > 0 ? (
            weekUpdatesData?.map((item) => (
              <Grid
                container
                py={2}
                sx={{ borderBottom: 1, borderColor: "#DCDCE1" }}
              >
                <Grid xs={12}>
                  <Grid container mb={1.5}>
                    <Grid xs={8}>
                      {item?.InstructionLeadClient?.length > 0 &&
                        item?.InstructionLeadClient?.map((clientName) => {
                          return (
                            <Typography className="normal_normal_bold_18_Manrope green">
                              {clientName?.LeadClient?.firstName}{" "}
                              {clientName?.LeadClient?.lastName}
                            </Typography>
                          );
                        })}
                    </Grid>
                    <Grid xs={4} textAlign={"right"}>
                      <Typography className="normal_normal_bold_14_Manrope">
                        {item?.ownerOfInstruction?.firstName.toUpperCase() ||
                          "NA"}{" "}
                        {item?.ownerOfInstruction?.lastName.toUpperCase() ||
                          "NA"}
                      </Typography>
                    </Grid>
                  </Grid>
                  {item?.Milestone && (
                    <Typography className="normal_normal_18_Manrope">
                      Instruction is{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {item?.Milestone?.name}.
                      </span>
                    </Typography>
                  )}
                  {item?.InstructionCommissions &&
                    item?.InstructionCommissions[0]?.amount && (
                      <Typography className="normal_normal_18_Manrope">
                        <span style={{ fontWeight: "bold" }}>
                          £
                          {Number(
                            item?.InstructionCommissions[0]?.amount
                          ).toFixed(2)}
                        </span>{" "}
                        Commission is applicable for instruction.{" "}
                      </Typography>
                    )}
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} mt={2}>
              <Typography className="normal_normal_18_Manrope" align="center">
                No updates for this week.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
