import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../assets/images/BUTTON_Back.svg";
import Loader from "../../../components/Loader";
import AlertComponent from "../../../components/alert";
import { showSnackbar } from "../../../slice/snackbarSlice";
import {
  deleteClient,
  getClientById,
  updateClient,
} from "../../../thunks/client";
import {
  NOTIFICATION_MESSAGE,
  USER_ROLE,
  VALIDATION_MSG,
} from "../../../utils/constant";
import { getClientNamesFromProspectString } from "../../../utils/util";
import AddViewNote from "./addViewNote";
import { formValidation } from "./formValidation";

const UpdateClient = () => {
  // hooks
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const lookups = useSelector((state) => state?.lookupData);
  let { "Source Type": sourceType } = lookups || {};

  // states
  const [isError, setError] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNo: "",
    email: "",
    sourceTypeLookupId: null,
    sourceSubType: "",
    Instruction: [],
    postalCode: "",
    onGoingInstructions: null,
  });

  const [isLoader, setLoader] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isShowNoteDialog, setShowNoteDialog] = useState(false);
  const createdAtDate = moment(formData?.createdAt);
  const dateDiff = createdAtDate.fromNow();
  const formattedDate = createdAtDate.format("Do MMMM, YYYY");

  /* method to get client data by ID */
  const getClientDataById = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getClientById(params?.id)).unwrap();
      if (response?.id) {
        setFormData({ ...response });
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };
  // Method for update client
  const handleSave = async () => {
    const isValidForm = formValidation(formData);
    if (!isValidForm) {
      setError(true);
    } else {
      setError(false);
      try {
        setLoader(true);
        const payload = {
          id: formData.id,
          firstName: formData?.firstName?.trim(),
          middleName: formData?.middleName?.trim(),
          lastName: formData?.lastName?.trim(),
          mobileNo: formData?.mobileNo,
          email: formData?.email,
          postalCode: formData?.postalCode,
          sourceTypeLookupId: formData?.sourceTypeLookupId,
          sourceSubType: formData?.sourceSubType?.trim(),
        };
        const res = await dispatch(updateClient(payload)).unwrap();
        if (res) {
          //ADDED API  CALL FOR AUTO REFRESH ON CHANGE CLIENT DATA
          getClientDataById();
          setLoader(false);
          dispatch(
            showSnackbar({
              show: true,
              message: NOTIFICATION_MESSAGE?.SUCCESS_CLIENT_UPDATE,
              severity: "success",
            })
          );
        }
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            message: error?.message,
            severity: "error",
          })
        );
      }
    }
  };
  // method for redirect to meeting screen
  const handleClickMeeting = (data) => {
    const payload1 = {
      applicationType: data?.applicationType || null,
      sourceType: data?.sourceTypeLookupId || null,
      sourceSubType: data?.sourceSubType || [],
      instructionType: data?.instructionType || null,
      createdAt: data?.createdAt || null,
      firstName: formData?.firstName || [],
      middleName: formData?.middleName || [],
      lastName: formData?.lastName || [],
      email: formData?.email || [],
      mobileNo: formData?.mobileNo || [],
      postalCode: formData?.postalCode || [],
    };
    if (payload1) {
      navigate("/app/setupNewMeeting", { state: { data: payload1 } });
    }
  };
  // handle change update client form data
  const handleChangeFormData = (value, key) => {
    if (!key.includes("address")) {
      setFormData({ ...formData, [key]: value });
    } else {
      let tempKey = key.split(".")[1];
      setFormData({
        ...formData,
        address: { ...formData.address, [tempKey]: value },
      });
    }
  };

  /* Delete Client confirmation */
  const handleClickDeleteClient = (id) => {
    setDelete(true);
  };

  /* Delete client success */
  const handleSuccessDeleteClient = async () => {
    try {
      setDelete(false);
      setLoader(true);
      const response = await dispatch(deleteClient(params?.id)).unwrap();
      if (response) {
        setLoader(false);
        navigate("/app/client/list");
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.SUCCESS_CLIENT_DELETE,
            severity: "success",
          })
        );
      } else {
        setLoader(false);
        navigate("/app/client/list");
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.SOMETHING_WENT_WRONG,
            severity: "error",
          })
        );
      }
    } catch (error) {
      setDelete(false);
      setLoader(false);
      navigate("/app/client/list");
      dispatch(
        showSnackbar({
          message: error?.message,
          severity: "error",
        })
      );
    }
  };

  const handleNavigateToInstructionPage = (event, instructionId) => {
    event.stopPropagation();
    navigate(`/app/instructions/dashboard/${instructionId}`);
  };

  // use effect
  useEffect(() => {
    getClientDataById();
  }, []);

  return (
    <Grid
      className="instruction_dashboard_scrollbar"
      overflow={"auto"}
      height={"100vh"}
    >
      {/* Loader Component */}
      {isLoader && <Loader />}
      {/* Confirmation Dialog for delete client */}
      {/* Confirmation dialog for deactivate user */}
      {isDelete && (
        <AlertComponent
          open={isDelete}
          handleSuccess={handleSuccessDeleteClient}
          handleClose={() => setDelete(false)}
          message={"Are you sure you want to delete client?"}
        />
      )}
      {/* Generate Dynamic forms */}
      <Grid container spacing={2} py={4} px={2} boxSizing={"border-box"}>
        <Grid
          item
          xs={6}
          style={{ display: "flex", alignItems: "center", gap: 30 }}
        >
          <img
            src={BackButton}
            // onClick={() => navigate("/app/client/list")}
            onClick={() => window.history.back()}
            style={{ marginRight: "10px", cursor: "pointer" }}
          />
          <Typography className="serif_display_regular_22">
            {formData.firstName} {formData.middleName} {formData.lastName}
          </Typography>

          {/* Add Note Component */}
          {userDetails?.role !== USER_ROLE?.INTRODUCER && (
            <Tooltip title="Add Note">
              <IconButton
                onClick={() => {
                  setShowNoteDialog(true);
                }}
                size="small"
                style={{
                  backgroundColor: "#00ce3f",
                  height: 25,
                  width: 25,
                }}
              >
                <EditNoteIcon className="white" style={{ margin: 2 }} />
              </IconButton>
            </Tooltip>
          )}

          {/* Dialog to open create note */}
          {isShowNoteDialog && (
            <AddViewNote
              open={isShowNoteDialog}
              handleClose={() => setShowNoteDialog(false)}
              clientId={params?.id}
            />
          )}
        </Grid>

        <Grid
          item
          xs={6}
          style={{
            visibility: `${!formData?.Instruction?.length > 0 ? "" : "hidden"}`,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            className="deactivate_button"
            variant="contained"
            size="small"
            onClick={handleClickDeleteClient}
          >
            Delete
          </Button>
        </Grid>

        {/* First Name */}
        <Grid item xs={2.4}>
          <TextField
            value={formData?.firstName}
            error={isError && !formData?.firstName}
            helperText={
              isError && !formData?.firstName && VALIDATION_MSG.REQUIRED
            }
            required
            fullWidth
            className="inputTextField border_color normal_normal_600_12_Manropee"
            label="First Name"
            placeholder="First Name"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handleChangeFormData(e?.target?.value, "firstName")
            }
          />
        </Grid>

        {/* Middle Name */}
        <Grid item xs={2.4}>
          <TextField
            value={formData?.middleName}
            fullWidth
            className="inputTextField border_color normal_normal_600_12_Manropee"
            label="Middle Name"
            placeholder="Middle Name"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handleChangeFormData(e?.target?.value, "middleName")
            }
          />
        </Grid>

        {/* Last Name */}
        <Grid item xs={2.4}>
          <TextField
            value={formData?.lastName}
            error={isError && !formData?.lastName}
            helperText={
              isError && !formData?.lastName && VALIDATION_MSG.REQUIRED
            }
            required
            fullWidth
            className="inputTextField border_color normal_normal_600_12_Manropee"
            label="Last Name"
            placeholder="Last Name"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handleChangeFormData(e?.target?.value, "lastName")}
          />
        </Grid>

        {/* Mobile Number */}
        <Grid item xs={2.4}>
          <label
            style={{ position: "relative", bottom: 5 }}
            className="normal_normal_600_10_Manropee"
          >
            Mobile Number
          </label>
          <PhoneInput
            required
            country="UK"
            defaultCountry="GB"
            placeholder="Mobile Number"
            className="inputTextField border_color normal_normal_600_12_Manropee"
            value={formData?.mobileNo}
            onChange={(e) => handleChangeFormData(e, "mobileNo")}
          />
          {isError && !formData?.mobileNo && (
            <FormHelperText className="red">
              {VALIDATION_MSG.REQUIRED}
            </FormHelperText>
          )}
          {/* <TextField
            value={formData?.mobileNo}
            error={
              (isError && !formData?.mobileNo) ||
              (isError && !phoneNumberValidation(formData?.mobileNo))
            }
            helperText={
              (isError && !formData?.mobileNo && VALIDATION_MSG.REQUIRED) ||
              (isError &&
                !phoneNumberValidation(formData?.mobileNo) &&
                VALIDATION_MSG.VALID_MOBILE_NUMBER)
            }
            required
            fullWidth
            className="inputTextField border_color normal_normal_600_12_Manropee"
            label="Mobile Number"
            placeholder="Mobile Number"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handleChangeFormData(e?.target?.value, "mobileNo")}
          /> */}
        </Grid>

        {/* Email */}
        <Grid item xs={2.4}>
          <TextField
            value={formData?.email}
            fullWidth
            className="inputTextField border_color normal_normal_600_12_Manropee"
            label="Email"
            placeholder="Email"
            variant="standard"
            // InputProps={{
            //   readOnly: true,
            // }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handleChangeFormData(e?.target?.value, "email")}
          />
        </Grid>

        {/* Post Code */}
        <Grid item xs={2.4}>
          <TextField
            value={formData?.postalCode}
            // error={isError && !formData?.postalCode}
            // helperText={
            //   isError && !formData?.postalCode && VALIDATION_MSG.REQUIRED
            // }
            fullWidth
            className="inputTextField border_color normal_normal_600_12_Manropee"
            label="Post Code"
            placeholder="Post Code"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handleChangeFormData(e?.target?.value, "postalCode")
            }
          />
        </Grid>

        {/* Source Type */}
        <Grid item xs={2.4}>
          <FormControl
            fullWidth
            className="border_color normal_normal_600_12_Manropee"
            required
            error={isError && !formData?.sourceTypeLookupId}
          >
            <InputLabel
              className="normal_normal_600_12_Manrope"
              shrink={true}
              style={{
                lineHeight: "2.0375",
                marginLeft: "-12px",
                color: "#00ce3f",
              }}
            >
              Campaign Type
            </InputLabel>
            <Select
              className="formControlField"
              value={formData?.sourceTypeLookupId}
              label="Campaign Type"
              placeholder="Select Campaign type"
              onChange={(e) =>
                handleChangeFormData(e?.target?.value, "sourceTypeLookupId")
              }
              variant="standard"
            >
              {sourceType?.length > 0 &&
                sourceType.map((d) => (
                  <MenuItem
                    className="normal_normal_600_12_Manrope_DropDown_Menu"
                    value={d?.id}
                  >
                    {d?.name}
                  </MenuItem>
                ))}
            </Select>
            {isError && !formData?.sourceTypeLookupId && (
              <FormHelperText className="red">
                {VALIDATION_MSG.REQUIRED}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Source Sub Type */}

        <Grid item xs={2.4}>
          <TextField
            value={`${formData?.leadCreator?.firstName || "NA"} ${
              formData?.leadCreator?.lastName || "NA"
            }`}
            // error={isError && !formData?.sourceSubType}
            // helperText={
            //   isError && !formData?.sourceSubType && VALIDATION_MSG.REQUIRED
            // }
            // required
            fullWidth
            className="inputTextField border_color normal_normal_600_12_Manropee"
            label="Campaign Sub Type"
            placeholder="Campaign Sub Type"
            variant="standard"
            InputLabelProps={{ shrink: true, readOnly: true }}
            onChange={(e) =>
              handleChangeFormData(e?.target?.value, "sourceSubType")
            }
          />
        </Grid>

        {/*  Added Since */}

        <Grid
          item
          xs={2.4}
          display={"flex"}
          flexDirection={"column"}
          gap={0.75}
        >
          <Grid className="normal_normal_600_10_Manropee">Added Since</Grid>
          <Grid className="normal_normal_16_Manrope">
            {dateDiff} - {formattedDate}
          </Grid>
        </Grid>

        {/*  Ongoing Instruction */}

        <Grid
          item
          xs={2.4}
          display={"flex"}
          flexDirection={"column"}
          gap={0.75}
        >
          <Grid className="normal_normal_600_10_Manropee">
            Ongoing Instruction
          </Grid>
          <Grid className="normal_normal_16_Manrope">
            {formData?.onGoingInstructions || 0}
          </Grid>
        </Grid>
      </Grid>

      {/* Instruction Card  Component */}

      <Grid
        container
        py={2}
        px={6}
        boxSizing={"border-box"}
        sx={{
          backgroundColor: "#E5FAEB",
          height: "auto",
          minHeight: "50vh",
          width: "100%",
          display: "flex",
          gap: 3,
          margin: 0,
        }}
      >
        {formData?.Instruction?.length ? (
          formData?.Instruction?.map(
            (instructionItem, instructionItemIndex) => {
              return (
                <InstructionCard
                  key={instructionItemIndex}
                  dataObject={instructionItem}
                  handleClickMeeting={handleClickMeeting}
                  userDetails={userDetails}
                  navigateToInstructionPage={(event) =>
                    handleNavigateToInstructionPage(event, instructionItem?.id)
                  }
                />
              );
            }
          )
        ) : (
          <Grid
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            className="normal_normal_16_Manrope dark_grey"
          >
            No Instructions to display
          </Grid>
        )}
      </Grid>

      {/* Save Button */}

      <Grid item xs={11} m={2} className="Save_Button_Container">
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
          border={0}
        >
          <Button className="save_button" onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UpdateClient;

const InstructionCard = ({
  dataObject,
  handleClickMeeting,
  userDetails,
  navigateToInstructionPage,
}) => {
  let names = getClientNamesFromProspectString(dataObject?.name || "");
  const data = {
    names: names,
    applicationType: dataObject.applicationType,
    instructionType: dataObject.instructionType,
    milestone: dataObject.milestone,
    plansAndProducts:
      dataObject?.InstructionPlanSelections
        ?.InstructionPlanSelectionsAndIndividualProducts,
  };

  return (
    <Grid
      sx={{ backgroundColor: "white", cursor: "pointer" }}
      boxShadow={"1px 2px 6px #00000029"}
      borderRadius={"16px"}
      padding={2}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      minWidth={"200px"}
      onClick={
        userDetails?.role === USER_ROLE?.INTRODUCER
          ? null
          : navigateToInstructionPage
      }
    >
      <Grid display={"flex"} flexDirection={"column"} gap={3}>
        {/* Client names */}
        <Grid display={"flex"} flexDirection={"column"} gap={0.5}>
          {data.names.map((clientName, clientIndex) => {
            return (
              <Grid
                className="normal_normal_600_16_Manrope"
                sx={{
                  color: clientIndex === 1 ? "#3D4740" : "#00CE3F",
                }}
              >
                {clientName}
              </Grid>
            );
          })}
        </Grid>

        {/* Application type */}
        <Grid display={"flex"} flexDirection={"column"} gap={0.5}>
          <Grid className="normal_normal_600_12_Manrope green">
            Application type
          </Grid>{" "}
          <Grid className="normal_normal_16_Manrope">
            {data.applicationType}
          </Grid>
        </Grid>

        {/* Instruction type */}
        <Grid display={"flex"} flexDirection={"column"} gap={0.5}>
          <Grid className="normal_normal_600_12_Manrope green">
            Instruction type
          </Grid>{" "}
          <Grid className="normal_normal_16_Manrope">
            {data.instructionType}
          </Grid>
        </Grid>

        {/* milestone */}
        <Grid display={"flex"} flexDirection={"column"} gap={0.5}>
          <Grid className="normal_normal_600_12_Manrope green">Milestone</Grid>{" "}
          <Grid className="normal_normal_16_Manrope">{data.milestone}</Grid>
        </Grid>

        {/* plan & products */}
        <Grid display={"flex"} flexDirection={"column"} gap={0.5}>
          <Grid className="normal_normal_600_12_Manrope green">
            Plan & Products
          </Grid>{" "}
          <Grid className="normal_normal_16_Manrope" style={{ width: "200px" }}>
            {data?.plansAndProducts || "NA"}
          </Grid>
        </Grid>

        {/* Set Up Meeting Button */}

        {userDetails?.role === USER_ROLE?.INTRODUCER ? null : (
          <Grid
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            mt={0}
            className="normal_normal_600_12_Manrope green"
          >
            <Button
              className="set_up_meeting_button"
              onClick={(e) => {
                e.stopPropagation();
                handleClickMeeting(dataObject);
              }}
            >
              Setup meeting
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
