import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import SetupNewMeeting from "../ConsultantDashboard/components/setupNewMeeting";
import SuperAdminDashboard from "../SuperAdminDashboard/components/dashboard";

const Dashboard = () => {
  const Location = useLocation();
  return (
    <>
      <div className="main-page-container">
        <Routes>
          <Route path={`/`} element={<SuperAdminDashboard />}></Route>
          {/* <Route path={"list"} element={<DashboardScreen />}></Route> */}
          <Route path={"setupNewMeeting"} element={<SetupNewMeeting />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default Dashboard;
