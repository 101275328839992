import {
  emailValidation,
  phoneNumberValidation,
  vatNumberValidation,
} from "../../../../utils/util";

// Form Validatin for user create / Update
export const formValidation = (data) => {
  if (
    (!data?.email && !emailValidation(data?.email)) ||
    (!data?.phoneNumber && !phoneNumberValidation(data?.phoneNumber)) ||
    !data?.firstName ||
    !data?.lastName ||
    !data?.salutation ||
    !data?.role ||
    !data?.address?.country ||
    (data?.isVatVerified && !data?.vatNumber) ||
    (data?.isVatVerified &&
      data?.vatNumber &&
      !vatNumberValidation(data?.vatNumber))
  ) {
    return false;
  } else {
    return true;
  }
};
