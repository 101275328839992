import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import DataGridComponent from "../../../../components/DataGridComponent";
import Loader from "../../../../components/Loader";
import PaginationComponent from "../../../../components/PaginationComponent";
import AlertComponent from "../../../../components/alert";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { getMasterTasks, updateTask } from "../../../../thunks/task";
import AddNewTaskDialog from "./AddNewTaskDialog";
import { PAGINATION_LIMIT } from "../../../../utils/constant";

const TaskManagementList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedTask, setSelectedTask] = useState(null);
  const [isAddNewTask, setIsAddNewTask] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [filteredTaskData, setFilteredTaskData] = useState([]);
  const [isDeleteTask, setIsDeleteTask] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: PAGINATION_LIMIT,
    totalCount: 0,
  });
  // Calculate the index range for the current page
  const startIndex =
    (paginationData?.currentPage - 1) * paginationData?.itemsPerPage;
  const endIndex = startIndex + paginationData?.itemsPerPage;
  const columns = [
    {
      field: "name",
      headerName: "Task Name",
      width: 500,
    },
    {
      field: "actor",
      headerName: "Actor",
      width: 150,
    },
    {
      field: "isGeneric",
      headerName: "Is Generic?",
      width: 110,
      renderCell: (params) => (
        <Checkbox
          sx={{
            color: "#00CE3F",
            "&.Mui-checked": {
              color: "#00CE3F",
            },
          }}
          checked={params?.row?.isGeneric || false}
          disabled
        />
      ),
    },
    {
      field: "sla",
      headerName: "sla",
      width: 90,
    },
    {
      field: "slaUnitName",
      headerName: "SLA Unit",
      width: 90,
    },
    {
      field: "action",
      headerName: "Action",
      width: 110,
      renderCell: (params) => (
        <>
          <Tooltip title="Update Task">
            <IconButton onClick={() => handleUpdateTask(params?.row?.id)}>
              <EditIcon className="green" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Task">
            <IconButton
              style={{ marginLeft: "1rem" }}
              onClick={() => handleDeleteTask(params?.row?.id)}
            >
              <DeleteIcon className="red" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];
  // Method to get task
  const getTasks = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getMasterTasks()).unwrap();
      if (response?.length > 0) {
        setTaskData(response);
        setFilteredTaskData(response);
        setPaginationData({ ...paginationData, totalCount: response?.length });
        setLoader(false);
      } else {
        setTaskData([]);
        setFilteredTaskData([]);
        setPaginationData({ ...paginationData, totalCount: 0 });
        setLoader(false);
      }
    } catch (error) {
      setTaskData([]);
      setLoader(false);
      return error;
    }
  };
  // Method to update task
  const handleUpdateTask = (data) => {
    setSelectedTask(data);
    setIsAddNewTask(true);
  };
  // Method to add task
  const handleAddTask = () => {
    setSelectedTask(null);
    setIsAddNewTask(true);
  };
  // Method o search task name
  const handleChangeSearch = (value) => {
    setSearch(value);
    if (value === "") {
      setTaskData(filteredTaskData);
      setPaginationData({
        ...paginationData,
        totalCount: filteredTaskData?.length,
        currentPage: 1,
      });
    } else {
      const filteredTasks = taskData?.filter((task) =>
        task?.name?.toLowerCase().includes(value?.toLowerCase())
      );
      setTaskData(filteredTasks);
      setPaginationData({
        ...paginationData,
        totalCount: filteredTasks?.length,
        currentPage: 1,
      });
    }
  };

  /* Method to change page --> Pagination */
  const handleChangePage = (event, value) => {
    setPaginationData({ ...paginationData, currentPage: value });
  };

  // Method to delete task
  const handleDeleteTask = (id) => {
    setSelectedTask(id);
    setIsDeleteTask(true);
  };

  // Method to handle delete success task
  const handleDeleteSuccessTask = async () => {
    setIsDeleteTask(false);
    try {
      const payload = {
        id: selectedTask,
        isActive: false,
      };
      setLoader(true);
      const response = await dispatch(updateTask(payload)).unwrap();
      if (response) {
        dispatch(
          showSnackbar({
            show: true,
            message: "Successfully deleted task",
            severity: "success",
          })
        );
        setLoader(false);
        getTasks();
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
      setLoader(false);
    }
  };
  useEffect(() => {
    getTasks();
  }, []);

  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}
      {/* Dialog for Add New Task */}
      {isAddNewTask && (
        <AddNewTaskDialog
          open={isAddNewTask}
          handleClose={() => setIsAddNewTask(false)}
          selectedTask={selectedTask}
          getTasks={getTasks}
        />
      )}
      {/* Confirmation dialog for delete task */}
      {isDeleteTask && (
        <AlertComponent
          open={isDeleteTask}
          handleSuccess={handleDeleteSuccessTask}
          handleClose={() => setIsDeleteTask(false)}
          message="Are you sure you want to delete an task?"
        />
      )}
      <Grid container spacing={3} padding={2}>
        {/* Filter By Name Container */}
        <Grid
          item
          xs={4}
          style={{ display: "flex", gap: 10, alignItems: "center" }}
        >
          <TextField
            fullWidth
            placeholder="Search by task name..."
            className="textField_outlined_border"
            size="small"
            value={search}
            onChange={(e) => handleChangeSearch(e?.target?.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            className="add_a_new_user_button"
            style={{ width: 200 }}
            onClick={() => handleAddTask()}
          >
            <AddCircleOutlineIcon style={{ paddingRight: 3 }} /> Add Task
          </Button>
        </Grid>

        {/* Add New Config Button */}
        <Grid
          item
          xs={8}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            className="add_a_new_user_button"
            onClick={() => navigate("/app/manage/configure-task")}
          >
            <AddCircleOutlineIcon style={{ paddingRight: 3 }} />
            Configure a task
          </Button>
        </Grid>
        {/* Task Table */}
        <Grid
          item
          xs={12}
          style={{ width: "100%", height: "80vh", overflow: "auto" }}
        >
          <DataGridComponent
            columns={columns}
            rows={taskData?.slice(startIndex, endIndex)}
            onRowClick={() => {}}
          />
        </Grid>

        {/* PAGINATION */}
        <PaginationComponent
          totalCount={paginationData?.totalCount}
          limit={paginationData?.itemsPerPage}
          page={paginationData?.currentPage}
          onChange={handleChangePage}
        />
      </Grid>
    </>
  );
};

export default TaskManagementList;
