import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import {
  addUpdateDocumentTypeData,
  getDocumentTypeById,
} from "../../../../thunks/documents";

const AddUpdateDocumentType = ({
  open,
  handleClose,
  selectedData,
  getDocumentTypesData,
  subTypes,
}) => {
  const dispatch = useDispatch();
  const [documentType, setDocumentType] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [selectedSubType, setSelectedSubType] = useState([]);
  const lookups = useSelector((state) => state?.lookupData);
  const [isLoader, setLoader] = useState(false);
  let { "Document Type": documentTypeId } = lookups || {};

  const handleChangeSubTypes = (value) => {
    if (value) {
      setSelectedSubType(value);
    } else {
      selectedSubType([]);
    }
  };
  const handleClickCreate = async () => {
    const tempSubType = [];
    if (selectedSubType?.length > 0) {
      selectedSubType?.map((d) => {
        tempSubType.push({
          id: d?.id,
          name: d?.name,
        });
      });
    }
    const payload = {
      id: selectedData ? selectedData?.id : documentTypeId[0]?.lookuptypeid,
      name: documentType,
      subLookups: tempSubType || [],
      isActive: isActive,
      isUpdate: selectedData ? true : false,
    };
    try {
      setLoader(true);
      const response = await dispatch(
        addUpdateDocumentTypeData(payload)
      ).unwrap();
      if (response) {
        handleClose();
        setLoader(false);
        getDocumentTypesData();
        dispatch(
          showSnackbar({
            message: "Document type created successfully",
            severity: "success",
          })
        );
      } else {
        handleClose();
        setLoader(false);
      }
    } catch (error) {
      handleClose();
      setLoader(false);
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  // Method to get document type details by passing ID
  const getDocumentTypeDetails = async () => {
    try {
      const response = await dispatch(
        getDocumentTypeById(selectedData?.id)
      ).unwrap();
      if (response) {
        setDocumentType(response?.name);
        setSelectedSubType(response?.SubLookup || []);
        setIsActive(response?.isActive);
      }
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    if (selectedData) {
      getDocumentTypeDetails();
    } else {
      setDocumentType(null);
      setSelectedSubType([]);
    }
  }, [selectedData]);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
        PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* Loader Component */}
        {isLoader && <Loader />}
        <DialogContent>
          <Grid container spacing={3}>
            {/* Create Task Label Section */}
            <Grid item xs={12}>
              <Typography
                className="serif_display_regular_26 green"
                textAlign={"center"}
              >
                Document Type
              </Typography>
            </Grid>

            {/* Task Name Field Section */}
            <Grid item xs={12}>
              <TextField
                className="inputTextField normal_normal_600_12_Manropee"
                label="Document Type"
                placeholder="Document Type"
                fullWidth
                required
                size="small"
                value={documentType || null}
                onChange={(e) => setDocumentType(e?.target?.value)}
                // error={isError && !taskData?.name}
                // helperText={
                //   isError && !taskData?.name && VALIDATION_MSG.REQUIRED
                // }
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>

            {/* Add/Update subTypes */}
            <Grid item xs={12}>
              <Autocomplete
                multiple
                value={selectedSubType || []}
                id="tags-outlined"
                options={subTypes || []}
                getOptionLabel={(option) => option?.name}
                onChange={(event, value) => handleChangeSubTypes(value)}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    className="normal_normal_600_12_Manropee"
                    size="small"
                    label={
                      <Typography sx={{ color: "#01ce3f" }}>
                        Sub Types
                      </Typography>
                    }
                    placeholder="Select Sub Types"
                    // InputProps={{
                    //   ...params.InputProps,
                    //   style: {
                    //     maxHeight: "700px",
                    //     display: "flex",
                    //     flexDirection: "column",
                    //     flexWrap: "wrap",
                    //     overflowY: "auto",
                    //     marginTop: 10,
                    //     alignItems: "flex-start",
                    //   },
                    // }}
                  />
                )}
              />
            </Grid>

            {/* Is Active section */}
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#00CE3F",
                      "&.Mui-checked": {
                        color: "#00CE3F",
                      },
                    }}
                    checked={isActive || false}
                    onChange={(e) => setIsActive(e?.target?.checked)}
                  />
                }
                label="Is Active?"
              />
            </Grid>
            {/* Submit Button */}
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button className="save_button" onClick={handleClickCreate}>
                {selectedData ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddUpdateDocumentType;
