import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataGridComponent from "../../../components/DataGridComponent";
import Loader from "../../../components/Loader";
import PaginationComponent from "../../../components/PaginationComponent";
import {
  getInstructionDocumentsAsPerDocumentType,
  readImage,
} from "../../../thunks/instruction";
import { downloadDocument, getModifiedFileName } from "../../../utils/util";
import DocumentEdit from "./DocumentEdit";

const DocumentsByType = ({ open, handleClose, instructionId, clientData }) => {
  const dispatch = useDispatch();
  const lookups = useSelector((state) => state?.lookupData);
  let { "Document Type": documentType } = lookups || {};
  const [documents, setDocuments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [editDoc, setEditDoc] = useState(false);
  const [doctoEdit, setDoctoEdit] = useState({});
  const [totalCount, setTotalCount] = useState("");
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: 10,
    documentTypeId: null,
  });

  const getDocumentsByType = async (payload = filterPayload) => {
    try {
      setLoader(true);
      const response = await dispatch(
        getInstructionDocumentsAsPerDocumentType({
          instructionId,
          query: {
            page: payload?.page,
            limit: payload?.limit,
            documentTypeId: payload?.documentTypeId,
            isMiscellaneous: false,
          },
        })
      ).unwrap();
      if (response) {
        setDocuments(response?.allDocumentsOfInstruction || []);
        setTotalCount(response?.recordLength || 0);
        setLoader(false);
      } else {
        setDocuments([]);
        setLoader(false);
        setTotalCount(response?.recordLength || 0);
      }
    } catch (error) {
      setDocuments([]);
      setLoader(false);
      setTotalCount(0);
      return error;
    }
  };

  const handleChangePage = (event, value) => {
    setFilterPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
    const payload = {
      ...filterPayload,
      page: value,
    };
    getDocumentsByType(payload);
  };

  const handleChangeDocumentType = (e) => {
    setFilterPayload((prevState) => ({
      ...prevState,
      documentTypeId: e.target.value,
    }));
    const payload = {
      ...filterPayload,
      page: 1,
      documentTypeId: e.target.value,
    };
    getDocumentsByType(payload);
  };

  const handleReadImage = async (doc) => {
    try {
      const response = await dispatch(
        readImage({
          query: { filename: doc?.fileInfo?.path || doc?.documentUrl },
        })
      ).unwrap();
    } catch (error) {
      return error;
    }
  };

  const handleEditDocument = (doc) => {
    console.log("model is opening", doc);
    setEditDoc(true);
    setDoctoEdit(doc);
  };
  //METHOD TO CLOSE UPDATE DOC MODEL
  const handleModuleClose = () => {
    setEditDoc(false);
  };

  const columns = [
    {
      field: "forClient",
      headerName: "Client Name",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <Typography>
          {params?.row?.fileInfo?.clientName ||
            `${params?.row?.InstructionClient?.firstName || "NA"} ${
              params?.row?.InstructionClient?.lastName || "NA"
            }`}
        </Typography>
      ),
    },
    {
      field: "documentType",
      headerName: "Document Type",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <Typography>
          {params?.row?.fileInfo?.documentTypeName ||
            params?.row?.documentType?.name ||
            "NA"}
        </Typography>
      ),
    },
    {
      field: "documentSubTypeName",
      headerName: "Document SubType",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <Typography>
          {params?.row?.fileInfo?.subdocumentTypeName || "NA"}
        </Typography>
      ),
    },
    {
      field: "fileName",
      headerName: "File Name",
      width: 350,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography>
            {params?.row?.fileInfo?.modifiedFileName ||
              getModifiedFileName(params?.row?.documentUrl) ||
              "NA"}
          </Typography>
        );
      },
    },

    { field: "version", headerName: "Version", width: 80, sortable: false },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {params?.row?.documentUrl ? (
            <IconButton onClick={() => handleEditDocument(params?.row)}>
              <EditIcon />
            </IconButton>
          ) : (
            "NA"
          )}
          {params?.row?.documentUrl ? (
            <IconButton>
              <VisibilityIcon
                color="action"
                onClick={() => handleReadImage(params?.row)}
              />
            </IconButton>
          ) : (
            ""
          )}
          {params?.row?.documentUrl ? (
            <IconButton
              onClick={() =>
                params?.row?.documentUrl
                  ? downloadDocument(params?.row, clientData)
                  : null
              }
            >
              <DownloadIcon />
            </IconButton>
          ) : (
            "NA"
          )}
        </Grid>
      ),
    },
  ];

  useEffect(() => {
    getDocumentsByType();
  }, []);
  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
        {loader && <Loader />}
        {/* DIALOGUE FOR DOCS EDIT */}
        {editDoc && (
          <DocumentEdit
            open={editDoc}
            handleClose={() => handleModuleClose()}
            documentType={documentType}
            doc={doctoEdit}
            clientData={clientData}
            getDocumentsByType={() => getDocumentsByType()}
          />
        )}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Typography className="serif_display_regular_26 green">
            Documents
          </Typography>
        </DialogTitle>

        {/* Filter container */}
        <Grid container spacing={3} p={2}>
          <Grid item xs={4}>
            <FormControl
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "30px",
                },
              }}
            >
              <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                Document Type
              </InputLabel>
              <Select
                className="textField_outlined_border"
                label="Document Type"
                onChange={(e) => handleChangeDocumentType(e)}
                value={filterPayload.documentTypeId || ""}
              >
                <MenuItem
                  value={null}
                  className="normal_normal_medium_14_Manrope dark_grey"
                >
                  --
                </MenuItem>
                {documentType?.length > 0 &&
                  documentType.map((d) => (
                    <MenuItem
                      key={d?.id}
                      value={d?.id}
                      className="normal_normal_medium_14_Manrope dark_grey"
                    >
                      {d?.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Table pagination container */}
          <Grid item xs={8}>
            <PaginationComponent
              totalCount={totalCount}
              limit={filterPayload?.limit}
              page={filterPayload?.page}
              onChange={handleChangePage}
            />
          </Grid>

          {/* Table data section */}
          <Grid
            item
            xs={12}
            style={{ width: "100%", height: "75vh", overflow: "auto" }}
          >
            <DataGridComponent
              columns={columns}
              rows={documents || []}
              onRowClick={() => {}}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default DocumentsByType;
