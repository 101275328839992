import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ThemeContextProvider } from "../contexts/theme";
import I18n from "../locales";
import ForgotPassword from "../pages/forgotPassword/ForgotPassword";
import Login from "../pages/login/Login";
import ResetPassword from "../pages/resetPassword/ResetPassword";
import VerifyOtp from "../pages/verifyOtp/VerifyOtp";
import Store from "../store";
import MainApp from "./mainapp";
// eslint-disable-next-line no-unused-vars
const I = I18n;
const RootContainer = (props) => {
  const location = useLocation();

  // Method to check JWT token
  const isAuthStatus = () => {
    let userToken = Cookies.get("jwt-token") || "";
    if (userToken) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    isAuthStatus();
  }, [location]);

  return (
    <Provider store={Store}>
      <ThemeContextProvider Theme={"light"}>
        <Routes>
          <Route
            path="/app/*"
            element={
              isAuthStatus() ? <MainApp /> : <Navigate replace to="/login" />
            }
          />
          <Route
            path="/"
            element={
              isAuthStatus() ? (
                <Navigate replace to="/app" />
              ) : (
                <Navigate replace to="/login" />
              )
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-password/verify-otp" element={<VerifyOtp />} />
          <Route
            path="/forgot-password/verify-otp/reset-password"
            element={<ResetPassword />}
          />
          <Route path="*" element={<h1>Page Not Found!</h1>} />
        </Routes>
      </ThemeContextProvider>
    </Provider>
  );
};

export default RootContainer;
