import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { xeroPay } from "../../../thunks/auth";

const XeroProcessingDialog = ({ open, handleClose, accessToken, tenantid }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);
  const generateXeroInvoice = async () => {
    const userId = localStorage.getItem("user_id_path");
    // If condition to update consultants on XERO and else is to update xero payments
    if (userId) {
      // Get Xero tenant ID + Xero Access Token
      const urlParams = new URLSearchParams(window.location.search);
      const accessToken = urlParams.get("accessToken");
      const tenantid = urlParams.get("tenantId");
      // Set Access Token and Tenant ID to local Storage --> to get list of Xero Consultants
      localStorage.setItem("xero_user_token", accessToken);
      localStorage.setItem("xero_user_tenant_id", tenantid);
      if (accessToken && tenantid) {
        navigate(`/app/manage/update-user/${userId}`);
      }
    } else {
      const id = localStorage.getItem("tempXeroInstructionId");
      const instructionCommissionId = localStorage.getItem(
        "instructionCommissionId"
      );
      setLoader(true);
      const payload = {
        id: id,
        access_token: accessToken,
        tenantId: tenantid,
        party: localStorage.getItem("xeroParty") || "",
        instructionCommissionId: Number(instructionCommissionId),
      };
      try {
        const response = await dispatch(xeroPay(payload)).unwrap();
        if (response) {
          setLoader(true);
          localStorage.setItem("xeroParty", "");
          localStorage.setItem("tempXeroInstructionId", "");
          setLoader(false);
          dispatch(
            showSnackbar({
              message: `${response?.message ? response?.message : response}`,
              severity: "success",
            })
          );
          handleClose();
        }
      } catch (error) {
        dispatch(
          showSnackbar({
            message: `${error?.message}`,
            severity: "error",
          })
        );
        localStorage.setItem("xeroParty", "");
        setLoader(false);
        handleClose();
      }
    }
  };
  useEffect(() => {
    generateXeroInvoice();
  }, []);
  return (
    <>
      <Dialog
        sx={{ border: 1 }}
        open={open}
        fullWidth
        maxWidth={"xs"}
        PaperProps={{
          sx: {
            borderRadius: "28px",
            paddingBottom: 0,
            height: "auto",
            width: "80%",
          },
        }}
      >
        {/* Loader component */}
        {isLoader && <Loader />}
        <DialogTitle>
          {" "}
          <Typography
            className="serif_display_regular_26 green"
            textAlign={"center"}
          >
            Xero Payment Processing...
          </Typography>
        </DialogTitle>

        <DialogContent dividers={true}>
          <>
            <Grid
              container
              spacing={3}
              pb={3}
              sx={{ display: "flex", justifyContent: "center", border: 0 }}
            >
              <Grid item xs={12}>
                <Typography className="">Xero Payment Processing...</Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className="normal_normal_medium_14_Manrope"
                  variant="contained"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#00ce3f",
                    borderRadius: "24px",
                  }}
                  onClick={handleClose}
                >
                  Ok
                </Button>
              </Grid>
            </Grid>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default XeroProcessingDialog;
