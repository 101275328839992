import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Loader from "../../../components/Loader";
import PaginationComponent from "../../../components/PaginationComponent";
import { getCurrentWeekInstruction } from "../../../thunks/dashboard";
import { PAGINATION_LIMIT } from "../../../utils/constant";
const CurrentWeeksInstruction = () => {
  //   // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   // states
  const [isLoader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: PAGINATION_LIMIT,
  });
  const [instructions, setInstructions] = useState([]);

  /* Method to get Current week's Instruction */
  const getCurrentWeeksInstructionData = async (payload = filterPayload) => {
    setLoader(true);
    try {
      const response = await dispatch(
        getCurrentWeekInstruction(payload)
      ).unwrap();
      if (response) {
        setInstructions(response?.thisWeeksInstructions);
        setTotalCount(response?.thisWeeksInstructionCount);
        setLoader(false);
      } else {
        setInstructions([]);
        setTotalCount(0);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  //   /* Method to change page --> Pagination */
  const handleChangePage = (event, value) => {
    setFilterPayload({ ...filterPayload, page: value });
    const payload = {
      ...filterPayload,
      page: value,
    };
    getCurrentWeeksInstructionData(payload);
  };

  // use effect
  useEffect((d) => {
    getCurrentWeeksInstructionData();
  }, []);

  //   console.log("testing...", thingsToDoData);
  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}
      <Grid
        maxHeight={"90vh"}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        pb={"15px"}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography className="serif_display_regular_22 dark_grey">
              New Instructions
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {/* PAGINATION */}
            <PaginationComponent
              totalCount={totalCount}
              limit={filterPayload?.limit}
              page={filterPayload?.page}
              onChange={handleChangePage}
              siblingCount={0}
              boundaryCount={1}
            />
          </Grid>
        </Grid>

        {/* Things to do List section */}
        {/* Things to do List section */}
        <Grid
          display={"flex"}
          flexDirection={"column"}
          overflow={"auto"}
          className="dashboard-scrollbar"
          gap={1}
          px={2}
          py={1}
        >
          {/* Instruction Task Type === Individual */}
          {instructions?.length > 0 &&
            instructions?.map((data) => (
              <>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  flexDirection={"row"}
                  style={{
                    padding: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(`/app/instructions/dashboard/${data?.id}`)
                  }
                >
                  <Grid item xs={5}>
                    {data?.InstructionLeadClient?.length > 0 &&
                      data?.InstructionLeadClient?.map((client) => {
                        return (
                          <Typography className="normal_normal_600_16_Manrope green">
                            {`${client?.LeadClient?.firstName} ${client?.LeadClient?.lastName}`}
                          </Typography>
                        );
                      })}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: "12px",
                    }}
                  >
                    <Typography className="normal_normal_bold_14_Manrope dark_grey">
                      {data?.milestone?.toUpperCase() || "NA"}
                    </Typography>
                    <Typography className="normal_normal_14_Manrope dark_grey">
                      {data?.name || "NA"}
                    </Typography>
                    {data?.InstructionsIndividualTasks?.length > 0 &&
                      data?.InstructionsIndividualTasks?.map((task) => (
                        <>
                          <Typography className="normal_normal_14_Manrope dark_grey">
                            {task?.name || "NA"}
                          </Typography>
                        </>
                      ))}
                  </Grid>
                </Grid>
                <Divider sx={{ border: `1px solid #DCDCE1` }} />
              </>
            ))}
        </Grid>
      </Grid>
    </>
  );
};

export default CurrentWeeksInstruction;
