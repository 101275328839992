import { Editor } from "@tinymce/tinymce-react";
import React, { forwardRef, useEffect, useRef, useState } from "react";

const textEditorKey = process.env.REACT_APP_TEXT_EDITOR_KEY;

const RichTextEditor = forwardRef((props, editorRef) => {
  const { userDetails, initialValue } = props;
  // file picker

  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  // sync files from parents
  useEffect(() => {
    if (props?.files) setSelectedFiles(props?.files);

    return () => {
      setSelectedFiles([]);
    };
  }, [props?.files]);

  const handleFileChange = (event) => {
    let files = [...selectedFiles];
    let uploadedFiles = event.target.files;
    if (uploadedFiles.length) {
      for (let i = 0; i < uploadedFiles.length; i++) {
        files.push(uploadedFiles[i]);
      }
    }
    if (props?.filesHandler) {
      props?.filesHandler(files);
    }
  };

  const customFilePickerButton = {
    icon: "browse",
    tooltip: "File Attachment",
    onAction: () => {
      fileInputRef.current.click();
    },
  };

  return (
    <>
      <Editor
        apiKey={textEditorKey}
        id={"email-composer"}
        onInit={(evt, editor) => {
          if (props?.filesHandler)
            editor.ui.registry.addButton("InsertFile", customFilePickerButton);
          return (editorRef.current = editor);
        }}
        initialValue={initialValue}
        init={{
          height: props?.height || 410,
          menubar: false,
          link_context_toolbar: true,
          // Plugines to show on rich text component ( link, image, anchor)
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "insertdatetime",
            "media",
            "table",
            "wordcount",
          ],
          // Options to show on bottom toolbar
          toolbar:
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | undo redo " +
            (props?.toolbarOptions ? props?.toolbarOptions : ""),
          statusbar: false,
        }}
      />
      {props?.filesHandler && (
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          multiple
          style={{ display: "none" }}
        />
      )}
    </>
  );
});
export default RichTextEditor;
