import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../../components/Loader";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { addCampaignData, xeroOptionSet } from "../../../../thunks/lookup";
import { NOTIFICATION_MESSAGE } from "../../../../utils/constant";

const CreateCampaign = ({ open, handleClose, lookupId, handleSuccess }) => {
  const dispatch = useDispatch();
  const [isLoader, setLoader] = useState(false);
  const [clientTypeLoader, setClientTypeLoader] = useState(false);
  const [clientType, setClientType] = useState([]);
  const [campaignData, setCampaignData] = useState({
    name: null,
    isCommissionApplicable: false,
    lookupObj: null,
  });

  const handleChangeFormFields = (value, key) => {
    setCampaignData({ ...campaignData, [key]: value });
  };

  const handleUpdateXeroClientType = async () => {
    try {
      setClientTypeLoader(true);
      const response = await dispatch(
        xeroOptionSet({
          query: {
            optionSet: "Client Type",
          },
        })
      ).unwrap();
      if (response?.length > 0) {
        setClientType(response);
        setClientTypeLoader(false);
      } else {
        setClientType([]);
        setClientTypeLoader(false);
      }
    } catch (error) {
      setClientTypeLoader(false);
      return error;
    }
  };

  const handleCreateCampaign = async () => {
    try {
      setLoader(true);
      const payload = {
        id: lookupId,
        name: campaignData?.name,
        isCommissionApplicable: campaignData?.isCommissionApplicable
          ? {
              isPermitToAutoApplyCommissions: true,
            }
          : null,
        lookupObj: campaignData?.lookupObj,
      };
      const response = await dispatch(addCampaignData(payload)).unwrap();
      if (response) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.CAMPAIGN_CREATE_SUCCESS,
            severity: "success",
          })
        );
        handleClose();
        handleSuccess();
      } else {
        setLoader(false);
        handleClose();
      }
    } catch (error) {
      handleClose();
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* Loader Component */}
        {isLoader && <Loader />}
        <DialogContent>
          <Grid container spacing={3}>
            {/* Create Task Label Section */}
            <Grid item xs={12}>
              <Typography
                className="serif_display_regular_26 green"
                textAlign={"center"}
              >
                Campaign
              </Typography>
            </Grid>

            {/* Campaign Name Field Section */}
            <Grid item xs={6}>
              <TextField
                className="inputTextField normal_normal_600_12_Manropee"
                label="Campaign Name"
                placeholder="Campaign Name"
                fullWidth
                required
                size="small"
                value={campaignData?.name || null}
                onChange={(e) =>
                  handleChangeFormFields(e?.target?.value, "name")
                }
                // error={isError && !taskData?.name}
                // helperText={
                //   isError && !taskData?.name && VALIDATION_MSG.REQUIRED
                // }
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>

            {/* Is Commission Applicable? section */}
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#00CE3F",
                      "&.Mui-checked": {
                        color: "#00CE3F",
                      },
                    }}
                    checked={campaignData?.isCommissionApplicable || false}
                    onChange={(e) =>
                      handleChangeFormFields(
                        e?.target?.checked,
                        "isCommissionApplicable"
                      )
                    }
                  />
                }
                label="Enable Auto Commission Relase?"
              />
            </Grid>

            {/* Xero client type mapping section */}
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                onOpen={() => handleUpdateXeroClientType()}
                options={clientType || []}
                getOptionLabel={(option) => option?.Name}
                onChange={(event, value) =>
                  handleChangeFormFields(value, "lookupObj")
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="normal_normal_600_12_Manropee"
                    size="small"
                    label="Xero Client Type"
                    placeholder="Xero Client Type"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {clientTypeLoader ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {/* Submit Button */}
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button className="save_button" onClick={handleCreateCampaign}>
                Create
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateCampaign;
