import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import DisPrice from "./components/DisPrice/DisPrice";
import EmailTemplate from "./components/EmailTemplate";
import CreatePlan from "./components/Plans/CreatePlan";
import UpdatePlan from "./components/Plans/UpdatePlan";
import PlanTable from "./components/Plans/planList";
import CreateProduct from "./components/Products/CreateProduct";
import ProductTable from "./components/Products/productList";
import UpdateProduct from "./components/Products/updateProduct";
// import ConfigureTask from "./components/TaskManagement/ConfigureTask";
import CampaignList from "./components/Campaign/list";
import DocumentTypeList from "./components/Document/DocumentTypeList";
import TaskTable from "./components/Tasks/TaskList";
import ConfigureTask from "./components/Tasks/TaskManagement";
import CreateUser from "./components/Users/createUser";
import UpdateUser from "./components/Users/updateUser";
import UserTable from "./components/Users/userList";
import Company from "./components/Company/Company";

const Manage = () => {
  const Location = useLocation();
  return (
    <>
      <div className="main-page-container">
        <Routes>
          <Route
            path={`/`}
            element={<Navigate replace="/" to={`${Location.pathname}/list`} />}
          ></Route>

          {/* USER */}
          <Route path={"users/list"} element={<UserTable />}></Route>
          <Route path={"create-user"} element={<CreateUser />}></Route>
          <Route path={"update-user/:id"} element={<UpdateUser />}></Route>

          {/* PRODUCT */}
          <Route path={"products/list"} element={<ProductTable />}></Route>
          <Route path={"create-product"} element={<CreateProduct />}></Route>
          <Route
            path={"update-product/:id"}
            element={<UpdateProduct />}
          ></Route>

          {/* PLAN */}
          <Route path={"plan/list"} element={<PlanTable />}></Route>
          <Route path={"create-plan"} element={<CreatePlan />}></Route>
          <Route path={"update-plan/:id"} element={<UpdatePlan />}></Route>

          {/* email */}
          <Route path={"email/*"} element={<EmailTemplate />}></Route>
          <Route path={"disbursementprice"} element={<DisPrice />}></Route>

          {/* Task Management */}
          <Route path={"task/list"} element={<TaskTable />}></Route>
          <Route path={"configure-task"} element={<ConfigureTask />}></Route>
          <Route path={"edit-task/"} element={<ConfigureTask />}></Route>

          {/* Document Management */}
          <Route path={"document/list"} element={<DocumentTypeList />}></Route>

          {/* Campaign Management */}
          <Route path={"campaign/list"} element={<CampaignList />}></Route>

          {/* Company Management */}
          <Route path={"company"} element={<Company />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default Manage;
