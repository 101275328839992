import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import {
  Autocomplete,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import CircleIcon from "@mui/icons-material/Circle";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { useNavigate } from "react-router";
import DataGridComponent from "../../../components/DataGridComponent";
import Loader from "../../../components/Loader";
import PaginationComponent from "../../../components/PaginationComponent";
import { showSnackbar } from "../../../slice/snackbarSlice";
import {
  getAllInstructions,
  getInstructionType,
} from "../../../thunks/instruction";
import { getLookups, getMilestone } from "../../../thunks/lookup";
import { getConsultant } from "../../../thunks/user";
import { PAGINATION_LIMIT, USER_ROLE } from "../../../utils/constant";
import XeroProcessingDialog from "./XeroPaymentProcessing";

const InstructionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get("accessToken");
  const tenantid = urlParams.get("tenantId");

  const milestone = useSelector((state) => state?.lookupData?.milestoneData);
  const instructionTypes = useSelector(
    (state) => state?.instruction?.instructionTypeData
  );
  const userData = useSelector((state) => state?.auth?.currentUser);
  const state = useSelector((state) => state);

  const [timer, setTimer] = useState(null);
  const [isLoader, setLoader] = useState(false);
  const [instructionsData, setInstructionsData] = useState([]);
  const [filteredInstructionData, setFilteredInstructionData] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: PAGINATION_LIMIT,
    search: "",
    type: "",
    milestones: "",
    statusId: 454,
    consultantId: null,
    orderByOnCreatedAt: "desc",
  });
  const [isXeroPaymentDialog, setXeroPaymentDialog] = useState(false);

  const columns = [
    {
      field: "clients",
      headerName: "Clients",
      minWidth: 250,
      sortable: false,
      renderCell: (params) => {
        return (
          <ul
            style={{
              marginTop: "1rem",
              listStyleType: "none",
              marginLeft: "-1rem",
            }}
          >
            {params?.row?.clients?.map((client, index) => (
              <li
                role="button"
                key={index}
                // onClick={() =>
                //   navigate(`/app/client/update-client/${client?.id}`, {
                //     state: { clientData: client },
                //   })
                // }
              >
                {params?.row?.isMigrated && (
                  <CircleIcon
                    style={{ fontSize: "8px", marginRight: "0.2rem" }}
                    className="red"
                  />
                )}
                {client.fullName}
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      field: "InstructionPlanSelectionsAndIndividualProducts",
      headerName: "Plans & Products",
      minWidth: 350,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      sortable: false,
    },
    {
      field: "introducers",
      headerName: "Introducer",
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center">
            {params?.row?.introducers?.length > 0 ? (
              <Typography className="normal_normal_16_Manrope dark_grey">
                {params?.row?.introducers[0]?.fullName}
              </Typography>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "ownerOfInstruction",
      headerName: "Consultant",
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center">
            <Typography className="normal_normal_16_Manrope dark_grey">{`${params?.row?.ownerOfInstruction?.firstName} ${params?.row?.ownerOfInstruction?.lastName}`}</Typography>
          </div>
        );
      },
    },
    // {
    //   field: "instructionType",
    //   headerName: "Type",
    //   minWidth: 190,
    //   sortable: false,
    // },
    {
      field: "createdAt",
      sortable: false,
      headerName: "Created On",
      minWidth: 300,
      renderHeader: (params) => (
        <strong
          style={{ textTransform: "uppercase" }}
          className="normal_normal_600_12_Manrope green"
        >
          {"Created On"}
          <span
            role="img"
            aria-label="enjoy"
            style={{ cursor: "pointer" }}
            onClick={() => handleCreatedOnFilter(params)}
          >
            {filterPayload?.orderByOnCreatedAt === "desc" ? (
              <SouthIcon style={{ cursor: "pointer", height: "13px" }} />
            ) : (
              <NorthIcon style={{ cursor: "pointer", height: "13px" }} />
            )}
          </span>
        </strong>
      ),
      renderCell: (params) => {
        const createdDate = moment(new Date(params?.row?.createdAt)).format(
          "DD/MM/YYYY"
        );

        return (
          <div className="d-flex justify-content-center">
            <Typography className="normal_normal_16_Manrope dark_grey">
              {createdDate}
            </Typography>
          </div>
        );
      },
    },
  ];

  const handleCreatedOnFilter = (params) => {
    let tempFilterState = null;
    if (filterPayload?.orderByOnCreatedAt === "desc") {
      tempFilterState = "asc";
    } else {
      tempFilterState = "desc";
    }
    setFilterPayload({
      ...filterPayload,
      orderByOnCreatedAt: tempFilterState,
      page: 1,
    });
    const payload = {
      ...filterPayload,
      orderByOnCreatedAt: tempFilterState,
      page: 1,
    };
    getInstructionsData(payload);
  };

  // Functionality for Pagination
  const handleChangePage = (event, value) => {
    setFilterPayload({ ...filterPayload, page: value });
    const payload = {
      ...filterPayload,
      page: value,
    };
    getInstructionsData(payload);
  };

  // Functionality for  filter Instruction type
  const handleChangeInstructionType = (value, name) => {
    const selectedRole = value === "" ? "" : value;

    setFilterPayload({
      ...filterPayload,
      type: value,
      page: 1,
    });

    const payload = {
      ...filterPayload,
      type: selectedRole,
      page: 1,
    };

    getInstructionsData(payload);
  };

  // Functionality for filter milestone
  const handleChangeMilestone = (value, name) => {
    const selectedRole = value === "" ? "" : value;

    setFilterPayload({
      ...filterPayload,
      milestones: value,
      page: 1,
    });

    const payload = {
      ...filterPayload,
      milestones: selectedRole,
      page: 1,
    };
    getInstructionsData(payload);
  };
  const handleChangeFilter = (value, name) => {
    setFilterPayload({
      ...filterPayload,
      page: 1,
      [name]: value,
    });

    const payload = {
      ...filterPayload,
      page: 1,
      [name]: value,
    };
    getInstructionsData(payload);
  };

  // Functionality for filter name
  const handleChangeName = (value) => {
    setFilterPayload({ ...filterPayload, search: value, page: 1 });

    if (value?.length >= 3) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        const payload = {
          ...filterPayload,
          page: 1,
          search: value,
        };
        getInstructionsData(payload);
      }, 500);
      setTimer(newTimer);
    }
    if (value?.length === 0) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        const payload = {
          ...filterPayload,
          page: 1,
          search: value,
        };
        getInstructionsData(payload);
      }, 500);
      setTimer(newTimer);
    }
  };

  const getInstructionTypeData = async () => {
    try {
      const response = await dispatch(getInstructionType()).unwrap();
    } catch (error) {}
  };

  const instructionStatusList = useSelector(
    (state) => state?.lookupData?.["Instruction Status"]
  );

  const getInstructionStatus = async () => {
    try {
      if (!instructionStatusList?.length) {
        await dispatch(
          getLookups({ searchStrings: JSON.stringify(["Instruction Status"]) })
        );
      }
    } catch (error) {}
  };
  const getMilestoneType = async () => {
    try {
      const response = await dispatch(getMilestone()).unwrap();
    } catch (error) {}
  };
  let consultantList = useSelector((state) =>
    state?.user?.consultantsData?.map((obj) => ({
      ...obj,
      label: `${obj?.firstName || "NA"} ${obj?.lastName || "NA"}`,
    }))
  );

  const getInstructionsData = async (payload = filterPayload) => {
    try {
      setLoader(true);
      const responseData = await dispatch(getAllInstructions(payload)).unwrap();
      setInstructionsData(responseData?.findAllInstructions || []);
      setFilteredInstructionData(responseData?.findAllInstructions || []);
      setTotalCount(responseData?.recordLength);

      if (!consultantList?.length) dispatch(getConsultant({}));

      setLoader(false);
    } catch (error) {
      setFilteredInstructionData([]);
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
      setLoader(false);
    }
  };
  // ------------------------XERO LOGIN-------------------

  const handleCloseXeroDialog = () => {
    setXeroPaymentDialog(false);
    navigate("/app/instructions/list");
  };

  // DataGrid onRowClick Method
  const onRowClick = (e) => {
    navigate(`/app/instructions/dashboard/${e?.row?.id}`);
  };

  useEffect(() => {
    getInstructionsData();
    getInstructionTypeData();
    getMilestoneType();
    getInstructionStatus();
  }, []);

  useEffect(() => {
    if (accessToken && tenantid) {
      setXeroPaymentDialog(true);
    } else {
      setXeroPaymentDialog(false);
    }
  }, [accessToken, tenantid]);
  return (
    <>
      {/* Xero Payment processing Dialog */}
      {isXeroPaymentDialog && (
        <XeroProcessingDialog
          open={true}
          handleClose={handleCloseXeroDialog}
          accessToken={accessToken}
          tenantid={tenantid}
        />
      )}

      <Grid container spacing={2} style={{ marginTop: "0px" }}>
        {/* Loader Component */}
        {isLoader && <Loader />}
        {/* Filter Container */}
        <Grid item xs={2.4}>
          <TextField
            fullWidth
            className="textField_outlined_border"
            placeholder="Search"
            size="small"
            value={filterPayload.search}
            onChange={(e) => handleChangeName(e?.target?.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* TYPE */}
        <Grid item xs={2.4}>
          <FormControl
            fullWidth
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
          >
            <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
              Type
            </InputLabel>
            <Select
              className="textField_outlined_border"
              label="Type"
              onChange={(e) =>
                handleChangeInstructionType(e?.target?.value, "type")
              }
            >
              <MenuItem>--</MenuItem>
              {instructionTypes?.length > 0 &&
                instructionTypes.map((d) => (
                  <MenuItem
                    key={d?.id}
                    value={d?.name}
                    className="normal_normal_medium_14_Manrope dark_grey"
                  >
                    {d?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {/* MILESTONE */}
        <Grid item xs={2.4}>
          <FormControl
            fullWidth
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
          >
            <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
              Milestone
            </InputLabel>
            <Select
              className="textField_outlined_border"
              label="Milestone"
              onChange={(e) =>
                handleChangeMilestone(e?.target?.value, "milestones")
              }
            >
              <MenuItem>--</MenuItem>
              {milestone?.length > 0 &&
                milestone.map((d) => (
                  <MenuItem
                    key={d?.id}
                    value={d?.name}
                    className="normal_normal_medium_14_Manrope dark_grey"
                  >
                    {d?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>{" "}
        {/* STATUS */}
        <Grid item xs={2.4}>
          <FormControl
            fullWidth
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
          >
            <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
              Status
            </InputLabel>
            <Select
              className="textField_outlined_border"
              label="Status"
              value={filterPayload?.statusId}
              onChange={(e) => handleChangeFilter(e?.target?.value, "statusId")}
            >
              <MenuItem>--</MenuItem>
              {instructionStatusList?.length > 0 &&
                instructionStatusList.map((d) => (
                  <MenuItem
                    key={d?.id}
                    value={d?.id}
                    className="normal_normal_medium_14_Manrope dark_grey"
                  >
                    {d?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {/* CONSULTANT */}
        {(userData?.role === USER_ROLE?.OFFICE_ADMIN ||
          userData?.role === USER_ROLE?.SUPER_ADMIN) && (
          <Grid item xs={2.4}>
            <Autocomplete
              options={consultantList}
              onChange={(e, value) =>
                handleChangeFilter(value?.id, "consultantId")
              }
              filterSelectedOptions={Boolean(consultantList?.length)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="normal_normal_medium_14_Manropee textField_outlined_border"
                  variant="outlined"
                  size="small"
                  label="Consultant"
                  placeholder="Select Consultant"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "30px",
                      height: 38,
                    },
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} style={{ width: "100%", height: "75vh" }}>
          <DataGridComponent
            columns={columns}
            rows={instructionsData}
            onRowClick={onRowClick}
          />
        </Grid>
        {/* PAGINATION */}
        <PaginationComponent
          totalCount={totalCount}
          limit={filterPayload?.limit}
          page={filterPayload?.page}
          onChange={handleChangePage}
        />
      </Grid>
    </>
  );
};

export default InstructionList;
