import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../assets/images/BUTTON_Back.svg";
import setupButton from "../../../assets/images/setUpButton.svg";
import Loader from "../../../components/Loader";
import "../../../custom-bootstrap.scss";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { createMeeting, getClientsForMeeting } from "../../../thunks/client";
import { getInstructionType } from "../../../thunks/instruction";
import { NOTIFICATION_MESSAGE, VALIDATION_MSG } from "../../../utils/constant";
import { emailValidation, phoneNumberValidation } from "../../../utils/util";

const SetupNewMeeting = () => {
  // const meetingTypes = useSelector(
  //   (state) => state?.lookupData?.meetingTypeData
  // );
  //
  // const sourceType = useSelector((state) => state?.lookupData?.sourceTypeData);

  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const lookups = useSelector((state) => state?.lookupData);
  let { "Meeting Type": meetingTypes, "Source Type": sourceType } =
    lookups || {};

  // states
  const [isError, setIsError] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [generateForm, setGenerateForm] = useState([]);
  const [isLocation, setIsLocation] = useState(false);
  const [clientData, setClientData] = useState({
    location: "",
    name: "",
    applicationType: "",
    sourceTypeLookupId: null,
    sourceSubType: "",
    isJoint: null,
    instructionTypeId: null,
    instructionType: "",
    meetingTypeLookupId: null,
    meetingDate: new Date(),
    LeadClients: [],
  });
  const [clientsList, setClientsList] = useState([]);
  const [instructionType, setInstructionType] = useState([]);

  // handle change application type
  const handleChangeApplicationType = (value, key) => {
    setClientData({
      ...clientData,
      [key]: value,
    });
    const tempGenerateForm = [];
    if (value === "Single") {
      tempGenerateForm.push({
        clientName: "",
        firstName: "",
        middleName: "",
        lastName: "",
        mobileNo: "",
        email: "",
        postalCode: "",
      });
    } else {
      tempGenerateForm.push(
        {
          clientName: "",
          firstName: "",
          middleName: "",
          lastName: "",
          mobileNo: "",
          email: "",
          postalCode: "",
        },
        {
          clientName: "",
          firstName: "",
          middleName: "",
          lastName: "",
          mobileNo: "",
          email: "",
          postalCode: "",
        }
      );
    }
    setGenerateForm(tempGenerateForm);
  };

  // handle change form data
  const handleChangeFormData = (value, key) => {
    setClientData({ ...clientData, [key]: value });
  };

  // handle change client details
  const handleChangeClientDetails = (e, ind, key) => {
    if (key === "clientName") {
      let data = [...generateForm];
      data[ind]["clientName"] = e || "";
      data[ind]["firstName"] = e?.firstName || "";
      data[ind]["middleName"] = e?.middleName || "";
      data[ind]["lastName"] = e?.lastName || "";
      data[ind]["mobileNo"] = e?.mobileNo || "";
      data[ind]["email"] = e?.email || "";
      data[ind]["postalCode"] = e?.postalCode || "";

      setGenerateForm(data);
    } else {
      let data = [...generateForm];
      data[ind][key] = e;
      setGenerateForm(data);
    }
  };

  // method to get instruction type data
  const getInstructionTypeData = async () => {
    try {
      const response = await dispatch(getInstructionType()).unwrap();
      if (response) {
        setInstructionType(response);
      } else {
        setInstructionType([]);
      }
    } catch (error) {
      setInstructionType([]);
    }
  };

  // method to get all clients data
  const getAllClientsData = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getClientsForMeeting()).unwrap();
      if (response) {
        setClientsList(response);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setClientsList([]);
    }
  };

  // handle submit client data
  const handleSubmitClientData = async () => {
    const getSelectedInstructionTypeById = instructionType.filter(
      (data) => data?.id === clientData?.instructionTypeId
    );

    const payload = {
      ...clientData,
      isJoint: clientData?.applicationType === "Joint" ? true : false,
      instructionType: getSelectedInstructionTypeById?.[0]?.name || "",
      LeadClients: generateForm,
    };
    let wasNull = false;
    for (var i in generateForm) {
      if (
        !clientData?.instructionTypeId ||
        !clientData?.sourceTypeLookupId ||
        !clientData?.sourceSubType ||
        !generateForm[i]?.firstName ||
        !generateForm[i]?.lastName ||
        !clientData?.meetingTypeLookupId ||
        !phoneNumberValidation(generateForm[i]?.mobileNo) ||
        (generateForm[i].email && !emailValidation(generateForm[i]?.email))
      ) {
        wasNull = true;
      }
    }
    if (wasNull) {
      setIsError(true);
    } else {
      setIsError(false);
      const payload = {
        ...clientData,
        isJoint: clientData?.applicationType === "Joint" ? true : false,
        instructionType: getSelectedInstructionTypeById?.[0]?.name || "",
        // meetingDate: clientData?.meetingDate?.toString(),
        LeadClients: generateForm,
      };
      try {
        setLoader(true);
        const response = await dispatch(createMeeting(payload)).unwrap();
        if (response?.id) {
          setLoader(false);
          dispatch(
            showSnackbar({
              show: true,
              message: NOTIFICATION_MESSAGE?.MEETING_CREATE_SUCCESS,
              severity: "success",
            })
          );
          navigate("/app/dashboard/list");
        }
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: `${error?.message}`,
            severity: "error",
          })
        );
      }
    }
  };
  // use effect
  useEffect(() => {
    if (location?.state?.data) {
      setIsLocation(true);
      setClientData({
        ...clientData,
        applicationType: "Single",
        sourceTypeLookupId: location?.state?.data?.sourceTypeLookupId || "",
        sourceSubType: location?.state?.data?.sourceSubType || "",
      });
      const tempGenerateForm = [];
      tempGenerateForm.push({
        clientName: "",
        firstName: location?.state?.data?.firstName || "",
        middleName: location?.state?.data?.middleName || "",
        lastName: location?.state?.data?.lastName || "",
        mobileNo: location?.state?.data?.mobileNo || "",
        email: location?.state?.data?.email || "",
        postalCode: location?.state?.data?.postalCode || "",
      });
      setGenerateForm(tempGenerateForm);
    }
  }, [location]);

  useEffect(() => {
    getInstructionTypeData();
    getAllClientsData();
  }, []);

  return (
    <>
      {isLoader && <Loader />}
      <Grid container spacing={3} style={{ width: "calc(100% + 36px)" }}>
        <Grid
          item
          xs={12}
          p={8}
          sx={{ backgroundColor: "#e5faeb", marginLeft: "13px" }}
        >
          <Grid container spacing={3} m={0}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              {/* back button and set up a new meeting label */}
              <img
                src={BackButton}
                onClick={() => navigate("/app/dashboard/list")}
                style={{ marginRight: "30px", cursor: "pointer" }}
              />

              <Typography className="serif_display_regular_26">
                Setup a New Meeting
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth className="border_color">
                <InputLabel
                  style={{ lineHeight: "2.0375", marginLeft: "-12px" }}
                  shrink={true}
                  className="normal_normal_600_12_Manrope green"
                >
                  Application Type
                </InputLabel>
                <Select
                  className="formControlField"
                  disabled={isLocation}
                  value={clientData?.applicationType}
                  label="Application Type"
                  onChange={(e) =>
                    handleChangeApplicationType(
                      e?.target?.value,
                      "applicationType"
                    )
                  }
                  variant="standard"
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Joint">Joint</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl
                fullWidth
                className="border_color"
                error={isError && !clientData?.sourceTypeLookupId}
                required
              >
                <InputLabel
                  shrink={true}
                  style={{
                    lineHeight: "2.0375",
                    marginLeft: "-12px",
                    color: "#00ce3f",
                  }}
                  className="normal_normal_600_12_Manrope green"
                >
                  Source Type
                </InputLabel>
                <Select
                  disabled={isLocation}
                  className="formControlField"
                  value={clientData?.sourceTypeLookupId}
                  label="Source Type"
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "sourceTypeLookupId")
                  }
                  variant="standard"
                >
                  {sourceType?.length > 0 &&
                    sourceType.map((d) => (
                      <MenuItem
                        className="normal_normal_600_12_Manrope dark_grey"
                        value={d?.id}
                      >
                        {d?.name}
                      </MenuItem>
                    ))}
                </Select>
                {isError && !clientData?.sourceTypeLookupId && (
                  <FormHelperText className="red">
                    Select source type
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <TextField
                disabled={isLocation}
                required
                error={isError && !clientData?.sourceSubType}
                helperText={
                  isError &&
                  !clientData?.sourceSubType &&
                  "Enter source sub type"
                }
                className="inputTextField border_color normal_normal_600_12_Manropee"
                fullWidth
                label="Source Sub Type"
                value={clientData?.sourceSubType}
                onChange={(e) =>
                  handleChangeFormData(e?.target?.value, "sourceSubType")
                }
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControl
                required
                error={isError && !clientData?.instructionTypeId}
                fullWidth
                className="border_color"
              >
                <InputLabel
                  shrink={true}
                  style={{
                    lineHeight: "2.0375",
                    marginLeft: "-12px",
                  }}
                  className="normal_normal_600_12_Manrope green"
                >
                  Instruction Type
                </InputLabel>
                <Select
                  className="formControlField"
                  value={clientData?.instructionTypeId}
                  label="Instruction Type"
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "instructionTypeId")
                  }
                  variant="standard"
                >
                  {instructionType?.length > 0 &&
                    instructionType?.map((d) => (
                      <MenuItem value={d?.id}>{d?.name}</MenuItem>
                    ))}
                </Select>
                {isError && !clientData?.instructionTypeId && (
                  <FormHelperText className="red">
                    Select instruction type
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl
                required
                variant="standard"
                fullWidth
                className="border_color"
                error={isError && !clientData?.meetingTypeLookupId}
              >
                <InputLabel
                  shrink={true}
                  className="normal_normal_600_12_Manrope green"
                >
                  Type of Meeting
                </InputLabel>
                <Select
                  className="formControlField"
                  value={clientData?.meetingTypeLookupId}
                  onChange={(e) =>
                    handleChangeFormData(
                      e?.target?.value,
                      "meetingTypeLookupId"
                    )
                  }
                  label="Type of Meeting"
                >
                  {meetingTypes?.length > 0 &&
                    meetingTypes.map((d) => (
                      <MenuItem value={d?.id}>{d?.name}</MenuItem>
                    ))}
                </Select>
                {isError && !clientData?.meetingTypeLookupId && (
                  <FormHelperText>Select meeting type</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  slotProps={{
                    textField: { InputLabelProps: { shrink: true } },
                  }}
                  label="Date & Time of Meeting"
                  className="normal_normal_600_12_Manropee outlined_border"
                  format={"dd/MM/yyyy hh:mm a"}
                  value={clientData?.meetingDate}
                  onChange={(e) => handleChangeFormData(e, "meetingDate")}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Generate Dynamic forms */}
      <Grid container spacing={3} mt={1} pl={2}>
        {generateForm?.length > 0 &&
          generateForm.map((d, ind) => (
            <Grid item xs={6} p={3} sx={{ borderRight: "1px solid #DCDCE1" }}>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography className="normal_normal_20_Manrope">
                    Client - {ind + 1}
                  </Typography>

                  <Grid item xs={6}>
                    <Autocomplete
                      disabled={isLocation}
                      fullWidth
                      value={d?.clientName || null}
                      options={clientsList}
                      getOptionLabel={(option) =>
                        `${option?.firstName} ${option?.lastName}`
                      }
                      filterSelectedOptions
                      onChange={(e, value) =>
                        handleChangeClientDetails(value, ind, "clientName")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="inputTextField border_color normal_normal_600_12_Manropee"
                          variant="standard"
                          size="small"
                          label="Choose Client"
                          placeholder="Choose Client"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled={d?.clientName?.id || isLocation}
                    value={d?.firstName}
                    error={isError && !d?.firstName}
                    helperText={
                      isError && !d?.firstName && VALIDATION_MSG.REQUIRED
                    }
                    required
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="First Name"
                    size="small"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeClientDetails(
                        e?.target?.value,
                        ind,
                        "firstName"
                      )
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled={d?.clientName?.id || isLocation}
                    value={d?.middleName}
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="Middle Name"
                    size="small"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeClientDetails(
                        e?.target?.value,
                        ind,
                        "middleName"
                      )
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled={d?.clientName?.id || isLocation}
                    value={d?.lastName}
                    error={isError && !d?.lastName}
                    helperText={
                      isError && !d?.lastName && VALIDATION_MSG.REQUIRED
                    }
                    fullWidth
                    required
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="Last Name"
                    size="small"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeClientDetails(
                        e?.target?.value,
                        ind,
                        "lastName"
                      )
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    disabled={d?.clientName?.id || isLocation}
                    value={d?.mobileNo}
                    error={isError && !phoneNumberValidation(d?.mobileNo)}
                    helperText={
                      isError &&
                      !phoneNumberValidation(d?.mobileNo) &&
                      VALIDATION_MSG.VALID_MOBILE_NUMBER
                    }
                    fullWidth
                    required
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="Mobile Number"
                    size="small"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeClientDetails(
                        e?.target?.value,
                        ind,
                        "mobileNo"
                      )
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    disabled={d?.clientName?.id || isLocation}
                    value={d?.email}
                    error={isError && d?.email && !emailValidation(d?.email)}
                    helperText={
                      isError &&
                      d?.email &&
                      !emailValidation(d?.email) &&
                      VALIDATION_MSG.VALID_EMAIL_ID
                    }
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="Email"
                    size="small"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeClientDetails(e?.target?.value, ind, "email")
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    disabled={d?.clientName?.id || isLocation}
                    value={d?.postalCode}
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="Post Code"
                    size="small"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeClientDetails(
                        e?.target?.value,
                        ind,
                        "postalCode"
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>

      {generateForm?.length > 0 && (
        <Grid container spacing={3} mb={2} mt={1}>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              style={{
                height: "40px",
                cursor: "pointer",
              }}
              src={setupButton}
              onClick={handleSubmitClientData}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SetupNewMeeting;
