import { createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS } from "../utils/constant";

// ------------------------------REPORT -------------------------

// METHOD TO GET REPORT PARAMS
export const getReportParams = createAsyncThunk(
  "company/getReportParameters",
  async (thunkApi) => {
    try {
      let res = await getAxios().get(API_ROUTES_PATHS.REPORT_PARAMS);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
// UPDATE REPORT PARAMETERS
export const UpdateReportParams = createAsyncThunk(
  "company/updateReportParameters",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.REPORT_PARAMS}/${model?.id}`,
        model
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
// ------------------------------PRESET-------------------------
//METHOD TO ADD PRESETS/FILTERS
export const CreatePreset = createAsyncThunk(
  "company/createPreset",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(`${API_ROUTES_PATHS.PRESETS}`, model);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// METHOD TO GET PRESETS/FILTERS
export const getPresets = createAsyncThunk(
  "company/getPresets",
  async (thunkApi) => {
    try {
      let res = await getAxios().get(API_ROUTES_PATHS.PRESETS);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
// UPDATE PRESETS/FILTERS
export const UpdatePreset = createAsyncThunk(
  "company/updatePresets",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.PRESETS}/${model?.id}`,
        model?.UpdatePayload
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
