import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import CreateEmailTemplate from "./CreateEmailTemplate";
import EmailTemplateList from "./EmailTemplateList";
import UpdateEmailTemplate from "./UpdateEmailTemplate";

const EmailTemplate = () => {
  const Location = useLocation();
  return (
    <>
      <div className="main-page-container">
        <Routes>
          <Route
            path={`/`}
            element={<Navigate replace="/" to={`${Location.pathname}/list`} />}
          ></Route>
          <Route path={"list"} element={<EmailTemplateList />}></Route>
          <Route path={"create"} element={<CreateEmailTemplate />}></Route>
          <Route path={"update/:id"} element={<UpdateEmailTemplate />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default EmailTemplate;
