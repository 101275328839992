import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";

import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DataGridComponent from "../../../../components/DataGridComponent";
import Loader from "../../../../components/Loader";
import { getLookups } from "../../../../thunks/lookup";
import CreateCampaign from "./CreateCampaign";
import UpdateCampaign from "./UpdateCampaign";

const CampaignList = () => {
  const dispatch = useDispatch();
  const [isAddCampaign, setAddCampaign] = useState(false);
  const [isupdateCampaign, setUpdateCampaign] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [lookupId, setLookupId] = useState(null);
  const [isLoader, setLoader] = useState(false);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 300,
    },
    {
      field: "isActive",
      headerName: "Auto Commission Applicable",
      width: 240,
      renderCell: (params) => (
        <div style={{ marginLeft: 70 }}>
          <Checkbox
            sx={{
              color: "#00CE3F",
              "&.Mui-checked": {
                color: "#00CE3F",
              },
            }}
            checked={
              params?.row?.CommissionPermissionsOnSource?.length > 0
                ? true
                : false
            }
            disabled
          />
        </div>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <>
          <Tooltip title="Update Campaign">
            <IconButton onClick={() => handleClickUpdateCampaign(params?.row)}>
              <EditIcon className="green" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const getCampaigns = async () => {
    try {
      setLoader(true);
      const lookupKeys = ["Source Type"];
      const response = await dispatch(
        getLookups({ searchStrings: JSON.stringify(lookupKeys) })
      );
      if (response?.payload?.[0]?.Lookup?.length > 0) {
        setLookupId(response?.payload?.[0]?.id);
        setCampaigns(response?.payload?.[0]?.Lookup);
        setLoader(false);
      } else {
        setLookupId(null);
        setCampaigns([]);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  const handleClickUpdateCampaign = (data) => {
    setSelectedData(data);
    setUpdateCampaign(true);
  };
  useEffect(() => {
    getCampaigns();
  }, []);
  return (
    <>
      {isLoader && <Loader />}
      {isAddCampaign && (
        <CreateCampaign
          open={isAddCampaign}
          handleClose={() => setAddCampaign(false)}
          lookupId={lookupId}
          handleSuccess={getCampaigns}
        />
      )}
      {isupdateCampaign && (
        <UpdateCampaign
          open={isupdateCampaign}
          handleClose={() => setUpdateCampaign(false)}
          lookupId={lookupId}
          selectedData={selectedData}
          handleSuccess={getCampaigns}
        />
      )}
      <Grid container spacing={3}>
        {/* Document Type Section */}
        <Grid item xs={6} mt={1}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              gap: 20,
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Typography className="serif_display_regular_22 green">
              Campaigns
            </Typography>
            <Button
              className="add_a_new_user_button"
              onClick={() => setAddCampaign(true)}
            >
              <AddCircleOutlineIcon style={{ paddingRight: 3 }} />
              Add
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ width: "100%", height: "80vh", overflow: "auto" }}
          >
            <DataGridComponent
              columns={columns}
              rows={campaigns || []}
              onRowClick={() => {}}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CampaignList;
