import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import AlertComponent from "../../../components/alert";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { ClearTaskData } from "../../../slice/task";
import {
  allocateOrCompleteIndividualTask,
  getCustomTasks,
  getIndividualTasks,
} from "../../../thunks/instruction";
import { NOTIFICATION_MESSAGE } from "../../../utils/constant";
import DueDateDialog from "../components/DueDateDialog";
import CommentsDrawer from "./CommentsDrawer";

const IndividualTask = ({
  open,
  handleClose,
  instructionId,
  openUpdateTaskAnswerDialog,
  viewTaskComment,
  taskType,
  taskId,
}) => {
  const dispatch = useDispatch();
  const individualTasks = useSelector((state) =>
    taskId
      ? state?.instruction?.individualTaskData.filter((d) => d?.id === taskId)
      : state?.instruction?.individualTaskData
  );
  const customTasks = useSelector((state) =>
    taskId
      ? state?.instruction?.customTaskData?.filter((d) => d?.id === taskId)
      : state?.instruction?.customTaskData
  );
  const individualTasksLoader = useSelector(
    (state) => state?.instruction?.individualTaskLoader
  );
  const customTasksLoader = useSelector(
    (state) => state?.instruction?.customTaskLoader
  );
  const [isIndividualTask, setIsIndovidualTask] = useState(true);
  const [isLoader, setLoader] = useState(false);
  const [isTaskPick, setTaskPick] = useState(false);
  const [pickTaskId, setPickTaskId] = useState(null);
  const [isTaskCompleted, setTaskComplete] = useState(false);
  const [completeTaskId, setCompleteTaskId] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [isTaskComment, setIsTaskComment] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [selectedDueDateData, setSelectedDueDateData] = useState(null);
  const [isOpenDueDate, setOpenDueDate] = useState(false);

  /* Method to open due date dialog if task status is not completed */
  const handleClickOpenDueDate = (data) => {
    let tempObj = {
      id: data.id,
      dueDate: data.dueDate,
      taskType: data?.taskType,
    };
    setSelectedDueDateData(tempObj);
    if (data?.status !== "Completed") {
      setOpenDueDate(true);
    }
  };

  /* Method to get Duplicate Tasks */
  const getTasks = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getIndividualTasks(instructionId));
      setLoader(false);
    } catch (error) {
      return error;
    }
  };

  /* Method to get Custom task */
  const getCustomTaskData = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getCustomTasks(instructionId));
      setLoader(false);
    } catch (error) {
      return error;
    }
  };
  /* Method to click pick task */
  const handleClickPickTask = (data) => {
    setTaskPick(true);
    setPickTaskId(data?.id);
  };

  /* Method to complete task */
  const handleClickCompleteTask = (data) => {
    setTaskComplete(true);
    setCompleteTaskId(data?.id);
  };

  /* Success method to update task status to Picked */
  const handlePickTaskSuccess = async () => {
    setTaskPick(false);
    const payload = {
      id: pickTaskId,
      status: "Picked",
    };
    try {
      setLoader(true);
      const response = await dispatch(
        allocateOrCompleteIndividualTask(payload)
      ).unwrap();
      if (response) {
        setLoader(false);
        if (tabValue === 0) {
          dispatch(getIndividualTasks(instructionId));
        } else {
          dispatch(getCustomTasks(instructionId));
        }
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.ASSIGN_TASK_SUCCESS,
            severity: "success",
          })
        );
      }
      setTaskPick(false);
    } catch (error) {
      setTaskPick(false);
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error?.message,
          severity: "error",
        })
      );
      // handleClose();
    }
  };

  /* Success method to update task status to Completed */
  const handleCompleteTaskSuccess = async () => {
    setTaskComplete(false);
    const payload = {
      id: completeTaskId,
      status: "Completed",
    };
    try {
      setLoader(true);
      const response = await dispatch(
        allocateOrCompleteIndividualTask(payload)
      ).unwrap();
      if (response) {
        setLoader(false);
        if (tabValue === 0) {
          dispatch(getIndividualTasks(instructionId));
        } else {
          dispatch(getCustomTasks(instructionId));
        }
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.COMPLETE_TASK_SUCCESS,
            severity: "success",
          })
        );
        // handleClose();
      }
      setTaskPick(false);
    } catch (error) {
      setTaskPick(false);
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error?.message,
          severity: "error",
        })
      );
      // handleClose();
    }
  };

  const handleChangeTabValue = (event, newVal) => {
    if (newVal === 0) {
      getTasks();
    } else {
      getCustomTaskData();
    }
    setTabValue(newVal);
  };

  // Method to clear filter
  const handleClearFilter = () => {
    console.log("Clear Filter");
    if (taskType && taskType === "Custom") {
      setTabValue(1);
      dispatch(ClearTaskData());
      getCustomTaskData();
    } else if (taskType && taskType === "Instance") {
      setTabValue(0);
      dispatch(ClearTaskData());
      getTasks();
    }
  };

  //HANDLE MODULE CLOSE
  const handleModuleClose = () => {
    dispatch(ClearTaskData());
    handleClose();
  };
  /* Method to get Task Comments */
  // const viewTaskComments = (data) => {
  //   setSelectedComment(data);
  //   setIsTaskComment(true);
  // };

  // Method to check date if date is greater then current date
  const checkDate = (data) => {
    // Parse the provided date into a Moment.js object
    const tempCurrentDate = moment().format("DD/MM/YYYY hh:mm A");
    const tempProvidedDate = moment(data?.dueDate || null).format(
      "DD/MM/YYYY hh:mm A"
    );
    const tempEndDate = moment(data?.endDate || null).format(
      "DD/MM/YYYY hh:mm A"
    );
    const providedDate = moment(tempProvidedDate, "DD/MM/YYYY hh:mm A");
    const currentDate = moment(tempCurrentDate, "DD/MM/YYYY hh:mm A");
    const endDate = moment(tempEndDate, "DD/MM/YYYY hh:mm A");
    // Compare the dates
    const isDateBeforeComplete = providedDate.isBefore(currentDate);
    const isDateAfterComplete = providedDate.isBefore(endDate);
    if (isDateBeforeComplete && !data?.isCompleted) {
      return true;
    } else if (isDateAfterComplete && data?.isCompleted) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!taskType) {
      getTasks();
    }
  }, []);

  useEffect(() => {
    if (taskType && taskType === "Custom") {
      setTabValue(1);
      getCustomTaskData();
    } else if (taskType && taskType === "Instance") {
      setTabValue(0);
      getTasks();
    }
  }, [taskType]);

  return (
    <>
      {/* Dialog for update due date */}
      {isOpenDueDate && (
        <DueDateDialog
          isIndividual={true}
          open={isOpenDueDate}
          handleClose={() => setOpenDueDate(false)}
          selectedData={selectedDueDateData}
        />
      )}

      {/* Tasks Comments Drawer */}
      {isTaskComment && (
        <CommentsDrawer
          open={isTaskComment}
          handleClose={() => setIsTaskComment(false)}
          selectedComment={selectedComment}
        />
      )}
      <Dialog
        open={open}
        onClose={handleModuleClose}
        fullWidth={true}
        maxWidth={"lg"}
        PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
      >
        <IconButton
          aria-label="close"
          onClick={handleModuleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* Loader Component */}
        {individualTasksLoader && <Loader />}
        {/* Dialog for Pick Task Confirmation */}
        {isTaskPick && (
          <AlertComponent
            open={isTaskPick}
            handleSuccess={handlePickTaskSuccess}
            handleClose={() => setTaskPick(false)}
            message="Are you sure, want to pick the task to yourself"
          />
        )}

        {/* Dialog for Complete Task Confirmation */}
        {isTaskCompleted && (
          <AlertComponent
            open={isTaskCompleted}
            handleSuccess={handleCompleteTaskSuccess}
            handleClose={() => setTaskComplete(false)}
            message="Are you sure you want to complete the task?"
          />
        )}

        <DialogContent dividers={true} sx={{ marginRight: "5px" }}>
          {isLoader && <Loader />}
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Tabs
              fullWidth
              value={tabValue}
              onChange={handleChangeTabValue}
              aria-label="Task"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#00CE3F",
                },
                "& .MuiTab-root.Mui-selected": {
                  color: "#00CE3F",
                  fontWeight: 600,
                },
                "& .MuiTab-root": {
                  color: "#3D4740",
                },
              }}
            >
              <Tab label="Duplicate Task" />
              <Tab label="Custom Task" />
            </Tabs>
            {taskId && (
              <Tooltip title="Clear filter">
                <IconButton aria-label="close" onClick={handleClearFilter}>
                  <FilterAltOffIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>

          {/* Duplicate Task Component */}
          {tabValue === 0 && (
            <Grid
              container
              spacing={2}
              justify="flex-start"
              mt={1}
              mb={1}
              className="instruction_dashboard_scrollbar"
              style={{ overflow: "auto", padding: "1rem" }}
            >
              {individualTasksLoader ? (
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography style={{ color: "#00CE3F", marginTop: "1rem" }}>
                    Loading Tasks...
                  </Typography>
                </Grid>
              ) : individualTasks?.length > 0 ? (
                individualTasks?.map((d) => (
                  <>
                    <Grid
                      key={d?.id}
                      item
                      xs={6}
                      display="flex"
                      justifyContent="flex-start"
                    >
                      <Typography className="serif_display_regular_20">
                        {d?.milestoneName || "NA"}
                      </Typography>
                      <Typography ml={1} className="serif_display_regular_20">
                        ({d?.productName || "NA"})
                      </Typography>
                    </Grid>

                    <Grid item xs={6} display="flex" justifyContent="flex-end">
                      <Typography className="normal_normal_medium_14_Manrope dark_grey">
                        <span className="normal_normal_600_14_Manrope dark_grey">
                          Actor:-
                        </span>
                        {` ${d?.actor || "NA"}`}
                      </Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <Typography className="normal_normal_18_Manrope">
                        <Tooltip
                          title={
                            d?.status === "Completed"
                              ? "Completed Task"
                              : "Click to complete task"
                          }
                        >
                          <IconButton
                            onClick={
                              !d?.finalTruthWhatActionCanHeTakeOnTaskLikeGivingComment ||
                              d?.status === "Completed" ||
                              d?.status === null
                                ? null
                                : () => handleClickCompleteTask(d)
                            }
                          >
                            <CheckCircleIcon
                              sx={{
                                color: `${
                                  d?.status === "Completed"
                                    ? "#00CE3F"
                                    : "#dee2e6"
                                }`,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        {d?.name || "NA"}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography className="normal_normal_medium_14_Manrope dark_grey">
                        <span className="normal_normal_600_14_Manrope dark_grey">
                          {`${
                            d?.status === "Completed"
                              ? "Completed By:-"
                              : "Assigned to:-"
                          }`}
                        </span>
                        {`${
                          d?.assigned
                            ? d?.assigned?.firstName +
                              " " +
                              d?.assigned?.lastName
                            : d?.actor || "NA"
                        }`}
                      </Typography>
                    </Grid>

                    {/* Assign task to yourself */}
                    {d?.isVisible ? (
                      <Button
                        size="small"
                        style={{
                          boxShadow: "none",
                          backgroundColor: "#00ce3f",
                          borderRadius: "24px",
                          marginTop: "1rem",
                          marginLeft: "1rem",
                          textTransform: "none",
                          display: `${
                            !d?.isAssignedToCurrentUser ||
                            d?.status === "Picked" ||
                            d?.status === "Completed"
                              ? "none"
                              : ""
                          }`,
                        }}
                        variant="contained"
                        onClick={
                          d?.status === "Picked" || d?.status === "Completed"
                            ? null
                            : () => handleClickPickTask(d)
                        }
                      >
                        Add to in-progress
                      </Button>
                    ) : null}

                    <Grid
                      container
                      spacing={3}
                      mt={1}
                      style={{
                        display: `${
                          d?.status === "Picked" || d?.status === "Completed"
                            ? ""
                            : "none"
                        }`,
                      }}
                    >
                      {d?.TaskAns?.length > 0 &&
                        d?.TaskAns?.map((answer) => (
                          <>
                            <Grid
                              key={d?.id}
                              item
                              xs={4}
                              style={{
                                display: "flex",
                                gap: 10,
                                alignItems: "center",
                              }}
                            >
                              <Button
                                disabled={
                                  !d?.finalTruthWhatActionCanHeTakeOnTaskLikeGivingComment ||
                                  d?.status === null
                                }
                                fullWidth
                                size="small"
                                style={{
                                  borderRadius: "24px",
                                  boxShadow: "none",
                                  borderColor: "#3D4740",
                                  marginLeft: "1rem",
                                }}
                                variant="outlined"
                                className={`normal_normal_semibold_14_Manrope ${
                                  !d?.finalTruthWhatActionCanHeTakeOnTaskLikeGivingComment ||
                                  d?.status === null
                                    ? ""
                                    : "dark_grey"
                                } roundOutlinedButton`}
                                onClick={() =>
                                  openUpdateTaskAnswerDialog(
                                    answer,
                                    d,
                                    isIndividualTask
                                  )
                                }
                              >
                                {answer?.name || ""}
                              </Button>

                              <IconButton onClick={() => viewTaskComment(d)}>
                                <RemoveRedEyeIcon className="green" />
                              </IconButton>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              {d?.status === "Completed" ? (
                                <Tooltip title="Task is already completed">
                                  <span
                                    className={`normal_normal_600_14_Manrope ${
                                      checkDate(d) ? "red" : "green"
                                    }`}
                                    style={{ marginLeft: "1rem" }}
                                  >
                                    (Due by:-{" "}
                                    {d?.dueDate
                                      ? moment(new Date(d?.dueDate)).format(
                                          "DD/MM/YYYY hh:mm A"
                                        )
                                      : "NA"}
                                    )
                                  </span>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Update Due Date">
                                  <span
                                    onClick={() => handleClickOpenDueDate(d)}
                                    className={`normal_normal_600_14_Manrope ${
                                      checkDate(d) ? "red" : "green"
                                    }`}
                                    style={{
                                      marginLeft: "1rem",
                                      cursor: "pointer",
                                    }}
                                  >
                                    (Due by:-{" "}
                                    {d?.dueDate
                                      ? moment(new Date(d?.dueDate)).format(
                                          "DD/MM/YYYY hh:mm A"
                                        )
                                      : "NA"}
                                    )
                                  </span>
                                </Tooltip>
                              )}
                              {/* <span
                                className={`normal_normal_600_14_Manrope ${
                                  checkDate(d) ? "red" : "green"
                                }`}
                                style={{
                                  marginLeft: "1rem",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleClickOpenDueDate(d)}
                              >
                                (Due by:-{" "}
                                {d?.dueDate
                                  ? moment(new Date(d?.dueDate)).format(
                                      "DD/MM/YYYY hh:mm A"
                                    )
                                  : "NA"}
                                )
                              </span> */}
                            </Grid>
                            {d?.isCompleted && (
                              <Grid
                                item
                                xs={4}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  className="normal_normal_600_14_Manrope green"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  (Completed On:-{" "}
                                  {d?.endDate
                                    ? moment(new Date(d?.endDate)).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )
                                    : "NA"}
                                  )
                                </span>
                              </Grid>
                            )}
                          </>
                        ))}
                    </Grid>

                    <Grid item xs={12} mt={1} style={{ marginLeft: "30px" }}>
                      <Divider
                        orientation="horizontal"
                        sx={{ border: `1px solid #DCDCE1` }}
                      />
                    </Grid>
                  </>
                ))
              ) : (
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography style={{ color: "#00CE3F", marginTop: "1rem" }}>
                    Data not found.
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}

          {/* Custom Task Component */}
          {tabValue === 1 && (
            <Grid
              container
              spacing={2}
              justify="flex-start"
              mt={1}
              mb={1}
              className="instruction_dashboard_scrollbar"
              style={{ overflow: "auto", padding: "1rem" }}
            >
              {customTasksLoader ? (
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography style={{ color: "#00CE3F", marginTop: "1rem" }}>
                    Loading Tasks...
                  </Typography>
                </Grid>
              ) : customTasks?.length > 0 ? (
                customTasks?.map((d) => (
                  <>
                    <Grid item xs={8}>
                      <Typography className="normal_normal_18_Manrope">
                        <Tooltip
                          title={
                            d?.status === "Completed"
                              ? "Completed Task"
                              : "Click to complete task"
                          }
                        >
                          <IconButton
                            onClick={
                              !d?.finalTruthWhatActionCanHeTakeOnTaskLikeGivingComment ||
                              d?.status === "Completed" ||
                              d?.status === null
                                ? null
                                : () => handleClickCompleteTask(d)
                            }
                          >
                            <CheckCircleIcon
                              sx={{
                                color: `${
                                  d?.status === "Completed"
                                    ? "#00CE3F"
                                    : "#dee2e6"
                                }`,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        {d?.name || "NA"}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography className="normal_normal_medium_14_Manrope dark_grey">
                        <span className="normal_normal_600_14_Manrope dark_grey">
                          {`${
                            d?.status === "Completed"
                              ? "Completed By:-"
                              : "Assigned to:-"
                          }`}
                        </span>
                        {`${
                          d?.assigned
                            ? d?.assigned?.firstName +
                              " " +
                              d?.assigned?.lastName
                            : d?.actor || "NA"
                        }`}
                      </Typography>
                    </Grid>

                    {/* Assign task to yourself */}
                    {d?.isVisible ? (
                      <Button
                        size="small"
                        style={{
                          boxShadow: "none",
                          backgroundColor: "#00ce3f",
                          borderRadius: "24px",
                          marginTop: "1rem",
                          marginLeft: "1rem",
                          textTransform: "none",
                          display: `${
                            !d?.isAssignedToCurrentUser ||
                            d?.status === "Picked" ||
                            d?.status === "Completed"
                              ? "none"
                              : ""
                          }`,
                        }}
                        variant="contained"
                        onClick={
                          d?.status === "Picked" || d?.status === "Completed"
                            ? null
                            : () => handleClickPickTask(d)
                        }
                      >
                        Add to in-progress
                      </Button>
                    ) : null}

                    <Grid
                      container
                      spacing={3}
                      mt={1}
                      style={{
                        display: `${
                          d?.status === "Picked" || d?.status === "Completed"
                            ? ""
                            : "none"
                        }`,
                      }}
                    >
                      {d?.TaskAns?.length > 0 &&
                        d?.TaskAns?.map((answer) => (
                          <>
                            <Grid
                              key={d?.id}
                              item
                              xs={4}
                              style={{
                                display: "flex",
                                gap: 10,
                                alignItems: "center",
                              }}
                            >
                              <Button
                                disabled={
                                  !d?.finalTruthWhatActionCanHeTakeOnTaskLikeGivingComment ||
                                  d?.status === null
                                }
                                fullWidth
                                size="small"
                                style={{
                                  borderRadius: "24px",
                                  boxShadow: "none",
                                  borderColor: "#3D4740",
                                  marginLeft: "1rem",
                                }}
                                variant="outlined"
                                className={`normal_normal_semibold_14_Manrope ${
                                  !d?.finalTruthWhatActionCanHeTakeOnTaskLikeGivingComment ||
                                  d?.status === null
                                    ? ""
                                    : "dark_grey"
                                } roundOutlinedButton`}
                                onClick={() =>
                                  openUpdateTaskAnswerDialog(
                                    answer,
                                    d,
                                    isIndividualTask
                                  )
                                }
                              >
                                {answer?.name || ""}
                              </Button>
                              <IconButton onClick={() => viewTaskComment(d)}>
                                <RemoveRedEyeIcon className="green" />
                              </IconButton>
                            </Grid>

                            <Grid
                              item
                              xs={4}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              {d?.status === "Completed" ? (
                                <Tooltip title="Task i s completed">
                                  <span
                                    className={`normal_normal_600_14_Manrope ${
                                      checkDate(d) ? "red" : "green"
                                    }`}
                                    style={{ marginLeft: "1rem" }}
                                  >
                                    (Due by:-{" "}
                                    {d?.dueDate
                                      ? moment(new Date(d?.dueDate)).format(
                                          "DD/MM/YYYY hh:mm A"
                                        )
                                      : "NA"}
                                    )
                                  </span>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Update Due Date">
                                  <span
                                    onClick={() => handleClickOpenDueDate(d)}
                                    className={`normal_normal_600_14_Manrope ${
                                      checkDate(d) ? "red" : "green"
                                    }`}
                                    style={{
                                      marginLeft: "1rem",
                                      cursor: "pointer",
                                    }}
                                  >
                                    (Due by:-{" "}
                                    {d?.dueDate
                                      ? moment(new Date(d?.dueDate)).format(
                                          "DD/MM/YYYY hh:mm A"
                                        )
                                      : "NA"}
                                    )
                                  </span>
                                </Tooltip>
                              )}
                              {/* <span
                                className={`normal_normal_600_14_Manrope ${
                                  checkDate(d) ? "red" : "green"
                                }`}
                                style={{
                                  marginLeft: "1rem",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleClickOpenDueDate(d)}
                              >
                                (Due by:-{" "}
                                {d?.dueDate
                                  ? moment(new Date(d?.dueDate)).format(
                                      "DD/MM/YYYY hh:mm A"
                                    )
                                  : "NA"}
                                )
                              </span> */}
                            </Grid>
                            {d?.isCompleted && (
                              <Grid
                                item
                                xs={4}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  className="normal_normal_600_14_Manrope green"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  (Completed On:-{" "}
                                  {d?.endDate
                                    ? moment(new Date(d?.endDate)).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )
                                    : "NA"}
                                  )
                                </span>
                              </Grid>
                            )}
                          </>
                        ))}
                    </Grid>

                    <Grid item xs={12} mt={1} style={{ marginLeft: "30px" }}>
                      <Divider
                        orientation="horizontal"
                        sx={{ border: `1px solid #DCDCE1` }}
                      />
                    </Grid>
                  </>
                ))
              ) : (
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography style={{ color: "#00CE3F", marginTop: "1rem" }}>
                    Data not found.
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default IndividualTask;
