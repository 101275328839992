import React, { useState } from "react";
import { RoundButton } from "../../../components/buttons";
import { MONDAY_TICKET_IFRAME_SRC_BOARD, MONDAY_TICKET_IFRAME_SRC_FORM } from "../../../utils/constant";

// monday.com iframe url of new ticket form and board view
const mondayFormUrl = MONDAY_TICKET_IFRAME_SRC_FORM;
const mondayBoardUrl = MONDAY_TICKET_IFRAME_SRC_BOARD;

const Tickets = () => {
  const [view, setView] = useState(1);

  return (
    <div style={{ height: "98vh", display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          gap: "10px",
          margin: "20px",
        }}
      >
        <RoundButton
          onClick={() => setView(1)}
          style={{
            backgroundColor: view === 1 ? "#00ce3f" : "white",
            border: view === 1 ? "#00ce3f" : "0.5px solid black",
            color: view === 1 ? "white" : "black",
          }}
          label="Board"
        >
          Board
        </RoundButton>
        <RoundButton
          onClick={() => setView(2)}
          style={{
            backgroundColor: view === 2 ? "#00ce3f" : "white",
            border: view === 2 ? "#00ce3f" : "0.5px solid black",
            color: view === 2 ? "white" : "black",
          }}
          label="Create New Ticket"
        >
          Create New Ticket
        </RoundButton>
      </div>
        <iframe
          src={view === 2 ? mondayFormUrl : mondayBoardUrl}
          style={{
            border: 0,
            boxShadow: "2px 2px 16px 0px rgba(0,0,0,0.2)",
            height: "100%",
            width: "100%",
            flexGrow: 1,
          }}
        />
    </div>
  );
};

export default Tickets;
