import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import DataGridComponent from "../../../../components/DataGridComponent";
import Loader from "../../../../components/Loader";
import PaginationComponent from "../../../../components/PaginationComponent";
import "../../../../custom-bootstrap.scss";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { getProduct } from "../../../../thunks/product";
import { PRODUCT_TABLE_COLUMNS } from "../../../../utils/TableColumns/ProductTableColumns";
import { PAGINATION_LIMIT } from "../../../../utils/constant";

const ProductTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tempProductData = useSelector((state) => state?.product?.productsData);
  const [productData, setProductData] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    itemsPerPage: PAGINATION_LIMIT,
    totalCount: 0,
  });
  // Calculate the index range for the current page
  const startIndex =
    (paginationData?.currentPage - 1) * paginationData?.itemsPerPage;
  const endIndex = startIndex + paginationData?.itemsPerPage;

  // DataGrid onRowClick Method
  const onRowClick = (e) => {
    navigate(`/app/manage/update-product/${e?.row?.id}`);
  };

  /* Method to get product data */
  const getProductData = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getProduct()).unwrap();
      if (response) {
        setProductData(response);
        setPaginationData({ ...paginationData, totalCount: response?.length });
        setLoader(false);
      }
    } catch (error) {
      setProductData([]);
      setPaginationData({ ...paginationData, totalCount: 0 });
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
      setLoader(false);
    }
  };

  /* Method to handleChange search */
  const handleChangeSearch = (e) => {
    setSearch(e);
    const filterData = tempProductData?.filter((value) =>
      value?.productName?.toLowerCase().includes(e?.toLowerCase())
    );
    setPaginationData({
      ...paginationData,
      totalCount: filterData?.length,
      currentPage: 1,
    });
    setProductData(filterData);
  };

  /* Method to change page for --> Pagination */
  const handleChangePage = (event, value) => {
    setPaginationData({ ...paginationData, currentPage: value });
  };

  useEffect(() => {
    getProductData();
  }, []);

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "0px" }}>
        {/* Loader Component */}
        {isLoader && <Loader />}

        {/* Filter By Name Container */}

        <Grid item xs={3}>
          <TextField
            fullWidth
            placeholder="Search by name..."
            className="textField_outlined_border"
            size="small"
            value={search}
            onChange={(e) => handleChangeSearch(e?.target?.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* ADD PRODUCT BUTTON */}

        <Grid item xs={3}>
          <Button
            className="add_a_new_user_button"
            onClick={() => navigate("/app/manage/create-product")}
          >
            Add a New Product
          </Button>
        </Grid>

        {/* PRODUCT TABLE */}

        <Grid
          item
          xs={12}
          style={{ width: "100%", height: "75vh", overflow: "auto" }}
        >
          <DataGridComponent
            columns={PRODUCT_TABLE_COLUMNS}
            rows={productData?.slice(startIndex, endIndex)}
            onRowClick={onRowClick}
          />
        </Grid>

        {/* PAGINATION */}
        <PaginationComponent
          totalCount={paginationData?.totalCount}
          limit={paginationData?.itemsPerPage}
          page={paginationData?.currentPage}
          onChange={handleChangePage}
        />
      </Grid>
    </>
  );
};

export default ProductTable;
