import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import SnackbarComponent from "./components/Snackbar";
import RootContainer from "./containers/root";
import "./custom-bootstrap.scss";
function App() {
  const { show, message, severity, duration } = useSelector(
    (state) => state.snackbar
  );

  return (
    <div className="App">
      <Router>
        <RootContainer />
        {/* Snackbar component to show application notification on global level */}
        <SnackbarComponent
          open={show}
          autoHideDuration={duration}
          message={message}
          severity={severity || ""}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        />
      </Router>
    </div>
  );
}

export default App;
