import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Reports from "./components/report";

const OfficeAdminDashboard = () => {
  const Location = useLocation();
  return (
    <>
      <div className="main-page-container">
        <Routes>
          <Route path={"/"} element={<Reports />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default OfficeAdminDashboard;
