import React, { Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import Dashboard from "./components/Dashboard/Dashboard";
import InstructionFormList from "./components/InstructionFormList";
import InstructionList from "./components/list";

/* Component to define Instruction Module routes */

const Instructions = () => {
  const Location = useLocation();
  return (
    <>
      <div style={{ width: "100%" }}>
        <Routes>
          <Route
            path={`/`}
            element={<Navigate replace="/" to={`${Location.pathname}/list`} />}
          ></Route>
          <Route path={"list"} element={<InstructionList />}></Route>
          <Route path={"dashboard/:id"} element={<Dashboard />}></Route>
          <Route
            path={"forms/:id/*"}
            element={
              <Suspense fallback={<Loader />}>
                <InstructionFormList />
              </Suspense>
            }
          ></Route>
        </Routes>
      </div>
    </>
  );
};

export default Instructions;
