import { createSlice } from "@reduxjs/toolkit";
import { getClientById } from "../thunks/client";

const initState = {
  loading: false,
  clientUserData: {},
};

const clientSlice = createSlice({
  name: "clientSlice",
  initialState: initState,
  reducers: {},
  extraReducers: {
    [getClientById.pending]: (state, action) => {
      state.loading = true;
    },
    [getClientById.fulfilled]: (state, action) => {
      state.loading = false;
      state.clientUserData = action.payload;
    },
    [getClientById.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default clientSlice.reducer;
