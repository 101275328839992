import { Grid } from "@mui/material";
import moment from "moment";

export const EMAILTEMPLATE_COLUMNS = [
  { field: "name", headerName: "Name", width: 250, sortable: false },
  { field: "subject", headerName: "Subject", width: 350, sortable: false },
  {
    field: "description",
    headerName: "Description",
    width: 350,
    sortable: false,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    minWidth: 230,
    sortable: true,
    renderCell: (selectedRow) => {
      const createdDate = moment(new Date(selectedRow?.row?.createdAt)).format(
        "DD/MM/YYYY"
      );

      return (
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {createdDate || "NA"}
        </Grid>
      );
    },
  },
];
